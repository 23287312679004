import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './BenefitCard.module.scss';

type Props = {
  text: string;
  icon: string;
};

export const BenefitCard = ({ text, icon }: Props) => {
  return (
    <div className={styles['benefit-card']}>
      <div className={styles['benefit-icon-wrapper']}>
        <Icon type={`benefit-${icon}`} className={styles['benefit-icon']} />
      </div>
      <p>{text}</p>
    </div>
  );
};
