export const config = {
  articles: {
    regularPerPage: 16,
    relatedPerPage: 9,
  },
  defaultAuthor: {
    slug: 'nicolae-buldumac',
    id: '600ed69904aa5dc3d1da9ff1',
  },
  enrichment: {
    blurThreshold: 10,
  },
};
