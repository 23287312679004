import { useMemo } from 'react';

import cn from 'classnames';

import styles from './ErrorMessage.module.scss';

interface Props {
  error: string | string[];
  position?: 'left' | 'center' | 'right';
  className?: string;
}

export const ErrorMessage = ({ error, position = 'left', className = '' }: Props) => {
  const message = useMemo(() => (Array.isArray(error) ? error[0] : error), [error]);

  return (
    <div className={cn(styles['error-message'], styles[`error-${position}`], styles[className])}>
      <span className={styles['error-title']}>{message}</span>
    </div>
  );
};
