import { useContext, useMemo, useRef } from 'react';

import cn from 'classnames';

import { caseStudiesCategories } from 'types';

import { ContentContext } from 'context/ContentContext';

import { useIntersection } from 'hooks/useIntersection';

import { Faq } from 'ui/atoms/Faq/Faq';
import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';

import { FormBook } from 'features/customers/organisms/FormBook/FormBook';
import { PlanCollapse } from 'features/customers/organisms/PlanCollapse/PlanCollapse';

const PricingPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { additionals, pageVars } = useContext(ContentContext);

  const pricingPageVars = useMemo(() => pageVars.pricing || {}, [pageVars.pricing]);

  return (
    <div className="pricing">
      <div className="platform">
        <div className="platform__wrapper">
          <div className="plan-collapse plan-collapse-flex">
            <div ref={ref} className={cn('plan-collapse__left', intersected && 'intersected')}>
              <PlanCollapse />
            </div>

            <div className="plan-collapse__right">
              <FormBook title={pricingPageVars.request_a_quate} type="quote" product={caseStudiesCategories[0]} />
            </div>
          </div>
        </div>
      </div>

      <div className="platform__customers">
        <OurCustomers />
      </div>

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq title={pricingPageVars.question_title} data={additionals.faq.data} />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default PricingPage;
