import { FC, useCallback, useMemo, useContext } from 'react';

import cn from 'classnames';

import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';
import { isObject } from 'libs/object/object';
import { stringifyQuery } from 'libs/node';
import { multipleSplit } from 'libs/array';

import { NavigationProps } from 'stores/content/content.types';

import { LeadContext } from 'context/LeadContext';
import { ContentContext } from 'context/ContentContext';

import { Dropdown } from 'ui/atoms/Dropdown/Dropdown';
import { MegaDropdown, ChildrenParams, MegaOption } from 'ui/atoms/MegaDropdown/MegaDropdown';
import { Tabs } from 'ui/atoms/Tabs/Tabs';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import { Burger } from '../Burger/Burger';
import { HeaderRows, HeaderRow, HeaderItem } from '../HeaderItem/HeaderItem';
import { ItemMenuInterface } from './Header.types';

const Div = (props: any) => <div {...props} />;

export const HeaderLoader = () => <div className="header-loader" />;

export const Header = () => {
  const {
    config,
    pageVars,
    secondaryNavigation,
    clientNavigationGroups: { solutions = [], countries = [], industries = [] },
    navigationGroups: { regular = [], products = [] },
  } = useContext(ContentContext);
  const { leadData } = useContext(LeadContext);
  const router = useRouter();

  const ItemMenu: FC<ItemMenuInterface> = (
    { loading, title, className = '', route = '', query, megaDropdown, children },
    key: number,
  ) => {
    const Component = route ? Link : Div;

    const hasActiveRoute =
      route === router.query.category ||
      children?.reduce((acc, current) => {
        if (
          !acc &&
          ((router.query.category !== undefined && current.route === router.query.category) ||
            current.children?.find((child) => child.route === router.query.category))
        ) {
          return true;
        }

        return acc;
      }, false) === true;

    return (
      <Component
        {...(route ? { route, query } : {})}
        key={key}
        className={cn(
          'header__menu-item',
          Boolean(children) === false && loading && 'loading',
          hasActiveRoute && 'active',
          route && 'header-menu-link',
        )}
      >
        {children ? (
          megaDropdown ? (
            <MegaDropdown loading={loading} label={title} options={children as MegaOption[]} />
          ) : (
            <Dropdown loading={loading} label={title} className={className} options={children} width={318} />
          )
        ) : (
          title
        )}
      </Component>
    );
  };

  const loadingMegaDropdown = useMemo(
    () => [solutions, countries, industries].filter((i) => i.length > 0).length === 0,
    [solutions, countries, industries],
  );

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const solutionsData = useMemo(() => solutions.filter(({ title }) => title), [solutions]);
  const productsData = useMemo(
    () => products.filter(({ title }) => title).sort((a, b) => a.headerMenuPosition - b.headerMenuPosition),
    [products],
  );

  const getCountryRows = useCallback(
    (region: string) =>
      multipleSplit(
        countries.filter((country) => country.title && region === country.region),
        4,
      ),
    [countries],
  );

  const getIndustryRows = useCallback(
    () =>
      multipleSplit(
        industries.filter((country) => country.title),
        4,
      ),
    [industries],
  );

  const menu = useMemo(
    () => [
      {
        title: commonPageVars.menu_item_1,
        value: 'products',
        className: 'products-list',
        loading: productsData.length === 0,
        children: productsData.map((product) => ({
          value: product.slug,
          title: product.title,
          route: product.slug,
          icon: product.icon,
          description: product.description,
        })),
      },
      {
        title: commonPageVars.menu_item_2,
        value: 'dataSolutions',
        loading: loadingMegaDropdown,
        megaDropdown: true,
        children: [
          {
            value: 'solutions',
            title: commonPageVars.second_submenu_item_1,
            loading: solutions.length === 0,
            disabled: solutionsData.length === 0,
            children: solutionsData.map(({ slug, title }) => ({ route: slug, title })),
            render: ({ onCloseMenu }: ChildrenParams) => {
              const renderSolution = (data: NavigationProps[]) =>
                data.map((solution) => (
                  <HeaderItem
                    key={solution.slug}
                    title={solution.title}
                    description={solution.description}
                    icon={solution.icon}
                    route={solution.slug}
                    onClick={onCloseMenu}
                  />
                ));

              return (
                <HeaderRows className="header-solutions-rows">
                  <HeaderRow>{renderSolution(solutionsData.filter(({ title }, key) => title && key < 4))}</HeaderRow>

                  <HeaderRow>{renderSolution(solutionsData.filter(({ title }, key) => title && key >= 4))}</HeaderRow>
                </HeaderRows>
              );
            },
          },
          {
            value: 'browseByCountries',
            title: commonPageVars.second_submenu_item_2,
            loading: countries.length === 0,
            children: countries.map(({ slug, title }) => ({ route: slug, title })),
            render: ({ onCloseMenu }: ChildrenParams) => (
              <Tabs
                className="header-browse-countries"
                data={[
                  {
                    label: commonPageVars.second_submenu_tab_1,
                    className: 'header-browse-countries-tab',
                    children: (
                      <HeaderRows>
                        {countries
                          .filter(({ title, isPopular }) => title && isPopular)
                          .map((country) => (
                            <HeaderItem
                              key={country.title}
                              title={country.title}
                              route={country.slug}
                              onClick={onCloseMenu}
                            />
                          ))}
                      </HeaderRows>
                    ),
                  },
                  ...countries
                    .reduce<string[]>(
                      (acc, current) => (!acc.includes(current.region) ? [...acc, current.region] : acc),
                      [],
                    )
                    .map((region) => ({
                      label: commonPageVars[region],
                      className: 'header-browse-countries-tab',
                      children: (
                        <HeaderRows>
                          {getCountryRows(region).map((row, key) => (
                            <HeaderRow key={key}>
                              {row.map((country) => (
                                <HeaderItem
                                  key={country.slug}
                                  title={country.title}
                                  route={country.slug}
                                  onClick={onCloseMenu}
                                />
                              ))}
                            </HeaderRow>
                          ))}
                        </HeaderRows>
                      ),
                    })),
                ]}
              />
            ),
          },
          {
            value: 'browseByIndustries',
            title: commonPageVars.second_submenu_item_3,
            loading: industries.length === 0,
            children: industries.map(({ slug, title }) => ({ route: slug, title })),
            render: ({ onCloseMenu }: ChildrenParams) => (
              <HeaderRows className="header-industries-rows">
                {getIndustryRows().map((row, key) => (
                  <HeaderRow key={key}>
                    {row.map((country) => (
                      <HeaderItem key={country.slug} title={country.title} route={country.slug} onClick={onCloseMenu} />
                    ))}
                  </HeaderRow>
                ))}
              </HeaderRows>
            ),
          },
        ],
      },
      ...regular
        .filter(({ title }) => title)
        .map((item) => {
          let route = item.slug;

          if (item.navigationId === 'pricing_products') {
            if (config?.is_pricing_on_shop !== true) {
              route = secondaryNavigation.pricing.slug;
            }
          }

          return {
            title: item.title,
            route,

            // add to customers page in query page 1
            query: secondaryNavigation.customers?.slug === item.slug ? { paage: 1 } : {},
          };
        }),
    ],
    [
      config,
      solutions,
      getCountryRows,
      countries,
      getIndustryRows,
      industries,
      pageVars,
      products,
      regular,
      secondaryNavigation,
      loadingMegaDropdown,
      commonPageVars,
    ],
  );

  const hasCountryPromotion = useMemo(() => 'country_promotion' in pageVars, [pageVars]);

  const linkData = useMemo(() => {
    if (isObject(leadData)) {
      const queryString = stringifyQuery({
        full_name: leadData.full_name,
        email: leadData.email,
        phone: leadData.phone,
      });

      return `${process.env.PLATFORM}${queryString ? '/?' : ''}${queryString}`;
    }

    return process.env.PLATFORM;
  }, [leadData]);

  return (
    <div id="header" className={`header ${hasCountryPromotion ? 'is-country-promotion' : ''}`}>
      <div className="header__container">
        <Link className="header__logo" route="" ariaLabel="Home page">
          <Icon type="logo-header" className="header-logo" />
        </Link>

        {hasCountryPromotion === false ? (
          <>
            <Burger options={menu} className="hamburger-show" />

            <div className="header__menu">{menu.map(ItemMenu)}</div>

            <div className="header__signin-div">
              {secondaryNavigation.book_demo?.slug && commonPageVars.book_demo_button && (
                <Button size={48} type="invert" route={secondaryNavigation.book_demo.slug}>
                  {commonPageVars.book_demo_button}
                </Button>
              )}

              {commonPageVars.sign_in && (
                <Button type="transparent" size={48} className="sign_in_button" href={linkData} target="_blank" rel="noopener noreferrer">
                  {commonPageVars.sign_in}
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className="header__contact-wrapper">
            <a href="tel:+44 203 640 6006">
              <Icon type="footer-phone" />
              +44 203 640 6006
            </a>

            <a href="mailto:info@globaldatabase.com">
              <Icon type="footer-email" />
              info@globaldatabase.com
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Header;
