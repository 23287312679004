import React, { useContext, useMemo } from 'react';

import { ContentContext } from 'context/ContentContext';

import { Button } from 'ui/atoms/Button/Button';

import styles from './TouchTab.module.scss';

import { Props, TouchTabsProps } from './TouchTab.types';

export const TouchTabs = ({ inline, className = '', children }: TouchTabsProps) => {
  return <div className={`${styles['touch-tabs']} ${inline ? styles['inline'] : ''} ${className}`}>{children}</div>;
};

export const TouchTab = ({ type, className = '', buttonClassName = '', inline, ...rest }: Props) => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const touchData = useMemo(() => {
    const href = rest.href;
    let description = rest.description;
    let button = rest.button;

    let route, query;
    if ('route' in rest) {
      route = rest.route;
      query = rest.query;
    }

    if (
      type === 'business-free-trial' &&
      secondaryNavigation.free_trial?.slug &&
      commonPageVars.touch_free_trial_desc &&
      commonPageVars.touch_free_trial_button
    ) {
      description = commonPageVars.touch_free_trial_desc;
      button = commonPageVars.touch_free_trial_button;
      route = secondaryNavigation.free_trial.slug;
    }

    if (type === 'search' && commonPageVars.touch_search_desc && commonPageVars.touch_search_button) {
      description = commonPageVars.touch_search_desc || 'Enter company name or reg. number';
      button = commonPageVars.touch_search_button || 'Search';
      route = secondaryNavigation.book_demo.slug;
    }

    if (secondaryNavigation.book_demo?.slug) {
      if (
        type === 'industry-request-sample' &&
        commonPageVars.touch_request_sample_desc &&
        commonPageVars.touch_request_sample_button
      ) {
        description = commonPageVars.touch_request_sample_desc;
        button = commonPageVars.touch_request_sample_button;
        route = secondaryNavigation.book_demo.slug;
        query = {
          request_sample: true,
        };
      }

      if (
        type === 'email-request-demo' &&
        commonPageVars.touch_request_demo_desc &&
        commonPageVars.touch_request_demo_button
      ) {
        description = commonPageVars.touch_request_demo_desc;
        button = commonPageVars.touch_request_demo_button;
        route = secondaryNavigation.book_demo.slug;
      }

      if (type === 'phone-book-demo' && commonPageVars.touch_phone_desc && commonPageVars.touch_phone_demo_button) {
        description = commonPageVars.touch_phone_desc;
        button = commonPageVars.touch_phone_demo_button;
        route = secondaryNavigation.book_demo.slug;
      }

      if (
        type === 'marketing-free-trial' &&
        commonPageVars.touch_marketing_desc &&
        commonPageVars.touch_marketing_trial_button
      ) {
        description = commonPageVars.touch_marketing_desc;
        button = commonPageVars.touch_marketing_trial_button;
        route = secondaryNavigation.free_trial.slug;
      }
    }

    return {
      description,
      button,
      route,
      href,
      query,
    };
  }, [commonPageVars, secondaryNavigation]);

  return (
    <div className={`${styles['touch-data-wrapper']} ${className}`}>
      <h2 className={styles['touch-data-detail']}>{touchData.description}</h2>

      {touchData.route ? (
        <Button size={48} className={`${buttonClassName} ${type}`} route={touchData.route} query={touchData.query}>
          {touchData.button}
        </Button>
      ) : (
        <Button type="invert" size={48} className={`${buttonClassName} ${type}`} href={touchData.href} target="_blank" rel="noreferrer">
          {touchData.button}
        </Button>
      )}
    </div>
  );
};
