/* eslint react-hooks/exhaustive-deps: "warn" */

import { useState, useCallback, ReactNode, DragEvent, useRef } from 'react';

import cn from 'classnames';

import styles from './DropFileInput.module.scss';

interface Props {
  onUpload: (value: File[]) => void;
  formats?: string[];
  count?: number;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
}

export const DropFileInput = ({ onUpload, formats, count = 1, className = '', children }: Props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const drop = useRef<HTMLDivElement>(null);

  const handleDragEnter = useCallback((ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback((ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(false);
  }, []);

  const checkFormatsUploadedFiles = useCallback(
    (files: File[]) => {
      if (
        formats &&
        files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))
      ) {
        // eslint-disable-next-line no-console
        console.error(`Only following file formats are acceptable: ${formats.join(', ')}`);
        return false;
      }
      return true;
    },
    [formats],
  );

  const dropFiles = useCallback(
    (files: File[]) => {
      if (count && count < files.length) {
        // eslint-disable-next-line no-console
        console.error(`Only ${count} file${count !== 1 ? 's' : ''} can be uploaded at a time`);
        return;
      }

      const fasFiles = checkFormatsUploadedFiles(files);
      if (!fasFiles) {
        return;
      }

      onUpload(files);
    },
    [checkFormatsUploadedFiles, count, onUpload],
  );

  const handleDrop = useCallback(
    (ev: DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsDragOver(false);

      const files = Array.from(ev.dataTransfer?.files || []) as File[];
      dropFiles(files);
    },
    [dropFiles],
  );

  const onUploadClick = useCallback(async () => {
    try {
      const [fileHandle] = await (window as any).showOpenFilePicker({
        types: [
          {
            accept: {
              'text/plain': formats,
            },
          },
        ],
      });

      const file = await fileHandle?.getFile();
      if (!file) {
        return;
      }

      dropFiles([file]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error selecting file:', error);
    }
  }, [dropFiles, formats]);

  return (
    <div
      ref={drop}
      className={cn(className, isDragOver && styles['drag-over'])}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragEnter}
      onDrop={handleDrop}
      onClick={onUploadClick}
    >
      {children}
    </div>
  );
};
