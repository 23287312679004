import { defaultLocale } from 'libs/locale';

type Params = { lang: string };
type Query = { [key: string]: unknown };

export const getAbsoluteRoute = (route: string, { lang }: Params, query = {} as Query) => {
  const hasLang = lang.length === 2 && lang !== defaultLocale;

  const queryKeys = Object.keys(query);
  const hasQuery = queryKeys.length > 0;
  let queryAsString = '';

  if (hasQuery) {
    queryAsString = queryKeys.reduce((acc, curr) => {
      acc += `${acc ? '&' : ''}${curr}=${query[curr]}`;
      return acc;
    }, '');
  }

  return `${process.env.HOST}/${route}${hasLang ? `/${lang}` : ''}${hasQuery ? `?${queryAsString}` : ''}`;
};
