import { LearnBlock } from 'features/academy/organisms/LearnBlock/LearnBlock';

import styles from './AcademyPage.module.scss';

const AcademyPage = () => {
  return (
    <div className={styles['academy-wrapper']}>
      <div className={styles.container}>
        <LearnBlock />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcademyPage;
