import { FC } from 'react';

import { Career } from 'libs/http/api/content/content.types';

import { CareerCard } from 'features/about/atoms/CareerCard/CareerCard';

import styles from './CareerCategory.module.scss';

type Props = {
  title: string;
  key: string;
  data: Career[];
};

export const CareerCategory: FC<Props> = ({ title, key, data }) => {
  if (data.length > 0) {
    return (
      <div className={styles['category-wrapper']} key={key}>
        <h4>{title}</h4>

        {data.map((career) => (
          <CareerCard
            key={career.name}
            name={career.name}
            category={title}
            location={career.location}
            slug={career.slug}
          />
        ))}
      </div>
    );
  }

  return null;
};
