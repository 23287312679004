import styles from './Tag.module.scss';

type Props = {
  tagName: string;
  type?: string;
  className?: string;
};

export const Tag = ({ tagName, type, className = '' }: Props) => {
  return <p className={`${styles['tag']} ${styles[`tag-type-${type}`]} ${className}`}>{tagName}</p>;
};
