import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  label: string;
  open: boolean;
  id: number;
  onClick: () => void;
}

export const WebTechItem = ({ label, open, id, onClick }: Props) => (
  <div className="web-technologies__suggestions" onClick={onClick}>
    <div className={`web-technologies__left ${open ? 'web-technologies__true' : ''}`}>
      <span>
        {id < 10 ? 0 : ''}

        {id}
      </span>

      <Icon type={open ? 'grey-small' : 'grey-very-small'} />
    </div>

    <div className="web-technologies__right" style={open ? { color: '#077BE7', transition: '0.3s all ease' } : {}}>
      <span>{label}</span>
    </div>
  </div>
);
