import { ReactNode } from 'react';

import cn from 'classnames';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './Collapse.module.scss';

type Props = {
  title: string;
  className: string;
  iconClassName: string;
  iconType: string;
  opened: boolean;
  loading?: boolean;
  onChange: (value: Props['opened']) => void;
  children: ReactNode;
};

export const Collapse = ({
  title,
  loading,
  opened,
  onChange,
  className = '',
  iconClassName = '',
  children,
  iconType,
}: Props) => {
  const onChangeHandler = () => onChange(!opened);

  return (
    <div className={styles['collapse-wrapper']}>
      <div className={cn(styles['first-block-wrapper'], opened && styles['collapsed'], loading && styles['loading'])}>
        <div className={styles['title-wrapper']} onClick={onChangeHandler}>
          <h2 className="title">{title}</h2>

          <Icon type={iconType} className={iconClassName} />
        </div>

        <hr />
      </div>

      {opened && <div className={className}>{children}</div>}
    </div>
  );
};
