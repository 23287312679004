import { ReactNode } from 'react';

import { Tabs } from 'features/public/atoms/Tabs/Tabs';

interface Props {
  className?: string;
  title?: ReactNode;
  hasTabs?: boolean;
  children?: ReactNode;
}

export const Layout = ({ className = '', hasTabs = true, title, children }: Props) => (
  <div className={`policy-layout-block ${className}`}>
    {title && (
      <div className="policy-layout__title">
        <h1>{title}</h1>
      </div>
    )}

    {hasTabs && <Tabs />}

    {children}
  </div>
);
