import { useContext, useMemo, useRef } from 'react';

import { ContentContext } from 'context/ContentContext';

import { useIsInViewport } from 'hooks/useIsInViewport';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

import { ScrollToForm } from 'features/about/atoms/ScrollToForm/ScrollToForm';
import { VacancyDescription } from 'features/about/atoms/VacancyDescription/VacancyDescription';
import { ApplyForm } from 'features/about/organisms/ApplyForm/ApplyForm';

import styles from './VacancyPage.module.scss';

const VacancyPage = () => {
  const { data: content, pageVars } = useContext(ContentContext);

  const vacancy = useMemo(() => content.has_career || {}, [content.has_career]);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const formRef = useRef<null | HTMLDivElement>(null);
  const firstRender = useRef(true);
  const visibleForm = useIsInViewport(formRef);
  const hideScroll = useMemo(() => {
    if (firstRender.current && visibleForm) {
      firstRender.current = false;
      return true;
    } else return false;
  }, [firstRender, visibleForm]);

  const handleClick = () => formRef.current && formRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <div className={styles['vacancy-wrapper']}>
      <InfoBlock title={vacancy.name} className={styles['info-block']} />

      <div className={styles['vacancy-container']}>
        <div className={styles['vacancy-description']}>
          <VacancyDescription title={vacancy['description_name']} content={vacancy['description']} />
          <VacancyDescription title={vacancy['responsibilities_name']} content={vacancy['responsibilities']} />
          <VacancyDescription title={vacancy['requirements_name']} content={vacancy['requirements']} />
          <VacancyDescription title={vacancy['qualities_name']} content={vacancy['qualities']} />
          <VacancyDescription title={vacancy['benefits_name']} content={vacancy['benefits']} />
        </div>

        <div className={styles['apply-form']} ref={formRef}>
          <ApplyForm />
        </div>
      </div>

      <ScrollToForm
        hide={hideScroll}
        visibleForm={visibleForm}
        onClick={handleClick}
        text={commonPageVars.apply_form_name}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default VacancyPage;
