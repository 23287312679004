import cn from 'classnames';
import { Cabin } from 'next/font/google';

export const cabin = Cabin({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  variable: '--font-cabin',
});

export const cabinClassNames = cn(cabin.className, cabin.variable);
