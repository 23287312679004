import { Fragment, useMemo, useState } from 'react';

import cn from 'classnames';

import { Product } from 'libs/http/api/content/content.types';

import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './PlansTable.module.scss';

type Nullable<T> = T | null;

/* prettier-ignore */
type Rows = Record<
  string, Record<
    string, Record<
      string, { 
        required: Nullable<boolean>; 
        checkmark: Nullable<boolean>; 
        has_checkmark: Nullable<boolean>; 
        name: Nullable<string> 
      }
    >
  >
>;

interface Props {
  data: Product[];
  footnote?: boolean;
}

export const PlansTable = ({ data = [], footnote = false }: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  const onToggleCollapse = () => setCollapsed((s) => !s);

  const [columns, columnKeys] = useMemo(() => {
    const columnKeys: string[] = [];

    return [
      [
        {
          label: 'Features',
          type: 'ghost',
        },
        ...data.map((item) => {
          columnKeys.push(item.name);

          return {
            label: item.name,
            type: item.best_value ? 'primary' : 'secondary',
          };
        }),
      ],
      columnKeys,
    ];
  }, [data]);

  const rows = useMemo(() => {
    return data.reduce((acc, product) => {
      product.pricing_feature_groups
        .filter((group) => group.pricing_features && group.pricing_features.length > 0)
        .forEach((group) => {
          if (group.name in acc === false) {
            acc[group.name] = {};
          }

          if (group.pricing_features) {
            group.pricing_features.forEach((category) => {
              if (category === null) {
                /** normally it should not be null, but some migrations on CMS can add such features */
                return;
              }

              if (category.group in acc[group.name] === false) {
                acc[group.name][category.group] = {};
              }

              acc[group.name][category.group][product.name] = {
                required: category.required,
                name: category.name,
                has_checkmark: category.has_checkmark,
                checkmark: category.checkmark,
              };
            });
          }
        });

      return acc;
    }, {} as Rows);
  }, [data]);

  if (Object.keys(rows).length === 0) {
    return null;
  }

  return (
    <div className={styles['table-wrapper']}>
      <div className={cn(styles['table-collapse'], collapsed && styles['collapsed'])}>
        <table>
          <thead>
            <tr className={styles['table-columns']}>
              {columns.map((column) => (
                <th key={column.label}>
                  <p className={styles[`table-columns-${column.type}`]}>{column.label}</p>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {Object.entries(rows).map(([group, groupItems]) => (
              <Fragment key={group}>
                <tr>
                  <td className={styles['table-group-name']} colSpan={4}>
                    {group}
                  </td>
                </tr>

                {Object.entries(groupItems).map(([category, categoryItems]) => (
                  <tr key={category}>
                    <td>{category}</td>

                    {columnKeys.map((column) => (
                      <td key={column}>
                        {column in categoryItems ? (
                          <p>
                            {categoryItems[column].has_checkmark && (
                              <Icon
                                className={
                                  styles[`category-icon-${categoryItems[column].checkmark ? 'check' : 'cross'}`]
                                }
                                type={`${categoryItems[column].checkmark ? 'check' : 'cross'}-circle`}
                              />
                            )}

                            {categoryItems[column].name}

                            {categoryItems[column].required && <span>*</span>}
                          </p>
                        ) : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>

        {footnote && (
          <span className={styles['table-columns-footnote']}>
            * data availability varies, depending selected region and/or country
          </span>
        )}
      </div>

      <div className={cn(styles['table-collapse-toggler'], collapsed && styles['collapsed'])}>
        <Button type="invert" size={48} onClick={onToggleCollapse}>
          {collapsed ? 'Hide' : 'Show'} all plan features
        </Button>
      </div>
    </div>
  );
};
