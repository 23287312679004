import { useState, useEffect } from 'react';

import { useRouter } from 'libs/router/useRouter';

import { ProgressLine } from 'ui/atoms/ProgressLine/ProgressLine';

const RouteProgress = () => {
  const { events } = useRouter();

  const [percentage, setPercentage] = useState<null | number>(null);

  useEffect(() => {
    const changePercentage = (i?: typeof percentage) => () =>
      setPercentage(i === undefined ? Math.random() * (40 - 10) + 10 : i);

    const start: Parameters<typeof events.on> = ['routeChangeStart', changePercentage()];
    const complete: Parameters<typeof events.on> = ['routeChangeComplete', changePercentage(100)];
    const error: Parameters<typeof events.on> = ['routeChangeError', changePercentage(100)];

    events.on(...start);
    events.on(...complete);
    events.on(...error);

    return () => {
      events.off(...start);
      events.off(...complete);
      events.off(...error);
    };
  }, [events]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (percentage === 100) {
      timeoutId = setTimeout(() => setPercentage(null), 450);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [percentage]);

  return <ProgressLine className="progress-bar" percentage={percentage} />;
};

// eslint-disable-next-line import/no-default-export
export default RouteProgress;
