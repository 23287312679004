import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './FreeTrialIncludes.module.scss';

interface Props {
  title: string;
  data: {
    lightAccess: {
      description: string;
      labels: {
        label: string;
      }[];
    };
    ecosystemAccess: {
      description: string;
      labels: {
        label: string;
      }[];
    };
  };
}

export const FreeTrialIncludes = ({ title, data }: Props) => {
  return (
    <div className={styles['trial-wrapper']}>
      <div className={styles['trial-container']}>
        <h2>{title}:</h2>

        <div className={styles['trial-description']}>
          <div className={styles['trial-inner']}>
            <h4>{data.lightAccess?.description}</h4>

            {data.lightAccess?.labels.map(({ label }) => (
              <div className={styles['trial-label']} key={label}>
                <Icon type="check-mark" />
                <p>{label}</p>
              </div>
            ))}
          </div>

          <div className={styles['trial-inner']}>
            <h4>{data.ecosystemAccess?.description}</h4>

            {data.ecosystemAccess?.labels.map(({ label }) => (
              <div className={styles['trial-label']} key={label}>
                <Icon type="check-mark" />
                <p>{label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
