import { ChangeEvent, useEffect, useContext, useRef, useCallback, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { useRouter } from 'libs/router/useRouter';
import { Country } from 'libs/http/api/dashboard/dashboard.types';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { keys } from 'libs/object/object';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';
import { useStateHandlers } from 'hooks/useStateHandlers';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Input } from 'ui/atoms/Input/Input';
import { SelectLoader } from 'ui/atoms/Select/SelectLoader';
import { DynamicImport } from 'ui/DynamicImport';

export const initialState = {
  key: Date.now(),
  id: undefined,
  name: '',
  country: '',
  address_city: '',
  address_full: '',
  address_postcode: '',
  vat_number: '',
  reg_number: '',
  phone: '',
  additional_info: '',
  pay_one: true,
};

interface Props {
  onChange: (newValues: typeof initialState) => void;
  onDelete?: () => void;
  initialValues?: typeof initialState;
  errors: object;
  id: number;
}

export const FreshInvestigation = ({
  id,
  errors,
  onChange,
  onDelete,
  initialValues = {} as typeof initialState,
}: Props) => {
  const { query } = useRouter();
  const { request: requestCountries, data: dataCountries } = useRequest<Country[]>({ data: [] });
  const { pageVars } = useContext(ContentContext);

  const changed = useRef(false);
  const [state, setState] = useStateHandlers(initialState);

  useEffect(() => {
    if (!changed.current && Object.keys(initialValues).length > 0) {
      changed.current = true;

      setState((prevState) =>
        keys(prevState).reduce((acc, current) => ({ ...acc, [current]: initialValues[current] }), prevState),
      );
    }
  }, [initialValues]);

  useEffect(() => {
    requestCountries(dashboard.country({ lang: `${query.lang}` }));
  }, []);

  const optionsDrop = (input: any, option: any) =>
    option ? option.key.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;

  const onChangeHandler = useCallback(
    (target: keyof typeof initialState, value: (typeof initialState)[keyof typeof initialState]) => {
      setState((prevState) => {
        const newState = { [target]: value };

        if (onChange !== undefined) {
          onChange({ ...prevState, ...newState });
        }

        return newState;
      });
    },
    [onChange],
  );

  const onCountryChange = async (value: string) => {
    if (value) {
      let response;
      try {
        response = await dashboard.investigationPrice({ country_code: value, lang: `${query.lang}` });
      } catch (e) {
        onChangeHandler('id', undefined);
        onChangeHandler('country', '');
        return;
      }
      const { data } = response;
      const price = data.credit_reports.find(({ codename }) => {
        return `fresh_investigation_${value.toLowerCase()}` === codename;
      });

      onChangeHandler('id', price?.id);
    }
    onChangeHandler('country', value);
  };

  const getMessageInput = useCallback(
    (target: keyof typeof initialState, ev: ChangeEvent<HTMLTextAreaElement>) => {
      ev.preventDefault();

      setState((prevState) => {
        const newState = { [target]: ev.target.value };

        if (onChange !== undefined) {
          onChange({ ...prevState, ...newState });
        }

        return newState;
      });
    },
    [onChange],
  );

  const countryOptions = useMemo(
    () =>
      dataCountries
        .filter((item) => item.fresh_investigation !== false)
        .map((item) => ({
          value: item.short_name,
          title: item.name,
          extra: `£${item.fresh_investigation}`,
          research: item.research,
          freshInvestigation: item.fresh_investigation,
        })),
    [dataCountries],
  );

  const displayError = useMemo(
    () =>
      Object.entries(errors).reduce((acc: {} | any, current) => {
        const [key, value] = current;

        if (key === id.toString()) {
          if (value.includes('name')) {
            acc = { ...acc, nameError: true };
          }

          if (value.includes('country')) {
            acc = { ...acc, countryError: true };
          }
        }

        return acc;
      }, {}),
    [errors],
  );

  const freshInvestigationPageVars = useMemo(() => pageVars.fresh_investigation || {}, [pageVars.fresh_investigation]);

  return (
    <div className="fresh-investigation-wrapper">
      {onDelete !== undefined ? (
        <div className="delete-form" onClick={onDelete}>
          <Icon type="close-icon" />
        </div>
      ) : null}

      <div className="fresh-investigation__input-wrapper">
        <div className="row">
          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.company_input_text}
            text={freshInvestigationPageVars.company_input_text}
            value={state.name}
            hasError={displayError.nameError}
            onChange={(value) => onChangeHandler('name', value)}
          />

          {displayError.nameError ? (
            <span className="input-error-label">{freshInvestigationPageVars.name_error}</span>
          ) : null}

          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.phone_input_text}
            text={freshInvestigationPageVars.phone_input_text}
            value={state.phone}
            onChange={(value) => onChangeHandler('phone', value)}
          />
        </div>

        <div className="row">
          <DynamicImport
            fallback={() => <SelectLoader size="md" title={freshInvestigationPageVars.select_country_placeholder} />}
          >
            {() => {
              const Component = dynamic(() => import('ui/atoms/Select/Select'), {
                ssr: false,
                loading: () => <SelectLoader size="md" title={freshInvestigationPageVars.select_country_placeholder} />,
              });

              return (
                <Component
                  options={countryOptions}
                  value={state.country}
                  placeholder={freshInvestigationPageVars.select_country_placeholder}
                  filterOptions={optionsDrop}
                  hasError={displayError.countryError}
                  onChange={(value) => onCountryChange(value as string)}
                />
              );
            }}
          </DynamicImport>

          {displayError.countryError ? (
            <span className="input-error-label">{freshInvestigationPageVars.country_error}</span>
          ) : null}

          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.city_input_text}
            text={freshInvestigationPageVars.city_input_text}
            value={state.address_city}
            onChange={(value) => onChangeHandler('address_city', value)}
          />
        </div>

        <div className="row">
          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.address_input_text}
            text={freshInvestigationPageVars.address_input_text}
            value={state.address_full}
            onChange={(value) => onChangeHandler('address_full', value)}
          />

          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.zip_code_input_text}
            text={freshInvestigationPageVars.zip_code_input_text}
            value={state.address_postcode}
            onChange={(value) => onChangeHandler('address_postcode', value)}
          />
        </div>

        <div className="row">
          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.vat_number_input_text}
            text={freshInvestigationPageVars.vat_number_input_text}
            value={state.vat_number}
            onChange={(value) => onChangeHandler('vat_number', value)}
          />

          <Input
            type="primary"
            ariaLabel={freshInvestigationPageVars.reg_number_input_text}
            text={freshInvestigationPageVars.reg_number_input_text}
            value={state.reg_number}
            onChange={(value) => onChangeHandler('reg_number', value)}
          />
        </div>

        <div className="column">
          <textarea
            aria-label={freshInvestigationPageVars.messages_placeholder}
            placeholder={freshInvestigationPageVars.messages_placeholder}
            name="Additional Info"
            onChange={(value) => getMessageInput('additional_info', value)}
          />
        </div>
      </div>
    </div>
  );
};
