import { Tooltip } from 'ui/atoms/Tooltip/Tooltip';

import styles from './TooltipTrigger.module.scss';

interface Props {
  text: string | number;
  tooltipText: string;
}

export const TooltipTrigger = ({ text, tooltipText }: Props) => {
  return (
    <div className={styles['tooltip-trigger-wrapper']}>
      <Tooltip label={tooltipText}>
        <span className={styles['tooltip-trigger-text']}>{text}</span>
      </Tooltip>
    </div>
  );
};
