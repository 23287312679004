import type { Article } from 'libs/http/api/common/common.types';

export const tagsPattern = /(<p>\[\[(?:download-sample|find-out|form)(?:\s+@[^\s]+\s*=\s*"[^"]*")*\s*]?\]<\/p>)/g;
export const downloadSamplePattern = /(<p>\[\[download-sample(?:\s+@[^\s]+\s*=\s*"[^"]*")*\s*]?\]<\/p>)/g;
export const findOutPattern = /(<p>\[\[find-out(?:\s+@[^\s]+\s*=\s*"[^"]*")*\s*]?\]<\/p>)/g;
export const formPattern = /(<p>\[\[form(?:\s+@[^\s]+\s*=\s*"[^"]*")*\s*]?\]<\/p>)/g;

export const parsePropsOfTag = (tagString: string) => {
  if (typeof tagString !== 'string' || Boolean(tagString) === false) {
    return {};
  }

  const regex = /@([^=]+)="([^"]+)"/g;
  const result = {} as { [key: string]: string };
  let match;

  while ((match = regex.exec(tagString)) !== null) {
    const key = match[1];
    const value = match[2];
    result[key] = value;
  }

  return result;
};

const articleKeys = ['', '_2', '_3', '_4', '_5'] as const;

export const getLastTitleKey = (article: Article | undefined) => {
  if (!article) {
    return null;
  }

  return [...articleKeys].reverse().find((i) => article[`title${i}`]) || null;
};

export const getTitles = (article: Article | undefined) => {
  if (!article) {
    return [];
  }

  return articleKeys.reduce<{ key: (typeof articleKeys)[number]; data: string }[]>((acc, current) => {
    const key = `title${current}` as const;
    const title = article[key];
    if (title) {
      acc.unshift({
        key: current,
        data: title,
      });
    }

    return acc;
  }, []);
};

export const addLazyLoading = (value: string) => {
  const iframeRegex = /<iframe\b(?![^>]*\bloading\s*=)[^>]*>/gi;
  const imgRegex = /<img\b(?![^>]*\bloading\s*=)[^>]*>/gi;

  /** Add iframes with loading="lazy" */
  return value
    .replace(iframeRegex, (match) => {
      return match.replace('<iframe', '<iframe loading="lazy"');
    })
    .replace(imgRegex, (match) => {
      return match.replace('<img', '<img loading="lazy"');
    });
};
