/* eslint react-hooks/exhaustive-deps: "warn" */

import { useState, useRef, useEffect, ReactNode, useCallback, useMemo } from 'react';

import cn from 'classnames';

import { useDraggableScroll } from 'hooks/useDraggableScroll';
import { useEventListener } from 'hooks/useEventListener';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './Slider.module.scss';

interface SliderProps {
  children?: JSX.Element[] | ReactNode | any;
  hasScrollBar?: boolean | undefined;
  className?: string;
  heightArrow?: 'top' | 'middle';
  arrowType?: 'primary' | 'secondary';
}

export const Slider = ({
  children,
  className,
  hasScrollBar = true,
  heightArrow = 'top',
  arrowType = 'primary',
}: SliderProps) => {
  const sliderRef = useRef<null | HTMLDivElement>(null);
  const scrollThrottle = useRef<NodeJS.Timeout>();

  const { onMouseDown } = useDraggableScroll(sliderRef, { direction: 'horizontal' });

  const [mounted, setMounted] = useState(false);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (sliderRef.current !== null) {
      setSliderWidth(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth);
    }
  }, [mounted, children, sliderWidth]);

  useEffect(() => {
    if (sliderRef.current !== null) {
      setShowArrows(sliderRef.current.scrollWidth > sliderRef.current.offsetWidth);
    }
  }, [sliderRef?.current?.offsetWidth, sliderRef?.current?.scrollWidth]);

  useEventListener(
    'scroll',
    useCallback(() => {
      if (scrollThrottle.current !== undefined) {
        return;
      }

      scrollThrottle.current = setTimeout(() => {
        window.requestAnimationFrame(() => {
          scrollThrottle.current = undefined;

          if (sliderRef.current === null) {
            return;
          }

          setHideLeftArrow(Math.round(sliderRef.current.scrollLeft) === 0);
          setHideRightArrow(Math.round(sliderRef.current.scrollLeft) >= sliderWidth);
        });
      }, 575);
    }, [sliderWidth]),

    // * Should trigger event listener re-register by mounted changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => sliderRef.current, [mounted]),
  );

  const onLeft = useCallback(() => {
    if (sliderRef.current !== null) {
      sliderRef.current.scrollLeft -= Math.round(sliderRef.current.scrollWidth / children.length);
    }
  }, [children.length]);

  const onRight = useCallback(() => {
    if (sliderRef.current !== null) {
      sliderRef.current.scrollLeft += Math.round(sliderRef.current.scrollWidth / children.length);
    }
  }, [children.length]);

  return (
    <div className={cn(styles['slider-wrapper'], className)}>
      {showArrows && (
        <button
          tabIndex={0}
          onClick={onLeft}
          aria-label="Arrow left"
          className={cn(
            styles['slider-arrow'],
            hideLeftArrow && styles['hide'],
            styles[`slider-arrow-${heightArrow}`],
            styles[`slider-arrow-type-${arrowType}`],
          )}
        >
          <Icon type="slider-arrow-left" />
        </button>
      )}

      <div
        ref={sliderRef}
        className={cn(styles['slider'], hasScrollBar && styles['slider-scroll'])}
        onMouseDown={onMouseDown}
      >
        {children}
      </div>

      {showArrows && (
        <button
          tabIndex={0}
          onClick={onRight}
          aria-label="Arrow right"
          className={cn(
            styles['slider-arrow'],
            hideRightArrow && styles['hide'],
            styles[`slider-arrow-${heightArrow}`],
            styles[`slider-arrow-type-${arrowType}`],
          )}
        >
          <Icon type="slider-arrow-right" />
        </button>
      )}
    </div>
  );
};
