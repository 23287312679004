import { useStateHandlers } from 'hooks/useStateHandlers';

import { Button } from 'ui/atoms/Button/Button';
import { Image } from 'ui/atoms/Image';
import { Icon } from 'ui/atoms/Icon/Icon';

export const ThankYouMedia = () => {
  const [button, setState] = useStateHandlers({ show: true });

  const onPlayButton = () => {
    setState({ show: false });
  };

  return (
    <div className="thank-you__video">
      {button.show ? (
        <Image width={728} height={484} src="img/presentation-2.jpg" alt="videoImg" />
      ) : (
        <iframe
          width="100%"
          height="100%"
          src={'https://www.youtube.com/embed/zTTi4cP1h0k?autoplay=1'}
          allow="autoplay"
        />
      )}

      {button.show ? (
        <div className="play-button" onClick={onPlayButton}>
          <Button size={32} className="thank-you-button">
            <Icon type="polygon" className="icon" /> Play Video
          </Button>
        </div>
      ) : null}
    </div>
  );
};
