import { useMemo, useCallback, ReactNode } from 'react';

import cn from 'classnames';

import type { ColumnRender } from 'libs/http/api/dashboard/dashboard.types';

import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './Table.module.scss';

interface Props<T> {
  rowKey?: any;
  title?: ReactNode;
  data: T[];
  columns: ColumnRender[];
  actionHeader?: ReactNode;
  blurThreshold?: number;
}

export const Table = <T extends { [key: string]: any }>({
  rowKey = 'id' as keyof T,
  title,
  data = [],
  columns,
  actionHeader,
  blurThreshold,
}: Props<T>) => {
  const isSmallDesktop = useMediaQuery(768);

  const getValueByDataIndex = (item: any, dataIndex: string) => {
    return dataIndex.split('.').reduce((acc, key) => acc?.[key], item);
  };

  const [fixedColumnsLeft, fixedColumnsWidthLeft, fixedColumnsRight, fixedColumnsWidthRight, normalColumns] =
    useMemo(() => {
      const $fixedColumnsLeft = columns.filter((column) => column.fixed === 'left' && column.visible !== false);
      const $fixedColumnsRight = columns.filter((column) => column.fixed === 'right' && column.visible !== false);

      return [
        $fixedColumnsLeft,
        $fixedColumnsLeft.reduce((totalWidth, column) => totalWidth + ((column && column?.width) || 0), 0),

        $fixedColumnsRight,
        $fixedColumnsRight.reduce((totalWidth, column) => totalWidth + ((column && column?.width) || 0), 0),

        columns.filter((column) => column.fixed !== 'left' && column.fixed !== 'right' && column.visible !== false),
      ];
    }, [columns]);

  const renderTitle = useCallback(() => {
    return title || `${data.length || 0} result${data.length !== 1 ? 's' : ''}`;
  }, [title, data]);

  if (isSmallDesktop) {
    return (
      <div className={styles['table']}>
        <div className={styles['table-header-actions']}>
          <div className={styles['table-title']}>{renderTitle()}</div>

          {actionHeader}
        </div>

        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                <th style={{ width: fixedColumnsWidthLeft }} className={styles['table-head-fixed']}>
                  <div style={{ display: 'flex' }}>
                    {fixedColumnsLeft.map((column) => (
                      <div key={column.dataIndex} style={{ width: column?.width }}>
                        {column?.title}
                      </div>
                    ))}
                  </div>
                </th>

                {normalColumns.map((column) => (
                  <th key={column.dataIndex} style={{ width: column.width }}>
                    {column.title}
                  </th>
                ))}

                <th
                  style={{ width: fixedColumnsWidthRight }}
                  className={cn(styles['table-head-fixed'], styles['table-head-fixed-right'])}
                >
                  <div style={{ display: 'flex' }}>
                    {fixedColumnsRight.map((column) => (
                      <div key={column.dataIndex}>{column?.title}</div>
                    ))}
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                const keyTypeOf = typeof item[rowKey];

                return (
                  <tr
                    key={keyTypeOf === 'string' || keyTypeOf === 'number' ? item[rowKey] : index}
                    className={styles['table-row']}
                  >
                    <td
                      style={{ width: fixedColumnsWidthLeft }}
                      className={cn(
                        styles['table-body-fixed'],
                        blurThreshold && index >= blurThreshold && styles['table-cell-blur'],
                      )}
                    >
                      <div style={{ display: 'flex' }}>
                        {fixedColumnsLeft.map((column, columnIndex) => (
                          <div key={columnIndex} style={{ width: column?.width }}>
                            {column?.render(getValueByDataIndex(item, column.dataIndex), index + 1)}
                          </div>
                        ))}
                      </div>
                    </td>

                    {normalColumns.map((column, columnIndex) => (
                      <td
                        key={columnIndex}
                        className={cn(blurThreshold && index >= blurThreshold && styles['table-cell-blur'])}
                        style={{ width: column.width }}
                      >
                        {column.render(getValueByDataIndex(item, column.dataIndex), index + 1)}
                      </td>
                    ))}

                    {fixedColumnsRight.length > 0 && (
                      <td
                        style={{ width: fixedColumnsWidthRight }}
                        className={cn(
                          styles['table-body-fixed'],
                          styles['table-body-fixed-right'],
                          blurThreshold && index >= blurThreshold && styles['table-cell-blur'],
                        )}
                      >
                        <div style={{ display: 'flex' }}>
                          {fixedColumnsRight.map((column, columnIndex) => (
                            <div key={columnIndex} style={{ width: column?.width }}>
                              {column?.render(getValueByDataIndex(item, column.dataIndex), index + 1)}
                            </div>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['table-mobile']}>
      <div className={styles['table-mobile-title-wrapper']}>
        <div className={styles['table-mobile-title-value']}>{renderTitle()}</div>

        {actionHeader && <div className={styles['table-mobile-title-actions']}>{actionHeader}</div>}
      </div>

      {data.map((item, index) => {
        const keyTypeOf = typeof item[rowKey];

        return (
          <div
            key={keyTypeOf === 'string' || keyTypeOf === 'number' ? item[rowKey] : index}
            className={styles['table-mobile-wrapper']}
          >
            <div className={styles['table-mobile-card']}>
              {columns
                .filter((i) => i.dataIndex !== 'index' && i.visible !== false)
                .map((column) => {
                  return (
                    <div key={column.dataIndex} className={styles['table-mobile-card-row']}>
                      <div className={styles['table-mobile-card-item-title']}>{column.title}</div>
                      <div className={styles['table-mobile-card-item-children']}>
                        {column?.render(getValueByDataIndex(item, column.dataIndex), index + 1)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
