import React from 'react';

import cn from 'classnames';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './TagLabel.module.scss';

interface Props {
  text: string;
  onClose?: () => void;
  className?: string;
  size?: 'md' | 'lg';
  status?: 'active' | '';
  suffix?: string;
  prefix?: string;
}

export const TagLabel = ({ text, status = '', size = 'md', onClose, prefix, suffix, className = '' }: Props) => {
  return (
    <div className={cn(styles['tag-label'], styles[`tag-label_${status}`], styles[`tag-label_${size}`], className)}>
      {prefix && <Icon type={prefix} className={styles['tag-label_prefix']} />}

      <span>{text}</span>

      {onClose && <Icon type="close" className={styles['tag-label_close']} onClick={onClose} />}
      {suffix && <Icon type={suffix} className={styles['tag-label_suffix']} />}
    </div>
  );
};
