import React, { useContext, useMemo, useState } from 'react';

import cn from 'classnames';

import type { Article } from 'libs/http/api/common/common.types';
import { Link } from 'libs/router/Link';
import { formatDateTime } from 'libs/date';

import { ContentContext } from 'context/ContentContext';

import { Image } from 'ui/atoms/Image';
import { Button } from 'ui/atoms/Button/Button';
import { Tag } from 'ui/atoms/Tag/Tag';
import { TouchTabs, TouchTab } from 'ui/atoms/TouchTab/TouchTab';

import styles from './BlogNote.module.scss';

import { Search } from '../../organisms/Search/Search';
import { ModalFormBook } from '../../organisms/ModalFormBook';

export const BlogNote = () => {
  const [showModal, setShowModal] = useState(false);

  const { data: content, secondaryNavigation, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  /** There is a verification whether article is undefined on page template */
  const article = useMemo(() => content.has_article as Article, [content.has_article]);

  const onToggleModal = () => setShowModal((s) => !s);

  const renderTouchData = useMemo(() => {
    const data = [
      {
        type: article?.touch_1_label,
        description: article?.touch_1_label,
        button: article?.touch_1_button,
        href: article?.touch_1_link,
      },
      {
        type: article?.touch_2_label,
        description: article?.touch_2_label,
        button: article?.touch_2_button,
        href: article?.touch_2_link,
      },
      {
        type: article?.touch_3_label,
        description: article?.touch_3_label,
        button: article?.touch_3_button,
        href: article?.touch_3_link,
      },
    ];

    const tags = article?.tags && article.tags.split('\n');

    return (
      <>
        {article?.touch_title ? (
          <TouchTabs>
            {article?.touch_title && <h2 className={styles['tabs-title']}>{article.touch_title}</h2>}

            <div className={styles['tabs-wrapper']}>
              {data.map(({ type, description, button, href }) => (
                <TouchTab
                  type={type}
                  key={type}
                  description={description}
                  button={button}
                  href={href}
                  className={styles['blog-note-touch']}
                  buttonClassName={styles['blog-note-button']}
                />
              ))}
            </div>
          </TouchTabs>
        ) : null}

        {article?.sample_file?.url || article?.sample_link ? (
          <Button
            onClick={onToggleModal}
            size={48}
            className={cn(
              styles['touch-download-sample'],
              Boolean(tags?.length) === false && styles['has-padding-bottom'],
            )}
          >
            {article.sample_title || commonPageVars.request_sample_button}
          </Button>
        ) : null}

        {tags && tags.length > 0 ? (
          <div className={styles['tag-wrapper']}>
            {tags.map((tag: string) => (
              <Tag key={tag} tagName={tag} type="bullet" className={styles['blog-note-tag']} />
            ))}
          </div>
        ) : null}
      </>
    );
  }, [article, commonPageVars]);

  return (
    <>
      {showModal && <ModalFormBook onClose={onToggleModal} />}

      <div className={styles['blog-note']}>
        {secondaryNavigation.customers?.slug &&
        article.case_studies_type &&
        (Boolean(article.type) === false || article.type === 'case_studies') ? (
          <Link
            tabIndex={0}
            route={secondaryNavigation.customers.slug}
            query={{ paage: 1, type: article.case_studies_type }}
            className={styles['blog-note-type']}
          >
            <span className={styles['blog-note-type']}>
              {commonPageVars[`case_studies_${article.case_studies_type}`]}
            </span>
          </Link>
        ) : null}

        <h1 className={`${styles['blog-note-title']} ${!article.has_author?.slug ? styles['no-author'] : ''}`}>
          {content.title}
        </h1>

        <div className={styles['blog-note-information']}>
          {article.has_author?.id && secondaryNavigation.author?.slug && (
            <Link
              tabIndex={0}
              className={styles['blog-note-link']}
              route={secondaryNavigation.author.slug}
              query={{ id: article.has_author.id }}
            >
              by <span>{article.has_author.full_name}</span>
            </Link>
          )}

          {content.created_at && (
            <div className={styles['blog-note-published']}>
              <span>
                {article.has_author?.id && secondaryNavigation.author?.slug ? ` · ` : ''}
                {formatDateTime(content.created_at)}{' '}
                {article.read_time && ` · ${article.read_time} ${commonPageVars.min_read || 'min read'}`}
              </span>
            </div>
          )}
        </div>

        <Search />

        {renderTouchData}

        {content.background?.url && (
          <Image
            priority
            width={1000}
            height={550}
            src={content.background.url}
            alt={content.title}
            className={styles['blog-note-image']}
          />
        )}
      </div>
    </>
  );
};
