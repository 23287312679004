/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useState, useMemo, useRef, useContext } from 'react';

import cn from 'classnames';
import type { YouTubePlayer } from 'youtube-player/dist/types';

import { useRouter } from 'libs/router/useRouter';
import { isObject } from 'libs/object/object';
import { clearTimeoutIfExists } from 'libs/node';
import { percentage, hmsToSeconds } from 'libs/number';
import { getLocalStorage, setLocalStorage } from 'libs/localStorage';

import { ContentContext } from 'context/ContentContext';

import { VideoPreviewScript } from 'ui/atoms/VideoPreview/VideoPreviewScript';
import { VideoPreview } from 'ui/atoms/VideoPreview/VideoPreview';
import { Image } from 'ui/atoms/Image';

import { CourseCompletion } from 'features/academy/organisms/CourseCompletion/CourseCompletion';

import styles from './LearningPlatform.module.scss';

export const LearningPlatform = () => {
  const { query } = useRouter();
  const { data: content } = useContext(ContentContext);

  const player = useRef<YouTubePlayer>();
  const onOpenCb = useRef<() => void>();
  const debounce = useRef<NodeJS.Timeout>();

  const [activeCourse, setActiveCourse] = useState(0);
  const [progression, setProgression] = useState<Record<number, number>>({});
  const [renderedVideos, setRenderedVideos] = useState<Record<number, undefined>>({ 0: undefined });
  const [videoPreviewScriptLoaded, setVideoPreviewScriptLoaded] = useState(false);

  useEffect(() => {
    const savedProgression = getLocalStorage(`progression-${query.category}`);

    if (isObject(savedProgression)) {
      setProgression(savedProgression);
    }

    return () => {
      clearTimeoutIfExists(debounce.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [courses, course] = useMemo(() => {
    const courses = (content.has_academy_faculty || {}).courses || [];

    return [courses, courses[activeCourse]];
  }, [activeCourse, content.has_academy_faculty]);

  const onInitVideo = (target: YouTubePlayer, cb: { onOpen: () => void }) => {
    player.current = target;

    onOpenCb.current = cb.onOpen;
  };

  const onChangeCourse = (value: typeof activeCourse) => {
    setActiveCourse(value);
    setRenderedVideos((s) => ({ ...s, [value]: undefined }));
  };

  const onVideoPreviewScriptLoad = () => setVideoPreviewScriptLoaded(true);

  const [coursesWithVideos, coursePreview] = useMemo(
    () => [
      courses.filter((i) => Boolean(i.youtube_link)),
      courses.find((i) => i.youtube_preview_image?.url)?.youtube_preview_image,
    ],
    [courses],
  );

  const renderedCourseHeader = useMemo(() => {
    if (coursesWithVideos.length === 0 && coursePreview === undefined) {
      return null;
    }

    if (coursesWithVideos.length === 0 && coursePreview) {
      return (
        <Image
          priority
          className={styles['learning-platform-single-preview']}
          src={coursePreview.url}
          alt={course.name}
          width={coursePreview.width}
          height={coursePreview.height}
        />
      );
    }

    if (!videoPreviewScriptLoaded) {
      return <div className={styles['learning-platform-video-loader']} />;
    }

    const onChangeProgression = (value: number) => {
      if (value > 0) {
        setProgression((prevProgression) => {
          const newProgression = { ...prevProgression };

          const newActiveProgress = percentage(value, hmsToSeconds(course.duration) || 0);

          if (activeCourse in prevProgression === false || prevProgression[activeCourse] < newActiveProgress) {
            newProgression[activeCourse] = newActiveProgress >= 95 ? 100 : newActiveProgress;
          }

          clearTimeoutIfExists(debounce.current);
          debounce.current = setTimeout(() => setLocalStorage(`progression-${query.category}`, newProgression), 250);

          return newProgression;
        });
      }
    };

    return coursesWithVideos.map(
      (item, idx) =>
        idx in renderedVideos && (
          <VideoPreview
            key={item.id}
            idx={idx}
            hidden={activeCourse !== idx}
            embedId={new URL(item.youtube_link).searchParams.get('v')}
            timecode={0}
            onChange={onChangeProgression}
            preview={course.youtube_preview_image}
            onInit={onInitVideo}
          />
        ),
    );
  }, [
    activeCourse,
    course.duration,
    course.name,
    course.youtube_preview_image,
    coursePreview,
    coursesWithVideos,
    query.category,
    renderedVideos,
    videoPreviewScriptLoaded,
  ]);

  if (Boolean(course) === false) {
    return <>No course specified</>;
  }

  return (
    <>
      <VideoPreviewScript onLoad={onVideoPreviewScriptLoad} />

      <div
        className={cn(styles['learning-platform-wrapper'], courses.length === 0 && styles['has-no-course-completion'])}
      >
        <div className={styles['learning-platform-block']}>
          {renderedCourseHeader}

          <div className={styles['learning-platform-description']}>
            <div dangerouslySetInnerHTML={{ __html: course.course_description }} />
          </div>
        </div>

        {courses.length > 0 && (
          <CourseCompletion
            hasVideo={coursesWithVideos.length > 0}
            progression={progression}
            active={courses[activeCourse]}
            data={courses}
            onChange={onChangeCourse}
          />
        )}
      </div>
    </>
  );
};
