import { Icon } from 'ui/atoms/Icon/Icon';

interface ContentProps {
  item: string[];
  title?: string;
}

export const PlanCollapseContent = ({ item, title }: ContentProps) => {
  if (!item) {
    return null;
  }

  return (
    <div className="plan-collapse__info">
      {title && <div>{title}</div>}

      {item.map((element, key) => (
        <div className="plan-collapse__title" key={key}>
          <Icon type="check-mark" className="icon-check" />
          <span>{element}</span>
        </div>
      ))}
    </div>
  );
};
