import { Button } from 'ui/atoms/Button/Button';

interface Props {
  freshInvestigation?: boolean;
  slug?: string;
  description?: string;
  buttonTitle?: string;
}

export const EmptyBlock = ({ freshInvestigation, description, buttonTitle, slug }: Props) => (
  <div className="empty-block">
    {freshInvestigation ? (
      <div className="fresh-investigation-wrapper">
        <span>{description}</span>

        <Button type="invert" size={48} route={slug}>
          {buttonTitle}
        </Button>
      </div>
    ) : (
      <div className="fresh-investigation-wrapper">
        <span>Not Found</span>
      </div>
    )}
  </div>
);
