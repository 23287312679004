import Head from 'next/head';
import cn from 'classnames';

import { Link } from 'libs/router/Link';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './Breadcrumbs.module.scss';

interface Props {
  data: {
    slug?: string;
    name?: string;
    key?: number;
    isLast?: boolean;
    query?: {
      paage?: number;
      type?: string;
    };
  }[];
}

export const Breadcrumbs = ({ data = [] }: Props) => {
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: data.map((item, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: item.name,
                item: item.slug,
              })),
            }),
          }}
        />
      </Head>

      <ul className={styles['breadcrumb']}>
        {data.map((item) => (
          <li key={item.key} className={styles['breadcrumb-element']}>
            <Link
              tabIndex={item.isLast ? -1 : 0}
              route={item.slug}
              query={item.query}
              className={cn(styles['breadcrumb-element-link'], item.isLast ? styles['disabled'] : '')}
              aria-current={item.isLast ? 'page' : undefined}
            >
              <span className={styles['breadcrumb-element-name']}>{item.name}</span>
            </Link>

            {item.isLast ? '' : <Icon type="arrow-down" className={styles['breadcrumb-element-divider']} />}
          </li>
        ))}
      </ul>
    </>
  );
};
