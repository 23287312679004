import { useMemo } from 'react';

import { useMediaQuery } from 'hooks/useMediaQuery';

import { Slider } from 'ui/atoms/Slider/Slider';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';
import { PropsCardData } from 'ui/molecules/CardsCustomer/CardsCustomer';

import styles from './Cards.module.scss';

interface Props {
  cardData: PropsCardData[];
  hasData: any[];
}

const Cards = ({ cardData = [], hasData }: Props) => {
  const isDesktop = useMediaQuery(1024);

  const renderCards = useMemo(
    () =>
      cardData
        .filter((i) => i.title || i.description)
        .map((item) => (
          <div className={styles['customer-block__each-block']} key={item.title}>
            <div className={styles['customer-block__text']}>
              <div className={styles['customer-block__rectangles']}>
                <div className={styles['icon-rectangles-one']}>
                  <Icon type={item.iconType} />
                </div>
              </div>

              <div className={styles['customer-block__title']}>
                <h2>{item.title}</h2>
              </div>

              <div className={styles['customer-block__info']}>{item.description}</div>
            </div>

            {item.slug && (
              <div className={styles['customer-block__button']}>
                <Button size={48} type="invert" route={item.slug}>
                  {item.buttonText}
                </Button>
              </div>
            )}
          </div>
        )),
    [],
  );

  if (hasData.length === 0) {
    return null;
  }

  if (isDesktop) {
    return <div className={styles['customer-block']}>{renderCards}</div>;
  }

  return (
    <div className={styles['customer-block']}>
      <Slider className={styles['slider-customer-block']} heightArrow="middle">
        {renderCards}
      </Slider>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Cards;
