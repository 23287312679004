import { useCallback, useState, useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useProducts } from 'hooks/useProducts';
import { useResizeListener } from 'hooks/useResizeListener';

import { Loader } from 'ui/atoms/Loader/Loader';
import { HeroText } from 'ui/atoms/HeroText/HeroText';
import { Faq } from 'ui/atoms/Faq/Faq';
import { PresentationBlockLoader } from 'ui/organisms/PresentationBlock/PresentationBlockLoader';
import { OurClients } from 'ui/molecules/OurClients/OurClients';
import { PlansTable } from 'ui/molecules/PlansTable/PlansTable';
import { DynamicImport } from 'ui/DynamicImport';

import { FrequencySwitch } from 'features/product-pricing/organisms/FrequencySwitch/FrequencySwitch';
import { PricingCards } from 'features/product-pricing/organisms/PricingCards/PricingCards';

// Standalone styles that might later be added back
// import styles from './ProductPricingPage.module.scss';

const PADDING_BOTTOM_GAP = 150;

const frequencies = [
  { value: 'month', label: 'Bill monthly' },
  { value: 'year', label: 'Bill yearly', discount: 20 },
];

const ProductPricingPage = () => {
  const { query, push } = useRouter();
  const { config, secondaryNavigation, additionals, pageVars } = useContext(ContentContext);

  /** This state is considered only if hasFrequency is true */
  const [frequency, setFrequency] = useState(config.is_yearly_default_on_pricing ? 'year' : 'month');

  const { data, loading, iframeData } = useProducts(() => {
    return push(secondaryNavigation.pricing.slug, { lang: `${query.lang}` });
  });

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const pricingPageVars = useMemo(() => pageVars.pricing_products || {}, [pageVars.pricing_products]);

  const resized = useCallback(() => {
    const height = document.getElementById('__next')?.offsetHeight || 0;

    if (!window.top) {
      return;
    }

    window.top.postMessage({ height: height + PADDING_BOTTOM_GAP }, '*');
  }, []);

  useResizeListener(() => {
    if (loading) {
      return;
    }

    resized();
  }, [loading, resized]);

  const hasFrequency = useMemo(() => {
    /**
     * Year price is mandatory, it can be blank, but only together with month price
     * which basically means that it is Call to action offer (e.g. book a demo, call & etc).
     */

    return data.reduce((acc, current) => {
      if (acc) {
        acc = (Boolean(current.price_month) && Boolean(current.price_year)) === false || Boolean(current.price_month);
      }

      return acc;
    }, true);
  }, [data]);

  if (loading) {
    return <Loader loading height="calc(100vh - 140px)" />;
  }

  return (
    <>
      <HeroText size="lg">{iframeData.title || pricingPageVars.title}</HeroText>

      {hasFrequency && iframeData.hasFrequencySwitcher !== false && (
        <FrequencySwitch data={frequencies} value={frequency} onChange={setFrequency} />
      )}

      <PricingCards
        frequency={hasFrequency ? frequency : 'year'}
        callToActionLink={iframeData.ctaLink}
        callToActionText={iframeData.ctaText}
        data={data}
      />

      <PlansTable data={data} footnote={true} />

      <DynamicImport fallback={() => <PresentationBlockLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/organisms/PresentationBlock/PresentationBlock'), {
            ssr: false,
            loading: () => <PresentationBlockLoader />,
          });

          return <Component id={iframeData.presentationId || 'aggregator-white-bg'} className="mb-55" />;
        }}
      </DynamicImport>

      {/* Standalone data that might later be added back */}
      {/* <div className={styles['products-standalone']}>
        <HeroText>
          Don’t like the bundles? <br />
          Get each service as standalone deals:
        </HeroText>

        <PricingCards data={standaloneData} />
      </div> */}

      <OurClients />

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq className="pb-55" title={commonPageVars.faq_2} data={additionals.faq.data} />
      )}
    </>
  );
};

/**
 * please, ignore export default error
 * Only pages require to have export default as they are imported dynamically
 */

// eslint-disable-next-line import/no-default-export
export default ProductPricingPage;
