import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { isVideo } from 'libs/string';

import { ContentContext } from 'context/ContentContext';

import { Cards } from 'ui/atoms/Cards/Cards';
import { Faq } from 'ui/atoms/Faq/Faq';
import { DataBlock } from 'ui/molecules/DataBlock/DataBlock';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { VideoBlock } from 'ui/molecules/VideoBlock/VideoBlock';
import { DynamicImport } from 'ui/DynamicImport';

import { CreditReportResults } from 'features/products/organisms/CreditReportResults/CreditReportResults';

const LeadGenerationPage = () => {
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const pagePageVars = useMemo(() => pageVars[content.page_var_suffix || content.page_type] || {}, [content, pageVars]);

  const leadGeneration = useMemo(
    () => [
      {
        title: pagePageVars.block_title,
        description: pagePageVars.block_description,
        [isVideo(pagePageVars.block_image) ? 'video' : 'img']: pagePageVars.block_image,
      },
      {
        type: 'get-data-reverse',
        title: pagePageVars.block_title_2,
        description: pagePageVars.block_description_2,
        [isVideo(pagePageVars.block_image_2) ? 'video' : 'img']: pagePageVars.block_image_2,
      },
      {
        title: pagePageVars.block_title_3,
        description: pagePageVars.block_description_3,
        [isVideo(pagePageVars.block_image_3) ? 'video' : 'img']: pagePageVars.block_image_3,
      },
      {
        type: 'get-data-reverse',
        title: pagePageVars.block_title_4,
        description: pagePageVars.block_description_4,
        [isVideo(pagePageVars.block_image_4) ? 'video' : 'img']: pagePageVars.block_image_4,
      },
      {
        title: pagePageVars.block_title_5,
        description: pagePageVars.block_description_5,
        [isVideo(pagePageVars.block_image_5) ? 'video' : 'img']: pagePageVars.block_image_5,
      },
      {
        type: 'get-data-reverse',
        title: pagePageVars.block_title_6,
        description: pagePageVars.block_description_6,
        [isVideo(pagePageVars.block_image_6) ? 'video' : 'img']: pagePageVars.block_image_6,
      },
    ],
    [pagePageVars],
  );

  const cardsData = useMemo(
    () =>
      ['', '_2', '_3'].map((i) => ({
        title: pagePageVars[`title${i}`],
        description: pagePageVars[`description${i}`],
      })),
    [pagePageVars],
  );

  return (
    <div className="lead-generation">
      <InfoBlock
        title={content.title}
        description={content.description}
        buttonText={content.button_text}
        buttonHref={content.button_href}
      />

      {content.is_credit_report && <CreditReportResults />}

      <Cards data={cardsData} />

      <InfoBlock description={pagePageVars.description_4} disabled type="lead-generation" />

      {leadGeneration &&
        leadGeneration
          .filter((_, key) => key < 3)
          .map((data, key) => (
            <DataBlock
              key={key}
              title={data.title}
              description={data.description}
              img={data.img}
              video={data.video}
              type={data.type}
            />
          ))}

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      {leadGeneration &&
        leadGeneration
          .filter((_, key) => key > 2)
          .map((data, key) => (
            <DataBlock
              key={key}
              title={data.title}
              description={data.description}
              img={data.img}
              video={data.video}
              type={data.type}
            />
          ))}

      <VideoBlock
        title={pagePageVars.presentation_title}
        description={pagePageVars.presentation_description}
        hasWhiteBg
      />

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq title={commonPageVars.faq_2} data={additionals.faq.data} />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LeadGenerationPage;
