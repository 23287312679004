import { useMemo, useContext } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';
import { DynamicImport } from 'ui/DynamicImport';

import { PeopleFeedbackLoader } from 'features/customers/organisms/PeopleFeedback/PeopleFeedbackLoader';

import styles from './OurClients.module.scss';

export const OurClients = () => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const pricingPageVars = useMemo(() => pageVars.pricing_products || {}, [pageVars.pricing_products]);

  const renderedRatings = useMemo(
    () => [1, 2, 3, 4, 5].map((item) => <Icon type={`report-rating-${item}`} key={item} />),
    [],
  );

  return (
    <div className={styles['our-clients-wrapper']}>
      <div className={styles['our-clients-container']}>
        <div className={styles['our-clients-description']}>
          <h2>{pricingPageVars.our_client_title}</h2>

          <div className={styles['our-clients-inner']}>
            <p>{pricingPageVars.our_clients_description}</p>

            {secondaryNavigation.book_demo?.slug && (
              <Button type="invert" id="our-clients" route={secondaryNavigation.book_demo.slug}>
                {pricingPageVars.our_clients_cta}
              </Button>
            )}
          </div>

          <OurCustomers hasBackground={false} className={styles['our-clients-position']} />

          <div className={styles['our-clients-rating']}>{renderedRatings}</div>
        </div>

        <DynamicImport fallback={() => <PeopleFeedbackLoader />}>
          {() => {
            const Component = dynamic(() => import('features/customers/organisms/PeopleFeedback/PeopleFeedback'), {
              ssr: false,
              loading: () => <PeopleFeedbackLoader />,
            });

            return <Component hasBackground={false} />;
          }}
        </DynamicImport>
      </div>
    </div>
  );
};
