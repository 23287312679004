import { useEffect, useMemo } from 'react';

import cn from 'classnames';

import { content } from 'libs/http/api/content/content';
import type { Presentation as PresentationType } from 'libs/http/api/content/content.types';
import { useRouter } from 'libs/router/useRouter';

import { useRequest } from 'hooks/useRequest';

import { Presentation } from 'ui/organisms/Presentation/Presentation';

import { PresentationBlockLoader } from './PresentationBlockLoader';

type Props = {
  id?: string;
  className?: string;
  hasTextWrapper?: boolean;
};

export const PresentationBlock = ({ id, className = '', hasTextWrapper = true }: Props) => {
  const { query } = useRouter();
  const { request, data: $data, errors, loading } = useRequest<PresentationType[]>();

  useEffect(() => {
    request(
      content.presentation({
        lang: `${query.lang}`,
        id,
      }),
    );
  }, [id]);

  const data = useMemo(() => $data?.[0] || {}, [$data]);

  if (Object.keys(errors).length > 0) {
    // eslint-disable-next-line no-console
    console.error('Could not render Presentation Block. Unexpected error occurred.');
    return null;
  }

  if (loading) {
    return <PresentationBlockLoader />;
  }

  if (!hasTextWrapper) {
    return <Presentation type="icon" video={data.youtube_video_link} />;
  }

  return (
    <div className={cn('watch-video', `watch-video-layout-${data.layout || 'inline'}`, className)}>
      <div className="watch-video__info">
        {data.title && (
          <div className="watch-video__title">
            <h2>{data.title}</h2>
          </div>
        )}

        {data.description && (
          <div className="watch-video__text" dangerouslySetInnerHTML={{ __html: data.description }} />
        )}
      </div>

      <div className="watch-video__video">
        <Presentation
          type={data.cta_type || undefined}
          ctaText={data.cta_text || undefined}
          preview={data.preview?.url}
          video={data.youtube_video_link}
          buttonSize={data.button_size}
          buttonType={data.button_type}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default PresentationBlock;
