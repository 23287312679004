import { useContext, useRef } from 'react';

import cn from 'classnames';

import { ContentContext } from 'context/ContentContext';

import { useIntersection } from 'hooks/useIntersection';

import { Image } from 'ui/atoms/Image';
import { DataBlock } from 'ui/molecules/DataBlock/DataBlock';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

const SalesForcePage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { data: content } = useContext(ContentContext);

  if (content.has_integration_information === undefined) {
    return <span>Oops! {"There's"} no integration information on Global CMS.</span>;
  }

  return (
    <div className="sales-force">
      <InfoBlock
        title={content.title}
        description={content.description}
        buttonText={content.button_text}
        buttonHref={content.button_href}
      />

      <div ref={ref} className={cn('circle-content', 'sales-force-content', intersected && 'intersected')}>
        <div className="circle-content__wrapper">
          <div className="center-image">
            <Image
              priority
              objectFit="contain"
              width={114}
              height={80}
              src={content.has_integration_information.icon.url}
              alt={content.title}
            />
          </div>
        </div>
      </div>

      <InfoBlock description={content.has_integration_information.motivation_text} disabled />

      <DataBlock
        title={content.has_integration_information.section_header_1}
        description={content.has_integration_information.section_description_1}
        img="img/integrations/1_financial_analysis_and_reporting.jpg"
      />

      <DataBlock
        type="get-data-reverse"
        title={content.has_integration_information.section_header_2}
        description={content.has_integration_information.section_description_2}
        img="img/integrations/2_account-based-marketing-vendors-abm-strategy-campaign.jpg"
      />

      <DataBlock
        title={content.has_integration_information.section_header_3}
        description={content.has_integration_information.section_description_3}
        img="img/integrations/3_Technology-stack_Corporate-Bulk-Data.jpg"
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default SalesForcePage;
