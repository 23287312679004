import { useRef, useState } from 'react';

import cn from 'classnames';

import { useIntersection } from 'hooks/useIntersection';
import { useImage } from 'hooks/useImage';

import { Button } from 'ui/atoms/Button/Button';

import { ModalFormBook } from 'features/articles/organisms/ModalFormBook';

import styles from './DownloadSample.module.scss';

interface Props {
  className?: string;
  link?: string;
  title?: string;
  button?: string;
}

export const DownloadSample = ({ className = '', title, button }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const [showModal, setShowModal] = useState(false);
  const { buildUrl } = useImage();

  const onToggleModal = () => setShowModal((s) => !s);

  return (
    <>
      {showModal && <ModalFormBook onClose={onToggleModal} />}

      <div
        ref={ref}
        className={cn(styles['download-sample'], className)}
        style={{
          backgroundImage: intersected
            ? `url('${buildUrl({
                src: 'img/request.jpg',
                width: 1216,
                height: 360,
              })}')`
            : undefined,
        }}
      >
        <div className={styles['download-sample-title']}>
          <h3>{title}</h3>
        </div>

        <Button onClick={onToggleModal} className={styles['download-sample-button']} type="transparent" size={64}>
          {button}
        </Button>
      </div>
    </>
  );
};
