import { useContext, useMemo, useRef } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useIntersection } from 'hooks/useIntersection';

import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';

import { FormBook } from '../organisms/FormBook/FormBook';
import { PlanCollapse } from '../organisms/PlanCollapse/PlanCollapse';

const BookDemoPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { query } = useRouter();
  const { data: content, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const reversed = useMemo(() => ['free_trial', 'book_demo'].includes(content.navigation_id), [content.navigation_id]);

  return (
    <>
      <div className={`plan-collapse plan-collapse-flex ${reversed ? 'plan-collapse-reverse' : ''}`}>
        <div
          ref={ref}
          className={cn('plan-collapse__left', reversed && 'plan-collapse__left-reverse', intersected && 'intersected')}
        >
          <PlanCollapse />
        </div>

        <div className="plan-collapse__right">
          <FormBook
            title={(query.request_sample && commonPageVars.touch_request_sample_button) || content.title}
            type="book"
          />
        </div>
      </div>

      <OurCustomers hasBackground={false} className="pt-40" />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default BookDemoPage;
