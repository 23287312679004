import { FormEvent, useCallback, useContext, useMemo, useEffect } from 'react';

import cn from 'classnames';

import type { BookDemoType } from 'libs/http/api/dashboard/dashboard.types';
import type { $Object } from 'libs/object/object.types';
import { Article } from 'libs/http/api/common/common.types';
import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { isNotBusinessEmail } from 'libs/string';
import { getAbsoluteRoute } from 'libs/router/helpers';

import { LeadContext } from 'context/LeadContext';
import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';

import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';
import { Input } from 'ui/atoms/Input/Input';
import { Button } from 'ui/atoms/Button/Button';

import { FormHubspot } from 'features/customers/organisms/FormHubspot/FormHubspot';

interface FormBookProps {
  title?: string;
  product?: string;
  hasArea?: boolean;
  isCountryPromotion?: boolean;
  type: BookDemoType;
  onSubmit?: () => void;
  loading?: boolean;
  errors?: {
    [key: string]: string | string[];
  };
}

const formIds = {
  book: {
    es: '8a463741-f45b-4fbf-bfb0-7f5bb9db4d1a',
    it: '0b136208-5d7f-4dfe-9b4d-801e8c446dab',
    fr: '50de006f-06a7-41aa-a564-5b0dc6903a03',
    default: '6e7ab485-eb6e-4d3f-a732-bd63d6ec49d2',
  },
  quote: {
    es: '7cbf584f-3d1f-4492-b0ec-e92d57cb4fca',
    it: '9067bef2-bb40-4689-8e5a-24cd8f20e429',
    fr: '56f774f2-8030-43fa-9390-8dd7f83297de',
    default: 'f1e4e14c-52fd-4292-836f-a63a540866f3',
  },
};

export const FormBook = ({
  type,
  product,
  title,
  hasArea,
  isCountryPromotion,
  onSubmit: onExternalSubmit,
  loading: loadingFetch,
  errors: errorsFetch,
}: FormBookProps) => {
  const { query, push } = useRouter();
  const { request, errors, loading } = useRequest();
  const { secondaryNavigation, data: content, pageVars } = useContext(ContentContext);
  const { leadData, setLeadData } = useContext(LeadContext);

  const article = useMemo(() => content.has_article as Article, [content.has_article]);

  useEffect(() => {
    if (typeof query?.message === 'string') {
      setLeadData({ message: decodeURIComponent(query.message) });
    }
  }, [query?.message]);

  const onHandleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (typeof onExternalSubmit === 'function') {
      onExternalSubmit();
    } else {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    await request(
      dashboard.book({
        full_name: leadData.full_name,
        email: leadData.email,
        phone: leadData.phone,
        message: hasArea ? leadData.message : undefined,
        type,
        lang: `${query.lang}`,
        additional_data: {
          product: type === 'quote' ? product : undefined,
        },
      }),
    );

    if (!secondaryNavigation.thank_you?.slug) {
      return;
    }

    const pushQuery = {} as $Object<any>;

    if (product) {
      pushQuery.type = product;
    }

    if (query.request_sample) {
      pushQuery.request_sample = query.request_sample;
    }

    if (article?.sample_file?.url || article?.sample_link) {
      pushQuery.sample_link = article?.sample_file?.url || article.sample_link;
    }

    try {
      await push(secondaryNavigation.thank_you.slug, { lang: `${query.lang}` }, pushQuery);

      setLeadData({ ...leadData, message: '' });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const onChange = useCallback((target: string) => (value: string) => setLeadData({ [target]: value }), []);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  if (type in formIds) {
    const redirectUrl = (() => {
      if (!secondaryNavigation.thank_you?.slug) {
        return undefined;
      }

      const pushQuery = {} as $Object<any>;

      if (product) {
        pushQuery.type = product;
      }

      if (query.request_sample) {
        pushQuery.request_sample = query.request_sample;
      }

      if (article?.sample_file?.url || article?.sample_link) {
        pushQuery.sample_link = article?.sample_file?.url || article.sample_link;
      }

      return getAbsoluteRoute(secondaryNavigation.thank_you?.slug, { lang: `${query.lang}` }, pushQuery);
    })();

    return (
      <FormHubspot
        data={formIds[type as keyof typeof formIds]}
        redirectUrl={redirectUrl}
        onFormSubmitted={onExternalSubmit}
      />
    );
  }

  return (
    <div className={cn('form-book', isCountryPromotion && 'country-promotion')}>
      <form onSubmit={onHandleSubmit}>
        {title && <h5>{title}</h5>}

        <Input
          type="primary"
          dataTestId="form-test-name"
          ariaLabel={commonPageVars.full_name}
          text={commonPageVars.full_name}
          value={leadData.full_name}
          name="full_name"
          onChange={onChange('full_name')}
          error={errors.full_name || errorsFetch?.full_name}
        />

        <Input
          type="primary"
          htmlType="email"
          dataTestId="form-test-email"
          ariaLabel={commonPageVars.work_email}
          text={commonPageVars.work_email}
          value={leadData.email}
          name="email"
          onChange={onChange('email')}
          error={
            isNotBusinessEmail(leadData.email)
              ? commonPageVars.business_email_validation
              : errors.email || errorsFetch?.email
          }
        />

        <Input
          type="primary"
          htmlType="number"
          dataTestId="form-test-phone"
          ariaLabel={commonPageVars.phone_label}
          text={commonPageVars.phone_label}
          value={leadData.phone}
          name="phone"
          onChange={onChange('phone')}
          error={errors.phone || errorsFetch?.phone}
        />

        {hasArea && (
          <Input
            htmlType="textarea"
            ariaLabel="Messages"
            dataTestId="form-test-messages"
            text="Messages"
            value={leadData.message}
            name="messages"
            error={errors.message || errorsFetch?.message}
            onChange={onChange('message')}
          />
        )}

        {errors.detail && <ErrorMessage error={errors.detail} position="right" />}

        <Button htmlType="submit" loading={loading || loadingFetch}>
          {content.button_text || 'Submit'}
        </Button>

        {[1, 2, 3, 4].map((i) => commonPageVars[`book_demo_submitting_${i}`]).filter((i) => i).length > 0 && (
          <div className="sub-button-text">
            {commonPageVars.book_demo_submitting_1}{' '}
            <Link tabIndex={0} route={secondaryNavigation.policy.slug}>
              {commonPageVars.book_demo_submitting_2}
            </Link>{' '}
            {commonPageVars.book_demo_submitting_3}{' '}
            <Link tabIndex={0} route={secondaryNavigation.terms.slug}>
              {commonPageVars.book_demo_submitting_4}
            </Link>
          </div>
        )}
      </form>
    </div>
  );
};
