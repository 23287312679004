import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { Cards } from 'ui/atoms/Cards/Cards';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

import { WebTechnologies } from 'features/products/organisms/WebTechnologies/WebTechnologies';
import { WebTechWidget } from 'features/products/organisms/WebTechWidget/WebTechWidget';

const InsightsPage = () => {
  const { data: content, additionals } = useContext(ContentContext);

  const cardsData = useMemo(
    () =>
      additionals.features?.data.map(({ title, description }) => ({
        title,
        description,
      })),
    [additionals],
  );

  return (
    <div className="insights-page">
      <InfoBlock
        title={content.title}
        description={content.description}
        buttonText={content.button_text}
        buttonHref={content.button_href}
      />

      <Cards data={cardsData} />

      <WebTechnologies />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component type="gray" title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      <WebTechWidget />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default InsightsPage;
