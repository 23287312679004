import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './HeroText.module.scss';

interface Props {
  /** @default 'md' */
  size?: 'md' | 'lg';
  /** @default 'center' */
  align?: 'center' | 'left' | 'right';
  /** @default 'h2' */
  tagName?: 'div' | 'h1' | 'h2' | 'p';
  className?: string;
  children?: ReactNode;
}

export const HeroText = ({
  tagName: Component = 'h2',
  size = 'md',
  align = 'center',
  className = '',
  children,
}: Props) => {
  return (
    <Component className={cn(styles['hero-text'], styles[`hero-text-${size}`], styles[align], className)}>
      {children}
    </Component>
  );
};
