import type { ChangeEventHandler } from 'react';

import cn from 'classnames';

import styles from './Switch.module.scss';

type Props = {
  onChange: (value: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  className?: string;
};

export const Switch = ({ onChange, checked = false, disabled = false, className = '' }: Props) => {
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (ev) => onChange(ev.target.checked);

  return (
    <div className={cn(styles['switch'], className)}>
      <input type="checkbox" checked={checked} disabled={disabled} onChange={onChangeHandler} id="switch" />
      <label htmlFor="switch" />
    </div>
  );
};
