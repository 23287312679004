export const getLocalStorage = (key: string): any | null => {
  let serializedValue = null;

  try {
    serializedValue = localStorage?.getItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error accessing localStorage:', error);
  }

  if (serializedValue === null) {
    return serializedValue;
  }

  try {
    const parsedValue = JSON.parse(serializedValue);
    return parsedValue;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error getting localStorage item:', err);
  }

  return serializedValue;
};

export const setLocalStorage = (key: string, value: any) => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error setting localStorage item:', err);
  }
};

export const removeLocalStorage = (key: string) => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem(key);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error setting localStorage item:', err);
  }
};
