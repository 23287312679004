import { BenefitCard } from 'features/about/atoms/BenefitCard/BenefitCard';

import styles from './Benefits.module.scss';

type Props = {
  heading: string;
  description: string;
  benefits: {
    text: string;
    icon: string;
  }[];
};

export const Benefits = ({ benefits, heading, description }: Props) => {
  return (
    <div className={styles['career-benefits-wrapper']}>
      <div className={styles['benefits-container']}>
        <div className={styles['benefits-info']}>
          <h2>{heading}</h2>
          <p>{description}</p>
        </div>
        <div className={styles['benefits']}>
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} text={benefit.text} icon={benefit.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};
