import { useMemo, useContext, useRef } from 'react';

import cn from 'classnames';

import { Link } from 'libs/router/Link';
import { Page } from 'libs/http/api/common/common.types';

import { ContentContext } from 'context/ContentContext';

import { useImage } from 'hooks/useImage';
import { useIntersection } from 'hooks/useIntersection';

import { Image } from 'ui/atoms/Image';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

export interface Article {
  title: string;
  type: string;
  short_description: string;
  img: string;
  content: string;
}

interface Props {
  hasPriortyFirstImage?: boolean;
  posts: Page[];
}

export const Blog = ({ hasPriortyFirstImage = false, posts = [] }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();
  const { pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const buildSrc = useMemo(
    () => buildUrl({ src: posts[0].background?.url, width: 560, height: 412 }),
    [buildUrl, posts],
  );

  return (
    <div className="benefits-blog">
      {posts.length >= 1 && posts[0].has_article && (
        <div ref={ref} className="benefits-blog-left">
          <Link
            key={posts[0].id}
            tabIndex={0}
            route={posts[0].slug}
            className="benefits-blog-link"
            style={{
              backgroundImage: hasPriortyFirstImage || intersected ? `url(${buildSrc})` : undefined,
              backgroundSize: 'contain',
            }}
          >
            {(loading) =>
              posts[0].has_article ? (
                <>
                  <LoaderSpin className="benefits-blog-left-loading" color="white" size="small" loading={loading}/>

                  <div className="benefits-blog-left-type">
                    <span>
                      {posts[0].has_article.case_studies_type
                        ? commonPageVars[`case_studies_${posts[0].has_article.case_studies_type}`]
                        : commonPageVars[`blog_${posts[0].has_article.type}`]}
                    </span>

                    {posts[0].has_article.read_time && (
                      <span>
                        {posts[0].has_article.read_time} {commonPageVars.min_read || 'min read'}
                      </span>
                    )}
                  </div>

                  <h2 className={cn('benefits-blog-left-title', loading && 'loading')}>{posts[0].title}</h2>

                  <div
                    className="benefits-blog-description"
                    dangerouslySetInnerHTML={{ __html: posts[0].description }}
                  />
                </>
              ) : null
            }
          </Link>
        </div>
      )}

      <div className="benefits-blog-right">
        {posts
          .filter((_, key) => key !== 0)
          .map(
            (item) =>
              item.has_article && (
                <Link key={item.id} route={item.slug} tabIndex={0}>
                  {(loading) =>
                    item.has_article ? (
                      <>
                        {item.background && (
                          <Image width={176} height={132} src={item.background.url} alt={item.has_article.type} />
                        )}

                        <div className="benefits-blog-dep">
                          <div className="benefits-blog-type">
                            <span>
                              {item.has_article.case_studies_type
                                ? commonPageVars[`case_studies_${item.has_article.case_studies_type}`]
                                : commonPageVars[`blog_${item.has_article.type}`]}
                            </span>

                            {item.has_article.read_time && <span>{item.has_article.read_time} min read</span>}
                          </div>

                          <h2 className={cn(loading && 'loading')}>{item.title}</h2>
                        </div>

                        <LoaderSpin className="benefits-blog-loading" color="white" size="small" loading={loading} />
                      </>
                    ) : null
                  }
                </Link>
              ),
          )}
      </div>
    </div>
  );
};
