import { ReactNode, useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';

import { useIntersection } from 'hooks/useIntersection';
import { useStateHandlers } from 'hooks/useStateHandlers';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Mask } from 'ui/atoms/Mask/Mask';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';
import { Option } from 'ui/atoms/Dropdown/Dropdown';

export interface ChildrenParams {
  onCloseMenu: () => void;
}

export interface MegaOption {
  loading?: boolean;
  disabled?: boolean;
  value?: string;
  title: string;
  className?: string;
  render?: (params: ChildrenParams) => ReactNode;
  children?: Option[];
}

interface Props {
  value?: string;
  label: ReactNode;
  options: MegaOption[];
  width?: number;
  loading?: boolean;
}

export const MegaDropdown = ({ label, value, options = [], width, loading }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const [{ show, parent }, setState] = useStateHandlers({
    show: false,
    parent: options?.[0]?.value || '',
  });

  useEffect(() => {
    if (options.length > 0) {
      setState({ parent: options[0].value });
    }
  }, [options]);

  const renderItems = useCallback(
    ({ className = '', ...itemProps }: MegaOption, key: number) => (
      <div
        key={key}
        className={`rc-dropdown-options-item ${className}${
          parent === itemProps.value || value === itemProps.value ? ' rc-dropdown-options-item-open' : ''
        }${itemProps.loading ? ' loading' : ''}${itemProps.disabled ? ' disabled' : ''}`}
        onClick={
          (itemProps.children || itemProps.render) && !loading && !itemProps.disabled
            ? () => onClickItem(itemProps.value)
            : undefined
        }
      >
        {itemProps.title}

        {itemProps.loading && <LoaderSpin />}
      </div>
    ),
    [parent, value],
  );

  const onToggle = () => setState((s) => ({ show: !s.show }));
  const onClose = () => setState({ show: false });

  const onClickItem = (parent = '') => setState({ parent });

  return (
    <>
      {show && <Mask type="transparent" onClick={onClose} zIndex={98} />}

      <div
        className={`rc-dropdown rc-dropdown-mega${show ? ' rc-dropdown-open' : ''}${
          loading ? ' rc-dropdown-loading' : ''
        }`}
      >
        <div className="rc-dropdown-label" onClick={!loading ? onToggle : undefined}>
          {label} <Icon type="arrow-down" />
        </div>

        <div className="rc-dropdown-options" style={{ minWidth: width || undefined }}>
          <div ref={ref} className={cn('rc-dropdown-options-row', intersected && 'intersected')}>
            {options.map(renderItems)}
          </div>

          {options.map((item) => (
            <div
              ref={ref}
              style={{ display: parent !== item.value ? 'none' : undefined }}
              key={item.value}
              className={cn('rc-dropdown-options-row', intersected && 'intersected')}
            >
              {item.render?.({ onCloseMenu: onClose })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
