import { fetcher } from '../../fetcher';
import { Ip } from './external.types';

export const external = {
  getIp: () =>
    fetcher.get<Ip>(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.IPGEOLOCATION || 'fa8dde18650f4236b5c450e041c13a5a'}`,
      { lang: 'en' },
    ),
};
