import { createContext, ReactNode } from 'react';

import { keys } from 'libs/object/object';
import { Page } from 'libs/http/api/common/common.types';
import { Config } from 'libs/http/api/content/content.types';

import { Additionals, NavigationGroup, NavigationProps, SecondaryNavigation } from 'stores/content/content.types';
import { Alternative, PageVars } from 'stores/intl/ints.types';

export const initialValues = {
  lang: '',
  page: 1,
  pathname: '',

  data: {} as Page,
  additionals: {} as Additionals,

  navigation: [] as NavigationProps[],
  clientNavigationGroups: {} as NavigationGroup,
  navigationGroups: {} as NavigationGroup,
  secondaryNavigation: {} as SecondaryNavigation,

  links: [] as Alternative[],
  pageVars: {} as PageVars,

  config: {} as Config,
};

export const ContentContext = createContext<typeof initialValues>(initialValues);

export const ContentProvider = ({ children, ...props }: { children: ReactNode } & typeof initialValues) => {
  return (
    <ContentContext.Provider
      value={keys(initialValues).reduce(
        (acc, current) => ({
          ...acc,
          [current]: props[current],
        }),
        initialValues,
      )}
    >
      {children}
    </ContentContext.Provider>
  );
};
