import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import cn from 'classnames';
import type { YouTubePlayer } from 'youtube-player/dist/types';

import { useRouter } from 'libs/router/useRouter';
import { AcademyCourseImage } from 'libs/http/api/content/content.types';

import { Image } from 'ui/atoms/Image';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './VideoPreview.module.scss';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

interface Props {
  idx?: number;
  onInit: (player: YouTubePlayer, cb: { onOpen: () => void }) => void;
  onChange: (value: number) => void;
  hidden?: boolean;
  type?: 'text-icon' | 'icon';
  embedId: string | null;
  timecode: number;
  preview: AcademyCourseImage;
}

const getPlayerId = (idx?: number) => `player-iframe-${idx || 0}`;

export const VideoPreview = ({ idx, onInit, onChange, hidden, type, embedId, timecode, preview }: Props) => {
  const { query } = useRouter();

  const [open, setOpen] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const player = useRef<YouTubePlayer>();
  const autoplayed = useRef(false);

  useEffect(() => {
    return () => {
      if (iframeRef.current) {
        iframeRef.current.nextElementSibling?.remove();
      }

      if (player.current) {
        player.current.destroy();
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (embedId && player.current === undefined) {
      player.current = new window.YT.Player(getPlayerId(idx), {
        height: '456',
        width: '100%',
        videoId: embedId,
        startSeconds: timecode,
        playerVars: {
          autoplay: 0,
          start: query.t || timecode || 0,
        },
      });

      if (player.current) {
        onInit(player.current, { onOpen: () => setOpen(true) });
      }

      setInterval(async () => {
        if (player.current?.getCurrentTime) {
          if (query.autoplay && autoplayed.current === false) {
            autoplayed.current = true;

            onOpenVideo();
          }

          onChange(await player.current.getCurrentTime());
        }
      }, 1000);
    }
  }, [idx, open, embedId, timecode]);

  const onOpenVideo = () => {
    setOpen(true);

    if (player.current?.playVideo) {
      player.current.playVideo();
    }
  };

  return (
    <div className={cn(styles['video-preview-presentation'], hidden && 'hide')}>
      <div id={getPlayerId(idx)} ref={iframeRef} />

      <div className={cn(styles['video-preview'], open && styles['hidden'])}>
        <Image src={preview?.url} alt="YT Player" height={preview?.height} width={preview?.width} />

        <div className={styles['video-preview-bg']} />

        <Button onClick={onOpenVideo} className={styles['video-preview-button']}>
          <Icon type="play" /> {type === 'text-icon' ? 'Play video' : ''}
        </Button>
      </div>
    </div>
  );
};
