import { useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  hasBackground?: boolean;
  className?: string;
}

export const OurCustomers = ({ hasBackground = true, className = '' }: Props) => {
  const { additionals } = useContext(ContentContext);

  if (!additionals.customersList?.data || additionals.customersList?.data.length === 0) {
    return null;
  }

  return (
    <div className={`customer-block-clients${hasBackground ? ' has-background' : ''} ${className}`}>
      {additionals.customersList.data
        .filter((i) => i.width && i.height)
        .map((item, idx) => (
          <Icon
            key={idx}
            url={`/api/svg-serving?url=${item.image.url}`}
            style={{ width: item.width, height: item.height }}
          />
        ))}
    </div>
  );
};
