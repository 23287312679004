import { useCallback, useContext } from 'react';

import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

export const Tabs = () => {
  const { query } = useRouter();
  const {
    navigationGroups: { policy = [] },
  } = useContext(ContentContext);

  const isActiveSlug = useCallback((tabSlug = '') => (query.category === tabSlug ? 'active' : ''), [query]);

  const Tab = useCallback(
    ({ route = '', params = {}, query = {}, children = '', className = '' }) => (
      <Link route={route} params={params} query={query} className={`${className} ${isActiveSlug(route)}`}>
        {children}
      </Link>
    ),
    [isActiveSlug],
  );

  return (
    <div className="faq-tabs-block">
      <div className="faq-tabs-header">
        {policy.map((item) => (
          <Tab key={item.slug} route={item.slug}>
            {item.title}
          </Tab>
        ))}
      </div>
    </div>
  );
};
