import { ChangeEvent, useMemo } from 'react';

import cn from 'classnames';

import styles from './Input.module.scss';

interface Props {
  disabled?: boolean;
  className?: string;
  type?: 'none' | 'primary' | 'error' | 'edited';
  text?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string | string[];
  hasError?: boolean;
  dataTestId?: string;
  htmlType?: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
  size?: 64 | 48 | 32;
  name?: string;
}

export const Input = ({
  value = '',
  type = 'none',
  className = '',
  onChange,
  disabled,
  text,
  error,
  hasError,
  dataTestId = '',
  htmlType = 'text',
  ariaLabel = 'Type here',
  ariaHidden = false,
  size = 64,
  name = '',
}: Props) => {
  const onChangeHandler = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(target.value);

  const renderElement = useMemo(() => {
    const elementProps = {
      'data-test': dataTestId,
      'aria-label': ariaLabel,
      'aria-hidden': ariaHidden,
      className: cn(
        styles[`input-type-${type}`],
        disabled && styles['disabled'],
        value.length > 0 && styles['check-input'],
        className,
      ),
      onChange: onChangeHandler,
      disabled,
      value,
      name,
    };

    if (htmlType === 'textarea') {
      return <textarea {...elementProps} rows={4} />;
    }

    return <input type={htmlType} {...elementProps} />;
  }, [dataTestId, ariaLabel, disabled, value, className, onChangeHandler, disabled, value]);

  return (
    <div
      className={cn(styles['input-wrapper'], styles[`input-size-${size}`], (error || hasError) && styles['has-error'])}
    >
      {renderElement}

      <span className={styles['input-label']}>{text}</span>

      {error && (
        <span className={styles['input-error-label']} data-test="input-error-label">
          {Array.isArray(error) ? error.join(', ') : error}
        </span>
      )}
    </div>
  );
};
