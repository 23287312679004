import { useContext, useMemo, useState } from 'react';

import { ContentContext } from 'context/ContentContext';

import { Image } from 'ui/atoms/Image';
import { WebTechItem } from 'ui/atoms/WebTechItem/WebTechItem';

export const WebTechnologies = () => {
  const { pageVars } = useContext(ContentContext);

  const [open, setOpen] = useState(1);

  const insightsPageVars = useMemo(() => pageVars.insights || {}, [pageVars.insights]);

  const data = useMemo(
    () => [
      insightsPageVars.description_1,
      insightsPageVars.description_2,
      insightsPageVars.description_3,
      insightsPageVars.description_4,
      insightsPageVars.description_5,
    ],
    [insightsPageVars],
  );

  return (
    <div className="web-technologies">
      <div className="web-technologies__title">
        <h2 dangerouslySetInnerHTML={{ __html: insightsPageVars.title }} />
      </div>

      <div className="web-technologies__content">
        <div className="web-technologies__text">
          {data.map((item, $key) => {
            const key = $key + 1;

            return <WebTechItem key={item} id={key} open={open === key} label={item} onClick={() => setOpen(key)} />;
          })}
        </div>

        <div className="web-technologies__img">
          <Image
            objectFit="contain"
            width={694}
            height={488}
            src={`base/digital_insights/0${open}.jpg`}
            alt="Technologies in use"
          />
        </div>
      </div>
    </div>
  );
};
