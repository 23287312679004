import { ReactNode, useMemo } from 'react';

import { capitalize } from 'libs/string';

import { Modal } from 'ui/organisms/Modal/Modal';
import { Icon } from 'ui/atoms/Icon/Icon';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

import styles from './ActionModal.module.scss';

type Props = {
  onClose: () => void;
  loading?: boolean;
  title?: string;
  description?: ReactNode;
  errors: { [key: string]: string | string[] };
};

export const ActionModal = ({ onClose, loading, title, description, errors }: Props) => {
  const detailedErrors = useMemo(() => {
    const detalizeError = (key: string) => (value: string) =>
      value.replace('This field', capitalize(key.replace(/_/g, ' ')));

    return Object.keys(errors).reduce<string[]>((acc, $current) => {
      const current = $current as string;

      if (Array.isArray(errors[current])) {
        acc.push(...(errors[current] as string[]).map(detalizeError(current)));
      } else {
        acc.push(detalizeError(current)(errors[current] as string));
      }

      return acc;
    }, []);
  }, [errors]);

  return (
    <Modal onClose={onClose} maxWidth={420}>
      <div className={styles['action-modal']}>
        {loading ? (
          <LoaderSpin loading={loading} />
        ) : (
          <>
            <Icon
              type={detailedErrors.length > 0 ? 'cross-circle' : 'check-circle'}
              className={styles['action-modal-image']}
            />

            {detailedErrors.length === 0 ? (
              <>
                {title && <p>{title}</p>}

                {description && <div>{description}</div>}
              </>
            ) : (
              <>
                {title && <p>We apologize!</p>}

                {detailedErrors.map((error) => (
                  <div key={error}>{error}</div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
