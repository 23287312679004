export const number = <T>(value: T): string | T => {
  const numberValue = Number(value);
  return !isNaN(numberValue) ? numberValue.toLocaleString() : value;
};

export const percentage = (used: number, total: number) => {
  if (typeof used !== 'number' || typeof total !== 'number') {
    return 0;
  }

  return (used / total) * 100;
};

export const secondsToHms = (value: string | number) => {
  const valueAsNumber = parseInt(value.toString(), 10);
  const hours = Math.floor(valueAsNumber / 3600);
  const minutes = Math.floor(valueAsNumber / 60) % 60;
  const seconds = valueAsNumber % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const hmsToSeconds = (value: string) => {
  if (typeof value?.split !== 'function') {
    return null;
  }

  const p = value.split(':');

  let s = 0;
  let m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop() || '0', 10);
    m *= 60;
  }

  return s;
};

export const getDuration = (hms: string) => {
  const splitHms = hms.split(':');

  return `${splitHms[0].replace(/^0/, '')} ${
    splitHms.length === 3 ? (splitHms[0] === '1' ? 'hours' : 'hour') : 'mins'
  }`;
};
