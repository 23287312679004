import { useCallback, useContext, useMemo } from 'react';

import { useRouter } from 'next/router';

import { careerTypes } from 'types';

import { ContentContext } from 'context/ContentContext';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

import { CareerCategory } from 'features/about/organisms/CareerCategory/CareerCategory';
import { Topics } from 'features/articles/atoms/Topics/Topics';
import { Benefits } from 'features/about/organisms/Benefits/Benefits';

import styles from './CareersPage.module.scss';

const CareersPage = () => {
  const { additionals, pageVars } = useContext(ContentContext);
  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const {
    query: { type },
  } = useRouter();

  const getFilteredCareers = useCallback(
    (category: string) =>
      additionals?.careers?.data
        .filter((career) => career.category === category)
        .map((career) => ({
          id: career.id,
          name: career.name,
          category: career.category,
          location: career.location,
          slug: career.slug,
        })) || [],
    [additionals],
  );
  const careerCategories = useMemo(
    () => careerTypes.filter((type) => commonPageVars[`careers_${type}`] && getFilteredCareers(type).length),
    [commonPageVars],
  );

  const benefits = useMemo(
    () =>
      additionals?.careerBenefits?.data.map((benefit) => ({
        text: benefit.text,
        icon: benefit.icon,
      })) || [],
    [additionals],
  );

  return (
    <div>
      <div className={`${styles['careers-page']}`}>
        <InfoBlock
          className={`${styles['careers-infoBlock']}`}
          title={pageVars.careers.title}
          description={pageVars.careers.description}
          type="careers"
        />

        <Topics type="careers" margin="small" careerCategories={careerCategories} />

        <div className={`${styles['career-categories-wrapper']}`} id="categories-wrapper">
          {careerCategories
            .filter((category) => !type || category === type)
            .map((category) => (
              <CareerCategory
                key={category}
                title={commonPageVars[`careers_${category}`]}
                data={getFilteredCareers(category)}
              />
            ))}
        </div>
      </div>

      <Benefits
        heading={pageVars.careers.benefits_heading}
        description={pageVars.careers.benefits_description}
        benefits={benefits}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CareersPage;
