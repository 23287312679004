import { parseObject } from 'libs/object/object';
import { defaultLocale } from 'libs/locale';

const baseKey = '285fae9184424f63aba1be6cd16c573d';

interface HTTPResponse<T> {
  $ok: boolean;
  data: T;
  status: number;
  count?: number;
  next?: string;
  previous?: string;
}

export interface Params {
  lang: string | string[];
  [key: string]: any;
}

export const fetcher = {
  // Use extends on the generic parameter to hint the compiler that it's a generic
  get: <T extends unknown>(url: string, { lang, ...params }: Params): Promise<HTTPResponse<T>> => {
    const isBaseUrlExternal = url.match(/^http/);
    const endpoint = `${!isBaseUrlExternal ? process.env.API_URL : ''}${url}${parseObject(params)}`;

    return fetch(endpoint, {
      headers: {
        'Accept-Language': typeof lang === 'string' ? lang : defaultLocale,
        'Content-type': 'application/json; charset=UTF-8',
        ...(!isBaseUrlExternal ? { 'SAAS-APP-TOKEN': baseKey } : {}),
        ...(process.env.NODE_ENV === 'development' && !isBaseUrlExternal ? { 'Cache-Control': 'no-cache' } : {}),
        ...(params ? params.headers : {}),
      },
      method: 'GET',
    })
      .then(async (res) => {
        if (res.body) {
          const textBody = await res.text();
          const body = Boolean(textBody) ? JSON.parse(textBody) : {};

          if (Array.isArray(body)) {
            return {
              data: body,
              $ok: res.ok,
              status: res.status,
              endpoint,
            };
          }

          const { results: data, ...rest } = body;

          return {
            ...(data !== undefined ? rest : {}),
            data: data !== undefined ? data : rest,
            $ok: res.ok,
            status: res.status,
            endpoint,
          };
        }

        return { $ok: false, data: {} };
      })
      .then((res) => {
        if (res.$ok) {
          return Promise.resolve(res);
        }

        return Promise.reject({ response: res });
      });
  },

  post: <T extends unknown>(
    url: string,
    body: any,
    { formData, lang, ...params }: Params,
  ): Promise<HTTPResponse<T>> => {
    const isBaseUrlExternal = url.match(/^http/);
    const endpoint = `${!isBaseUrlExternal ? process.env.API_URL : ''}${url}`;

    return fetch(endpoint, {
      ...params,
      headers: {
        'Accept-Language': typeof lang === 'string' ? lang : defaultLocale,
        ...(!isBaseUrlExternal ? { 'SAAS-APP-TOKEN': baseKey } : {}),
        ...(process.env.NODE_ENV === 'development' && !isBaseUrlExternal ? { 'Cache-Control': 'no-cache' } : {}),
        ...(params ? params.headers : {}),
        ...(formData ? {} : { 'Content-type': 'application/json; charset=UTF-8' }),
      },
      body: formData ? body : JSON.stringify(body),
      method: 'POST',
    })
      .then(async (res) => {
        if (res.body) {
          const textBody = await res.text();
          const body = Boolean(textBody) ? JSON.parse(textBody) : {};

          if (Array.isArray(body)) {
            return {
              data: body,
              $ok: res.ok,
              endpoint,
            };
          }

          const { results: data, ...rest } = body;

          return {
            ...(data !== undefined ? rest : {}),
            data: data !== undefined ? data : rest,
            $ok: res.ok,
            endpoint,
          };
        }

        return { $ok: false, data: {}, endpoint };
      })
      .then((res) => {
        if (res.$ok) {
          return Promise.resolve(res);
        }

        return Promise.reject({ response: res });
      });
  },
};
