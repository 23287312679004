import { useCallback, useContext, useMemo } from 'react';

import Head from 'next/head';

import { blog as blogTypes, caseStudiesCategories } from 'types';

import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

interface Props {
  /**
   * Size of margins between topics
   * @default tall
   * */
  margin?: 'small' | 'tall';
  type?: 'blog' | 'case_studies' | 'careers';
  defaultType?: string;
  careerCategories?: string[];
}

export const Topics = ({ type = 'blog', margin = 'tall', defaultType = '', careerCategories = [] }: Props) => {
  const { query } = useRouter();
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const blog = useMemo(() => blogTypes.filter((type) => commonPageVars[`blog_${type}`]), [commonPageVars]);

  const [queryType, queryTypeVar] = useMemo(() => {
    const $queryType = defaultType || `${query.type}` || '';
    return [$queryType, commonPageVars?.[`${type}_${$queryType}`]];
  }, [commonPageVars, type, defaultType, query]);

  const isTypeActive = useCallback((tabType: string) => (queryType === tabType ? 'active' : ''), [queryType]);

  if (!secondaryNavigation?.blog) {
    return null;
  }

  return (
    <>
      {queryTypeVar && (
        <Head>
          <title>{queryTypeVar}</title>
        </Head>
      )}

      <div className={`topics ${margin + '-margin'}`}>
        {type === 'blog' && (
          <>
            {secondaryNavigation.blog?.slug && (
              <>
                {commonPageVars.blog_all && (
                  <Link
                    tabIndex={0}
                    route={secondaryNavigation.blog.slug}
                    query={{ paage: 1 }}
                    className={isTypeActive('')}
                  >
                    {commonPageVars.blog_all}
                  </Link>
                )}

                {blog.map((type) => (
                  <Link
                    tabIndex={0}
                    key={type}
                    route={secondaryNavigation.blog.slug}
                    query={{ type, paage: 1 }}
                    className={isTypeActive(type)}
                  >
                    {commonPageVars[`blog_${type}`]}
                  </Link>
                ))}
              </>
            )}
          </>
        )}

        {type === 'case_studies' && (
          <>
            <Link
              tabIndex={0}
              route={secondaryNavigation.customers.slug}
              query={{ paage: 1 }}
              className={isTypeActive('')}
            >
              {commonPageVars.case_studies_all}
            </Link>

            {caseStudiesCategories.map((category) => (
              <Link
                key={category}
                tabIndex={0}
                route={secondaryNavigation.customers.slug}
                query={{ type: category, paage: 1 }}
                className={isTypeActive(category)}
              >
                {commonPageVars[`case_studies_${category}`]}
              </Link>
            ))}
          </>
        )}

        {type === 'careers' && (
          <>
            <Link route={secondaryNavigation.careers.slug} className={isTypeActive('')}>
              {commonPageVars.careers_all}
            </Link>

            {careerCategories.map((category) => (
              <Link
                key={category}
                route={secondaryNavigation.careers.slug}
                query={{ type: category }}
                className={isTypeActive(category)}
              >
                {commonPageVars[`careers_${category}`]}
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
};
