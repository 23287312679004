import { useRef } from 'react';

import cn from 'classnames';

import { useIntersection } from 'hooks/useIntersection';

import { FreeTrialIncludes } from 'ui/atoms/FreeTrialIncludes/FreeTrialIncludes';
import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';
import { CardsCustomer } from 'ui/molecules/CardsCustomer/CardsCustomer';

import { FormRequest } from 'features/product-pricing/organisms/FormRequest/FormRequest';

import styles from './PricingTrial.module.scss';

const PricingTrialPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const data = {
    title: 'Your test drive includes',
    data: {
      lightAccess: {
        description: 'Global Database Prospecting light access:',
        labels: [
          { label: 'Unlimited view credits for  each public company' },
          { label: 'Essential sales prospecting outlook' },
          { label: 'Open access to our business intelligence suite' },
          { label: 'Essential financial records previews' },
          { label: '50 download and export credits' },
        ],
      },
      ecosystemAccess: {
        description: 'Essential Outreach Ecosystem Access:',
        labels: [
          { label: '4 active automation sequences' },
          { label: '2-step e-mail chains' },
          { label: '50 outreach credits ' },
          { label: 'Limitless contact management via list upload' },
          { label: 'Unlimited contact mining via LinkedIn' },
        ],
      },
    },
  };

  const customDataForCards = [
    {
      title: 'Spotting the right prospect',
      description: 'Explore our prospecting success stories and their authors — accounts written by live subscribers',
      buttonText: 'View case studies',
      iconType: 'rectangles-one',
      slug: 'sales-&-marketing-platform',
    },
    {
      title: 'Supplier Monitoring and verification',
      description: 'See how our active members keep track on their partner relations, ensuring sustainable growth',
      buttonText: 'View case studies',
      iconType: 'rectangles-two',
      slug: 'sales-&-marketing-platform',
    },
    {
      title: 'Enriching & automating your onboarding process',
      description: 'Get a glace at how Global Database tuned-up out customers onboarding processes via automation',
      buttonText: 'View case studies',
      iconType: 'rectangles-three',
      slug: 'sales-&-marketing-platform',
    },
  ];

  return (
    <div className={styles['trial-wrapper']}>
      <div ref={ref} className={cn(styles['form-request-block'], intersected && styles['intersected'])}>
        <div>
          <h1>Your risk-free test drive awaits</h1>
          <p>get 50 prospecting credits, on-demand financial and automate your outreach</p>
        </div>

        <FormRequest title="Request free trial" type="book" />
      </div>

      <FreeTrialIncludes data={data.data} title={data.title} />

      <OurCustomers hasBackground={false} />

      <CardsCustomer title="or learn more about our success stories, focused on:" data={customDataForCards} />
    </div>
  );
};

/**
 * please, ignore export default error
 * Only pages require to have export default as they are imported dynamically
 */

// eslint-disable-next-line import/no-default-export
export default PricingTrialPage;
