import { useContext, useState, useMemo, FormEvent } from 'react';

import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { Input } from 'ui/atoms/Input/Input';
import { Button } from 'ui/atoms/Button/Button';

export const TouchCreditSearch = () => {
  const { query, push } = useRouter();
  const { secondaryNavigation, data: content, pageVars } = useContext(ContentContext);
  const [company, setCompany] = useState<string>('');

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (company && secondaryNavigation.credits.slug) {
      push(
        secondaryNavigation.credits.slug,
        { lang: `${query.lang}` },
        { company: company, country: content.menu_title },
      );
    }
  };

  const creditReportPageVars = useMemo(() => pageVars.common || {}, [pageVars.credit_report]);

  return (
    <div className="touch-credit-search-wrapper">
      <form onSubmit={onSubmit}>
        <Input
          ariaLabel={creditReportPageVars.credit_search_input_placeholder}
          text={creditReportPageVars.credit_search_input_placeholder}
          value={company}
          onChange={setCompany}
        />

        <Button size={64} htmlType="submit">
          {creditReportPageVars.credit_search_button_title}
        </Button>
      </form>
    </div>
  );
};
