/* eslint react-hooks/exhaustive-deps: "warn" */

import { FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import Head from 'next/head';
import dynamic from 'next/dynamic';

import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { useRouter } from 'libs/router/useRouter';
import type { Country, DataItem, HeadersItem, UploadResponse } from 'libs/http/api/dashboard/dashboard.types';
import type { $Object } from 'libs/object/object.types';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';
import { useStateHandlers } from 'hooks/useStateHandlers';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';
import { DataBlock } from 'ui/molecules/DataBlock/DataBlock';
import { RequestDemo } from 'ui/molecules/RequestDemo/RequestDemo';
import { CardsCustomer } from 'ui/molecules/CardsCustomer/CardsCustomer';
import { CreditReportForm } from 'ui/molecules/CreditReportForm/CreditReportForm';
import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';
import { FeedBackLoader } from 'ui/organisms/FeedBack/FeedBackLoader';
import { PresentationBlockLoader } from 'ui/organisms/PresentationBlock/PresentationBlockLoader';
import { DynamicImport } from 'ui/DynamicImport';

import { Benefits } from 'features/articles/organisms/Benefits/Benefits';
import { UploadDropzone } from 'features/enrichment/organisms/UploadDropzone/UploadDropzone';

const HomePage = () => {
  const modalRef = useRef(null);
  const { query, push } = useRouter();
  const { secondaryNavigation, clientNavigationGroups, data: content, pageVars } = useContext(ContentContext);

  const {
    request: requestCountries,
    data: dataCountries,
    loading: loadingCountries,
  } = useRequest<Country[]>({ loading: true, data: [] });

  const { request: requestUpload, loading: loadingUpload, errors: errorsUpload } = useRequest<UploadResponse>();

  const [showModal, setShowModal] = useState(false);

  const [uploadData, setUploadData] = useStateHandlers({
    file: {} as File,
    data: [] as DataItem[],
    headers: [] as HeadersItem[],
    session_key: '',
    status: '',
  });

  const [formData, setFormData] = useStateHandlers({
    company: '',
    country: '',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      requestCountries(dashboard.country({ lang: `${query.lang}` }));
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [query.lang, requestCountries]);

  const onChangeState = useCallback(
    <T extends keyof typeof formData>(target: string) =>
      (value: (typeof formData)[T]) =>
        setFormData({ [target]: value }),
    [setFormData],
  );

  const countryOptions = useMemo(() => {
    if (loadingCountries || !Array.isArray(dataCountries)) {
      return [];
    }

    return dataCountries.map((item) => ({
      value: item.short_name,
      title: item.name,
      countryId: item.id,
      research: item.research,
      freshInvestigation: item.fresh_investigation,
    }));
  }, [loadingCountries, dataCountries]);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const homePageVars = useMemo(() => pageVars.home || {}, [pageVars.home]);

  const enrichmentRoute = useMemo(
    () =>
      clientNavigationGroups.solutions?.reduce(
        (acc, current) => (current.navigationId === 'enrichment' ? current.slug : acc),
        '',
      ),
    [clientNavigationGroups],
  );

  const onToggleModal = useCallback(() => setShowModal((s) => !s), []);

  const onUploadFile = useCallback(
    async (files: File[] = []) => {
      if (files.length >= 1) {
        const formData = new FormData();
        formData.append('file', files[0] as File);

        try {
          const data = await requestUpload(dashboard.upload(formData));

          setUploadData({
            file: files[0] || {},
            data: data.data,
            headers: data.headers,
            session_key: data.session_key,
            status: data.upload.status,
          });

          onToggleModal();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    },
    [onToggleModal, requestUpload, setUploadData],
  );

  const onSubmit = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      const pushQuery = Object.entries(formData)
        .filter(([_, value]) => value.trim() !== '')
        .reduce((acc, [key, value]) => {
          acc[encodeURIComponent(key)] = encodeURIComponent(value);

          return acc;
        }, {} as $Object);

      if (secondaryNavigation.credits.slug && formData.company.length > 0) {
        push(secondaryNavigation.credits.slug, { lang: `${query.lang}` }, pushQuery);
      }
    },
    [formData, push, query.lang, secondaryNavigation.credits.slug],
  );

  return (
    <>
      {showModal &&
        (() => {
          const Component = dynamic(
            () =>
              import('features/enrichment/organisms/CompaniesUploadModal').then(
                (module) => module.CompaniesUploadModal,
              ),
            { ssr: false },
          );

          return <Component ref={modalRef} onClose={onToggleModal} uploadData={uploadData} />;
        })()}

      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: 'https://www.globaldatabase.com/',
              name: 'GlobalDatabase',
              location: "Artisans' House, 7 Queensbridge, Northampton, Northamptonshire, NN4 7BF, United Kingdom",
              alternateName: 'Global Database',
              legalName: 'Global Data Intelligence Limited',
              description: content.meta_description,
              logo: 'https://dublk70ek6ejz.cloudfront.net/dist-img/logo-blue.png',
              sameAs: [
                commonPageVars.linkedin,
                'https://www.quora.com/topic/Global-Database-1/',
                commonPageVars.facebook,
                commonPageVars.twitter,
                'https://www.crunchbase.com/organization/global-database-2',
                'https://www.youtube.com/c/GlobalDatabase',
                'https://www.instagram.com/global_database/',
              ].filter((i) => i),
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: commonPageVars.phone || '',
                  contactType: commonPageVars.customer_service || '',
                },
              ],
            }),
          }}
        />
      </Head>

      <div className="main-container">
        <InfoBlock
          title={content.title}
          description={content.description}
          buttonText={content.button_text}
          buttonHref={content.button_href}
        />

        <div className="customer-block-container">
          <CardsCustomer />

          <OurCustomers className="home-customer-block-clients" />
        </div>

        {homePageVars.middle_title || homePageVars.middle_description ? (
          <div className="content-main">
            <div className="content-ahead">
              {homePageVars.middle_title && <h2>{homePageVars.middle_title}</h2>}

              {homePageVars.middle_description && <span>{homePageVars.middle_description}</span>}
            </div>
          </div>
        ) : null}

        <DataBlock
          title={homePageVars.middle_item_title_1}
          description={homePageVars.middle_item_description_1}
          video={homePageVars.middle_item_image_1}
          button={homePageVars.middle_item_button_1}
          route={secondaryNavigation.marketing?.slug}
        />

        <DataBlock
          type="get-data-reverse"
          title={homePageVars.middle_item_title_2}
          description={homePageVars.middle_item_description_2}
          video={homePageVars.middle_item_image_2}
          route={enrichmentRoute}
          additional={
            <>
              <UploadDropzone onDrop={onUploadFile} loading={loadingUpload} size="small" />

              {errorsUpload.detail && <ErrorMessage error={errorsUpload.detail} />}
            </>
          }
          productName={secondaryNavigation.enrichment.title}
        />

        <DataBlock
          title={homePageVars.middle_item_title_3}
          description={homePageVars.middle_item_description_3}
          video={homePageVars.middle_item_image_3}
          route={secondaryNavigation.credits?.slug}
          productName={secondaryNavigation.credits.title}
          additional={
            <CreditReportForm
              size={48}
              countryLoading={loadingCountries}
              countryOptions={countryOptions}
              formData={formData}
              onSubmit={onSubmit}
              onChangeState={onChangeState}
            />
          }
        />

        <DataBlock
          type="get-data-reverse"
          title={homePageVars.middle_item_title_4}
          description={homePageVars.middle_item_description_4}
          video={homePageVars.middle_item_image_4}
          button={homePageVars.middle_item_button_4}
          route={secondaryNavigation.api?.slug}
        />

        <RequestDemo title={content.book_demo_title} />

        <div className="content-2">
          <DynamicImport fallback={() => <PresentationBlockLoader />}>
            {() => {
              const Component = dynamic(() => import('ui/organisms/PresentationBlock/PresentationBlock'), {
                ssr: false,
                loading: () => <PresentationBlockLoader />,
              });

              return <Component />;
            }}
          </DynamicImport>

          <DynamicImport fallback={() => <FeedBackLoader />}>
            {() => {
              const Component = dynamic(() => import('ui/organisms/FeedBack/FeedBack'), {
                ssr: false,
                loading: () => <FeedBackLoader />,
              });

              return <Component />;
            }}
          </DynamicImport>
        </div>

        <div className="content-3">
          <Benefits withBlog withViewMore limit={7} />
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default HomePage;
