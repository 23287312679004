import { useEffect } from 'react';

import { clearTimeoutIfExists } from 'libs/node';

import packageJson from '../../../package.json';

export const OverleadWidget = () => {
  useEffect(() => {
    (window as any)['zlk'] = 'gdb-analytics-567247';

    const delay = setTimeout(() => {
      const a = document.createElement('script');
      const m = document.getElementsByTagName('script')[0];
      a.async = true;
      a.src = `https://overlead.net/static/widget/auto.js?version=${packageJson.version}`;

      if (m.parentNode !== null) {
        m.parentNode.insertBefore(a, m);
      }
    }, 10000);

    return () => {
      clearTimeoutIfExists(delay);
    };
  }, []);

  return null;
};
