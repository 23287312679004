import cn from 'classnames';

import styles from './Status.module.scss';

export const Status = ({ status }: { status: string | string[] | null }) => {
  return (
    <div className={cn(styles['status-wrapper'], styles[status === 'Active' ? 'active' : 'inactive'])}>
      <div className={styles['status-circle']}>
        <span className={styles['status-circle-inner']} />
      </div>

      <span className={styles['status-label']}>{status}</span>
    </div>
  );
};
