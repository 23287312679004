import { CSSProperties } from 'react';

export type MaskType = 'black' | 'transparent' | 'semitransparent';

interface Props {
  type?: MaskType;
  className?: string;
  onClick?: () => void;
  zIndex?: number;
  style?: CSSProperties;
}

export const Mask = ({ type = 'black', className = '', onClick, zIndex, style = {} }: Props) => (
  <div className={`gdb-mask gdb-mask-${type} ${className}`} onClick={onClick} style={{ zIndex, ...style }} />
);
