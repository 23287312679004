import { ReactNode } from 'react';

import { Link } from 'libs/router/Link';

import { Image } from 'ui/atoms/Image';
import { Button } from 'ui/atoms/Button/Button';
import { Video } from 'ui/atoms/Video';

const placeholderRest = {
  title: 'Placeholder title',
  subTitle: 'Placeholder sub title',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo dolor quae necessitatibus. Voluptas, nisi et? Molestiae autem numquam consectetur, deserunt alias commodi eius, fugit corrupti rerum esse quam aut quisquam!',
};

interface DataBlock {
  loading?: boolean;
  title?: string;
  subTitle?: string;
  description?: string;
  button?: string;
  route?: string;
  img?: string;
  video?: string;
  type?: string;
  additional?: ReactNode;
  productName?: string;
}

export const DataBlock = ({ loading, button, route, img, video, type = '', productName, ...rest }: DataBlock) => {
  const data = loading ? placeholderRest : rest;

  if (!loading && !rest.title && !rest.description) {
    return null;
  }

  return (
    <div className={`get-data ${loading ? 'loading' : ''} ${type}`}>
      <div className="get-data__info">
        {productName && (
          <Link route={route} className="get-data__product">
            {(loading) => (
              <span className={loading ? 'color-font-2' : ''} title={loading ? 'Loading ..' : ''}>
                {productName}
              </span>
            )}
          </Link>
        )}

        {data.title && (
          <div className="get-data__title">
            <h2>{data.title}</h2>
          </div>
        )}

        {data.subTitle && <div className="get-data__sub_title">{data.subTitle}</div>}

        {data.description && <div className="get-data__text">{data.description}</div>}

        {button && route && (
          <Button size={48} type="invert" route={route} className="get-data__button">
            {button}
          </Button>
        )}

        {rest.additional && <div>{rest.additional}</div>}
      </div>

      {loading ? (
        <div className="get-data__image-placeholder" />
      ) : (
        <>
          {img && !video && (
            <div className="get-data__image">
              <Image quality={100} width={488} height={488} src={img} alt={data.title} />
            </div>
          )}

          {!img && video && (
            <div className="get-data__video">
              <Video src={video} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
