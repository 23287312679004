import { TouchTab } from 'ui/atoms/TouchTab/TouchTab';
import { Touch } from 'ui/atoms/TouchTab/TouchTab.types';
import { Image } from 'ui/atoms/Image';

interface Props {
  title: string;
  description: string;
  image?: {
    url: string;
    name: string;
  };
  alternative?: boolean;
  touch?: Touch;
  className?: string;
}

export const Laptop = ({ title, description, image, alternative, touch, className }: Props) => {
  return (
    <div className={`content__wrapper ${alternative ? 'alternative' : ''} ${className ? className : ''}`}>
      <div>
        <h2>{title}</h2>

        <div dangerouslySetInnerHTML={{ __html: description }} />

        {touch && <TouchTab type={touch} buttonClassName="touchData__button" />}
      </div>

      <div>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image
          width={592}
          height={381}
          {...(image
            ? {
                src: image.url,
                alt: image.name,
                className: 'laptop-svg',
              }
            : {
                alt: 'Laptop',
                src: 'img/laptop.jpg',
              })}
        />
      </div>
    </div>
  );
};
