import { Button } from 'ui/atoms/Button/Button';

import styles from './ScrollToForm.module.scss';

type Props = {
  hide: boolean;
  visibleForm: boolean;
  onClick: () => void;
  text: string;
};

export const ScrollToForm = ({ hide, visibleForm, onClick, text }: Props) => {
  if (hide) return null;
  return (
    <div className={styles[`scroll-wrapper${!visibleForm ? '-animate-up' : '-animate-down'}`]} onClick={onClick}>
      <Button onClick={onClick} className={styles['button']}>
        {text}
      </Button>
    </div>
  );
};
