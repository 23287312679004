import { useMemo, useContext } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { Icon } from 'ui/atoms/Icon/Icon';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { OurValuesItem } from 'ui/atoms/OurValuesItem/OurValuesItem';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { FeedBackLoader } from 'ui/organisms/FeedBack/FeedBackLoader';
import { VideoBlock } from 'ui/molecules/VideoBlock/VideoBlock';
import { DynamicImport } from 'ui/DynamicImport';

import styles from './AboutUsPage.module.scss';

import { OurMission } from '../../organisms/OurMission/OurMission';
import { CustomersFeedback } from '../../organisms/CustomersFeedback/CustomersFeedback';

const AboutUsPage = () => {
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  const aboutUsPageVars = useMemo(() => pageVars.about || {}, [pageVars.about]);
  const customers = useMemo(() => additionals.customersFeedback?.data, [additionals]);
  const ourValues = [
    {
      title: aboutUsPageVars.our_values_title,
      description: aboutUsPageVars.our_values_description,
      styleDiv: aboutUsPageVars.our_values_div_style,
    },
    {
      title: aboutUsPageVars.our_values_title_2,
      description: aboutUsPageVars.our_values_description_2,
      styleDiv: aboutUsPageVars.our_values_div_style_2,
    },
    {
      title: aboutUsPageVars.our_values_title_3,
      description: aboutUsPageVars.our_values_description_3,
      styleDiv: aboutUsPageVars.our_values_div_style_3,
    },
    {
      title: aboutUsPageVars.our_values_title_4,
      description: aboutUsPageVars.our_values_description_4,
      styleDiv: aboutUsPageVars.our_values_div_style_4,
    },
    {
      title: aboutUsPageVars.our_values_title_5,
      description: aboutUsPageVars.our_values_description_5,
      styleDiv: aboutUsPageVars.our_values_div_style_5,
    },
  ];

  return (
    <div className={`${styles['about-us-page']}`}>
      <InfoBlock className={`${styles['about-infoBlock']}`} title={content.title} description={content.description} />

      <div className={`${styles['primary-goal']}`}>
        <p>{aboutUsPageVars.primary_goal_text}</p>

        <div className={`${styles['about-us-upper__bg']}`}>
          <div className="icon">
            <Icon type="about-us-upper" />
          </div>
        </div>
      </div>

      <OurMission />

      <div className={`${styles['our-vision']}`}>
        <div className={`${styles['our-vision__description']}`}>
          <h2>{aboutUsPageVars.our_vision_title}</h2>

          <p>{aboutUsPageVars.our_vision_description}</p>
        </div>
      </div>

      <div className={`${styles['our-values']}`}>
        <div className={`${styles['our-values__title']}`}>
          <h2>{aboutUsPageVars.our_values_main_title}</h2>

          <p>{aboutUsPageVars.our_values_main_description}</p>
        </div>

        <div className={`${styles['our-values__wrapper']}`}>
          <div className={`${styles['our-values__bg-circles']}`}>
            <Icon type="circles-background" />
          </div>

          <div className={`${styles['our-values__left']}`}>
            {ourValues.map(
              (item, key) =>
                key < 3 && (
                  <OurValuesItem key={key} styleDiv={item.styleDiv} title={item.title} description={item.description} />
                ),
            )}
          </div>

          <div className={`${styles['our-values__right']}`}>
            {ourValues &&
              ourValues.map(
                (item, key) =>
                  key > 2 && (
                    <OurValuesItem
                      key={key}
                      styleDiv={item.styleDiv}
                      title={item.title}
                      description={item.description}
                    />
                  ),
              )}
          </div>
        </div>
      </div>

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      <VideoBlock hasWhiteBg />

      <div className={`${styles['about-us-feedback']}`}>
        <DynamicImport fallback={() => <FeedBackLoader />}>
          {() => {
            const Component = dynamic(() => import('ui/organisms/FeedBack/FeedBack'), {
              ssr: false,
              loading: () => <FeedBackLoader />,
            });

            return <Component />;
          }}
        </DynamicImport>

        {customers && <CustomersFeedback customers={customers} />}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AboutUsPage;
