import { ChangeEvent, FormEvent, useCallback, useContext, useMemo } from 'react';

import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useStateHandlers } from 'hooks/useStateHandlers';
import { useRequest } from 'hooks/useRequest';

import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';
import { Input } from 'ui/atoms/Input/Input';
import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';

import styles from './ApplyForm.module.scss';

export const ApplyForm = () => {
  const { query, push } = useRouter();
  const { request, errors, loading } = useRequest();
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const [state, setState] = useStateHandlers({
    name: '',
    email: '',
    phone: '',
    file: {
      lastModified: 0,
      name: '',
      size: 0,
      type: '',
    },
  });

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    //eslint-disable-next-line prefer-const
    let formData = new FormData();
    formData.append('file', state.file as File);
    formData.append('name', state.name);
    formData.append('email', state.email);
    formData.append('phone', state.phone);

    try {
      await request(dashboard.apply(formData));
      if (secondaryNavigation.thank_you.slug) {
        push(secondaryNavigation.thank_you.slug, { lang: `${query.lang}` });
      }
    } catch (error) {
      if (secondaryNavigation.thank_you.slug && Object.keys(error as Object).length === 0) {
        push(secondaryNavigation.thank_you.slug, { lang: `${query.lang}` });
      }
    }
  };

  const onChange = useCallback((target: string, value: string) => setState({ [target]: value }), []);

  const fileChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    setState({ ...state, file: ev!.target!.files![0] });
  };

  return (
    <form id="apply-form" onSubmit={onSubmit} className={styles[`apply-form-wrapper`]}>
      <h1>{commonPageVars.apply_form_name}</h1>

      <div>
        <Input
          dataTestId="name"
          ariaLabel={commonPageVars.full_name}
          text={commonPageVars.full_name}
          value={state.name}
          name="full_name"
          onChange={(value) => onChange('name', value)}
          error={errors.name}
        />
      </div>

      <div>
        <Input
          dataTestId="phone"
          ariaLabel={commonPageVars.phone_label}
          text={commonPageVars.phone_label}
          value={state.phone}
          name="phone"
          onChange={(value) => onChange('phone', value)}
          error={errors.phone}
        />
      </div>

      <div>
        <Input
          dataTestId="email"
          ariaLabel={commonPageVars.work_email}
          text={commonPageVars.work_email}
          value={state.email}
          name="email"
          onChange={(value) => onChange('email', value)}
          error={errors.email}
        />
      </div>

      <div className={styles['cv-input']}>
        <label htmlFor="file-upload" className={styles['file-upload-wrapper']}>
          <Icon type="file" className={styles['file-upload-icon']} />
          <div>
            {commonPageVars.attach_cv}
            {state.file.name ? ':' : ''}
          </div>
        </label>

        <div className={styles['file-name']}>{state.file.name}</div>

        <input
          aria-label={commonPageVars.attach_cv}
          id="file-upload"
          type="file"
          onChange={fileChangeHandler}
          hidden
          accept=".pdf,.docx,.doc"
        />

        {errors.file ? (
          <div className={styles['input-error-label']}>
            {Array.isArray(errors.file) ? errors.file.join(', ') : errors.file}
          </div>
        ) : null}
      </div>

      {errors.detail && <ErrorMessage error={errors.detail} position="right" />}

      <div>
        <Button size={64} loading={loading} htmlType="submit">
          {commonPageVars.apply_form_button}
        </Button>
      </div>

      {secondaryNavigation.policy?.slug &&
        secondaryNavigation.terms?.slug &&
        [1, 2, 3, 4].map((i) => commonPageVars[`book_demo_submitting_${i}`]).filter((i) => i).length > 0 && (
          <span className={styles['conditions-wrapper']}>
            {commonPageVars.book_demo_submitting_1}{' '}
            <Link tabIndex={0} route={secondaryNavigation.policy.slug}>
              {commonPageVars.book_demo_submitting_2}
            </Link>{' '}
            {commonPageVars.book_demo_submitting_3}{' '}
            <Link tabIndex={0} route={secondaryNavigation.terms.slug}>
              {commonPageVars.book_demo_submitting_4}
            </Link>
          </span>
        )}
    </form>
  );
};
