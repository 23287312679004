import React from 'react';

import cn from 'classnames';

import styles from './SelectLoader.module.scss';

const baseClass = 'select-loader';

interface Props {
  size?: 'md' | 'lg';
  title?: string;
}

export const SelectLoader = ({ size = 'lg', title = 'Loading' }: Props) => {
  return <div className={cn(styles[`${baseClass}__wrapper`], styles[`${baseClass}__${size}`])}>{title}</div>;
};
