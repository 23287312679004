import { fetcher, Params } from '../../fetcher';
import { LangSlug, Navigation, Page, SearchedBlog, Sitemap } from './common.types';

export const common = {
  navigation: (params = {} as Params) =>
    fetcher.get<Navigation[]>('/content-type/delivery/pages/list/', {
      ...params,
      depth: 1,
      page_size: 100,
      'filter[page_type__ne]': 'home',
      'filter[has_article]': null,
      'filter[has_industry_information]': null,
      'filter[has_country_information]': null,
      'filter[is_solution__ne]': true,
      fields: [
        'navigation_id',
        'menu_title',
        'title',
        'menu_description',
        'description',
        'menu_icon',
        'is_home_page',
        'page_type',
        'show_in_header',
        'show_in_footer',
        'is_product',
        'is_credit_report',
        'has_about_us_information',
        'has_integration_information',
        'has_frequency_questions',
        'header_menu_position',
      ].join(','),
    }),

  clientNavigation: ({ fields, ...params } = {} as Params) =>
    fetcher.get<Navigation[]>('/content-type/delivery/pages/list/', {
      ...params,
      depth: 1,
      page_size: 200,
      sort: '+menu_title',
      'filter[page_type__in]': params['filter[page_type__in]'],
      fields: [...fields, 'navigation_id', 'menu_title', 'title', 'page_type'].join(','),
    }),

  page: ({ slug, ...params }: Params) =>
    fetcher.get<Page>(`/content-type/delivery/pages/by-slug/${slug}/`, { depth: 3, ...params }),

  sitemap: (params: Params) =>
    fetcher.get<Sitemap[]>(`/content-type/delivery/pages/list/`, {
      ...params,
      depth: -1,
      page_size: 1000,
      fields: ['navigation_id', 'slug', 'is_home_page', 'created_at'],
      'filter[slug__ne]': 'none',

      // remove all promo pages
      'filter[page_type__ne]': 'mexico',
    }),

  checkPage: ({ slug }: Params) =>
    fetcher.get<{ id: string }[]>(`/content-type/delivery/pages/by-slug/${slug}/`, {
      lang: 'en',
      depth: 0,
      fields: 'id',
    }),

  langSlug: ({ lang, slug }: Params) =>
    fetcher.post<LangSlug>(
      '/content-type/delivery/slug-lookup/',
      [
        {
          slug,
          content_type: 'pages',
          'filter[slug__ne]': 'none',
        },
      ],
      { lang },
    ),

  search: (params = {} as Params) =>
    fetcher.get<SearchedBlog[]>('/content-type/delivery/pages/list/', {
      ...params,
      depth: 1,
      page: 1,
      sort: '-created_at',
      'filter[has_article__ne]': null,
      'filter[slug__ne]': null,
      fields: ['title', 'slug'].join(','),
    }),
};
