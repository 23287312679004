import { useContext, useMemo } from 'react';

import { hmsToSeconds, secondsToHms } from 'libs/number';

import { ContentContext } from 'context/ContentContext';

import { HeroText } from 'ui/atoms/HeroText/HeroText';
import { CardsPreview } from 'ui/atoms/CardsPreview/CardsPreview';

import { CourseDuration } from 'features/academy/atoms/CourseDuration/CourseDuration';
import { LearningPlatform } from 'features/academy/organisms/LearningPlatform/LearningPlatform';

import styles from './ProspectingPlatformPage.module.scss';

const ProspectingPlatformPage = () => {
  const { data: content, additionals } = useContext(ContentContext);

  const academyFaculty = useMemo(() => content.has_academy_faculty || {}, [content.has_academy_faculty]);
  const academyFaculties = useMemo(() => additionals.academyFaculties?.data || [], [additionals]);

  const [lessonsCount, lessonsDuration] = useMemo(() => {
    if (Boolean(academyFaculty.courses?.length) === false) {
      return [0, '0'];
    }

    return [
      academyFaculty.courses.length,
      academyFaculty.courses.filter((i) => Boolean(i.duration)).length > 0
        ? secondsToHms(
            academyFaculty.courses.reduce((acc, current) => {
              return (acc += hmsToSeconds(current.duration) || 0);
            }, 0),
          )
        : undefined,
    ];
  }, [academyFaculty.courses]);

  return (
    <div className={styles['prospect-wrapper']}>
      <div className={styles.container}>
        <HeroText size="lg">{academyFaculty.title}</HeroText>

        <CourseDuration minimal lessons={lessonsCount} duration={lessonsDuration} align="center" />

        <LearningPlatform />

        {academyFaculties.length > 0 && (
          <div>
            <HeroText size="md" align="left" className={styles['prospect-title']}>
              Watch next
            </HeroText>

            <CardsPreview size="md" />
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProspectingPlatformPage;
