import { useContext, useEffect, useMemo } from 'react';

import { content } from 'libs/http/api/content/content';
import { WebTechnology } from 'libs/http/api/content/content.types';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';

import { WebTechWidgetItem } from '../WebTechWidgetItem/WebTechWidgetItem';

export const WebTechWidget = () => {
  const { query } = useRouter();
  const { request, data } = useRequest<WebTechnology[]>({ data: [] });
  const { additionals, pageVars } = useContext(ContentContext);

  useEffect(() => {
    [1, 2, 3].forEach((page) => request(content.webTechnology({ lang: `${query.lang}`, page }), { concat: true }));
  }, []);

  const insightsPageVars = useMemo(() => pageVars.insights || {}, [pageVars.insights]);

  return (
    <div className="web-tech-widget">
      <div className="web-tech-widget__left">
        <h2>{insightsPageVars.technologies_title}</h2>
        <span>{insightsPageVars.technologies_description}</span>
      </div>

      {additionals.webTechnologies?.data && (
        <div className="web-tech-widget__right">
          {additionals.webTechnologies.data.map((item) => (
            <WebTechWidgetItem key={item.id} widget={item} technologies={data} />
          ))}
        </div>
      )}
    </div>
  );
};
