import { useMemo } from 'react';

import cn from 'classnames';

import type { ScoreData } from 'libs/http/api/dashboard/dashboard.types';
import type { $Object } from 'libs/object/object.types';

import { SmoothLoading } from 'ui/atoms/SmoothLoading/SmoothLoading';

import styles from './Scores.module.scss';

type Score = {
  color: string;
  value: number;
  label: string;
};

type Props = {
  data: ScoreData;
  loading?: boolean;
};

const colors: $Object = {
  not_found: 'red',
  total: 'yellow',
  found: 'green',
  processed: 'light-blue',
};

export const Scores = ({ data, loading = false }: Props) => {
  const [commonData, scoresData] = useMemo(() => {
    const mapData = (obj: { [key: string]: number }, { labelPrefix = '' }: { labelPrefix?: string } = {}) => {
      return Object.keys(obj).reduce<Score[]>((acc, current) => {
        acc.push({
          color: colors[current] || current.toLowerCase(),
          value: obj[current] || 0,
          label: `${labelPrefix}${current.replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}`,
        });

        return acc;
      }, []);
    };

    const { scores: scores = {}, ...common } = data;

    return [mapData(common), mapData(scores || {}, { labelPrefix: 'Score ' })];
  }, [data]);

  return (
    <SmoothLoading loading={loading}>
      <div className={styles['scores-wrapper']}>
        {[...commonData, ...scoresData]
          .filter((i) => i.value > 0)
          .map((item) => (
            <div className={cn(styles['score-item'], styles[`score-${item.color}`])} key={item.label}>
              <p>{item.value}</p>
              <span>{item.label}</span>
            </div>
          ))}
      </div>
    </SmoothLoading>
  );
};
