import { useContext, useMemo } from 'react';

import { ContentContext } from 'context/ContentContext';

import { Topics } from 'features/articles/atoms/Topics/Topics';
import { Search } from 'features/articles/organisms/Search/Search';
import { Benefits } from 'features/articles/organisms/Benefits/Benefits';

import styles from './BlogPage.module.scss';

const BlogPage = () => {
  const { pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  return (
    <div className={styles['blog-page']}>
      <div className={styles['blog-page__title']}>
        <h1>{commonPageVars.blog_title}</h1>
      </div>

      <Topics />

      <Search />

      <Benefits hasPriortyFirstImage className={styles['blog-page-benefits']} withBlog />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default BlogPage;
