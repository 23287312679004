import { ReactNode, useCallback, useEffect, useRef } from 'react';

import cn from "classnames";

import { clearTimeoutIfExists } from 'libs/node';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Button } from 'ui/atoms/Button/Button';

interface CommonProps {
  disabled?: boolean;
  route?: string;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

interface Props extends CommonProps {
  title: string;
  description?: string;
  icon?: string;
}

const InfoContainer = ({ onClick, route, disabled, className, children }: CommonProps) => {
  const closeDebounce = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(closeDebounce.current);
    };
  }, []);

  const onDelayedClose = useCallback(() => {
    if (typeof onClick === 'undefined') {
      return;
    }

    closeDebounce.current = setTimeout(() => {
      onClick();
    }, 200);
  }, [onClick]);

  return route && !disabled ? (
    <Button route={route} className={cn('loading-link', className)} onClick={!route ? onClick : onDelayedClose}>
      {children}
    </Button>
  ) : (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export const Info = ({ title, description, icon, route, disabled, className = '', onClick }: Props) => (
  <InfoContainer
    className={`rc-info ${className}${disabled ? ' disabled' : ''}`}
    route={route}
    disabled={disabled}
    onClick={onClick}
  >
    {icon && <Icon type={icon} />}

    <label>
      {title}

      {description && <span>{description}</span>}
    </label>
  </InfoContainer>
);
