import { useMemo, useCallback, useContext } from 'react';

import cn from 'classnames';

import { ContentContext } from 'context/ContentContext';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Switch } from 'ui/atoms/Switch/Switch';

import styles from './FrequencySwitch.module.scss';

export type Frequency = {
  value: string;
  label: string;
  discount?: number;
};

const Discount = ({ text = '', position = 0 /** supports only 0 at the moment */, amount = 0 }) => (
  <div className={cn(styles['frequency-switch-discount-wrapper'], styles[`frequency-switch-discount-pos-${position}`])}>
    <div className={styles['frequency-switch-discount']}>
      <Icon type="arrow-tariff" />
      <span>{text.replace('{amount}', `${amount}`)}</span>
    </div>
  </div>
);

type Props = {
  data: Frequency[];
  value: Frequency['value'];
  onChange: (value: Frequency['value']) => void;
};

export const FrequencySwitch = ({ data, value, onChange }: Props) => {
  const { pageVars } = useContext(ContentContext);

  const pricingPageVars = useMemo(() => pageVars.pricing_products || {}, [pageVars.pricing_products]);

  const onChangeHandler = (checked: boolean) => onChange(data[checked ? 1 : 0].value);
  const onClickLabel = useCallback((checked: boolean) => () => onChangeHandler(checked), [onChangeHandler]);

  const [discount, discountPosition] = useMemo(() => {
    const $discountPosition = data.reduce<number | null>((acc, current, idx) => {
      if (acc === null && 'discount' in current) {
        acc = idx;
      }

      return acc;
    }, null);

    if ($discountPosition === null) {
      return [null, undefined];
    }

    return [data[$discountPosition].discount, $discountPosition];
  }, [data]);

  return (
    <div className={styles['frequency-switch']}>
      {discount ? <Discount text={pricingPageVars.discount} position={discountPosition} amount={discount} /> : null}

      <span onClick={onClickLabel(false)}>{data[0].label}</span>

      <Switch checked={data[1].value === value} onChange={onChangeHandler} />

      <span onClick={onClickLabel(true)}>{data[1].label}</span>
    </div>
  );
};
