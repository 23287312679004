import { Link } from 'libs/router/Link';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './CareerCard.module.scss';

type Props = {
  name: string;
  category: string;
  location: string;
  slug: string;
};

export const CareerCard = ({ name, category, location, slug }: Props) => {
  return (
    <Link className={styles['career-card-link']} route={slug}>
      <div className={styles['career-card-wrapper']}>
        <div className={styles['details-wrapper']}>
          <div className={styles['name']}>{name}</div>

          <div className={styles['job-details']}>
            <div className={styles['detail']}>
              <Icon type="job-type" className={styles['detail-icon']} />
              <div className={styles['detail-name']}>{category}</div>
            </div>

            <div className={styles['detail']}>
              <Icon type="job-location" className={styles['detail-icon']} />
              <div className={styles['detail-name']}>{location}</div>
            </div>
          </div>
        </div>

        <div className={styles['button-wrapper']}>
          <div className={styles['button']}>
            <Icon type="download-arrow-right" className={styles['button-arrow']} />
          </div>
        </div>
      </div>
    </Link>
  );
};
