/* eslint react-hooks/exhaustive-deps: "warn" */

import { useRef, useState, useEffect, RefObject } from 'react';

import cn from 'classnames';

import { useEventListener } from 'hooks/useEventListener';

import { ProgressLine } from 'ui/atoms/ProgressLine/ProgressLine';

import styles from './ReadingBar.module.scss';

type Props = {
  className?: string;
  scrollArticlesRef: RefObject<HTMLDivElement>;
};

export const ReadingBar = ({ className, scrollArticlesRef }: Props) => {
  const scrollThrottle = useRef<NodeJS.Timeout>();

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    return () => {
      if (scrollThrottle.current !== undefined) {
        clearTimeout(scrollThrottle.current);
      }
    };
  }, []);

  useEventListener('scroll', () => {
    if (scrollThrottle.current !== undefined) {
      return;
    }

    scrollThrottle.current = setTimeout(() => {
      window.requestAnimationFrame(() => {
        scrollThrottle.current = undefined;

        const elem = document.documentElement;

        const scrollTop = elem.scrollTop || document.body.scrollTop;
        const scrollHeight = scrollArticlesRef.current?.scrollHeight || document.body.scrollHeight;
        const percent = (scrollTop / (scrollHeight - elem.clientHeight)) * 100;

        setWidth(percent);
      });
    }, 610);
  });

  return <ProgressLine className={cn(styles['reading-bar'], className)} percentage={width} />;
};
