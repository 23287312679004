/* eslint react-hooks/exhaustive-deps: "warn" */

import { useMemo, useEffect, useState, useCallback } from 'react';

import NextImage, { type ImageLoaderProps, type ImageProps } from 'next/legacy/image';

import { calcProportionalHeight } from 'libs/node';

import { useImage } from 'hooks/useImage';

const FALLBACK_SRC = 'https://platform.globaldatabase.com/img/no-image.png';
const EMPTY_SRC = '/storage/img/noise-light.png';

type Props = {
  width: number;
  height: number;
  priority?: boolean;
  className?: string;
  src?: string | { url: string; name: string };
  alt?: string | undefined;
} & Omit<ImageProps, 'alt'>;

export const Image = ({ width: $width, height, priority = false, src: $src, alt = '', ...props }: Props) => {
  const { buildUrl } = useImage();

  const [hasError, setHasError] = useState(false);

  const [innerWidth, setInnerWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!priority) {
      setInnerWidth(window.innerWidth * 2);
    }
  }, [priority]);

  const loader = useCallback(
    ({ src, width }: ImageLoaderProps) => {
      /** Do not block any prior images as this significantly impacts LCP score */
      if (priority) {
        return buildUrl({ src, width, height }) || EMPTY_SRC;
      }

      let finalWidth = width;
      let finalHeight = height;

      if (innerWidth && width > innerWidth) {
        finalWidth = innerWidth;
        finalHeight = calcProportionalHeight(width, height, innerWidth);
      }

      return buildUrl({ src, width: finalWidth, height: finalHeight }) || EMPTY_SRC;
    },
    [buildUrl, height, innerWidth, priority],
  );

  const onError = useCallback(() => setHasError(true), []);

  const finalSrc = useMemo(() => {
    if (!priority && innerWidth === undefined) {
      return EMPTY_SRC;
    }

    if (hasError) {
      return FALLBACK_SRC;
    }

    return $src;
  }, [$src, hasError, innerWidth, priority]);

  if (!$src) {
    return null;
  }

  return (
    <NextImage
      {...props}
      priority={priority}
      unoptimized={false}
      alt={alt}
      height={height}
      loader={loader}
      onError={onError}
      // all these props bellow will be passed automatically to loader as args
      width={$width}
      quality={100}
      src={finalSrc}
    />
  );
};
