import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { Laptop } from 'ui/atoms/Laptop/Laptop';
import { Cards } from 'ui/atoms/Cards/Cards';
import { Faq } from 'ui/atoms/Faq/Faq';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

import { ProfileDescription } from 'features/profile/organisms/ProfileDescription/ProfileDescription';
import { Data as ProfileDescriptionData } from 'features/profile/organisms/ProfileDescription/ProfileDescription.types';

const CountryPage = () => {
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const [directory, industry, emailDatabase, phoneDirectory] = useMemo(
    (): ProfileDescriptionData[] =>
      content.has_country_information !== undefined
        ? [
            {
              // latest update
              title: content.has_country_information.content_item_title_1,
              average: content.has_country_information.content_item_numbers_1,
              averageInfo: content.has_country_information.content_item_numbers_sub_1,
              data: content.has_country_information.content_item_numbers_3,
              dataInfo: content.has_country_information.content_item_numbers_sub_3,
              dataTwo: content.has_country_information.content_item_numbers_2 || 'Yesterday',
              dataTwoInfo: content.has_country_information.content_item_numbers_sub_2,
              description: content.has_country_information.content_item_text_1,
            },
            {
              title: content.has_country_information.content_item_title_2,
              average: content.has_country_information.content_item_second_numbers_1,
              averageInfo: content.has_country_information.content_item_second_numbers_sub_1,
              data: content.has_country_information.content_item_second_numbers_3,
              dataInfo: content.has_country_information.content_item_second_numbers_sub_3,
              dataTwo: content.has_country_information.content_item_second_numbers_2,
              dataTwoInfo: content.has_country_information.content_item_second_numbers_sub_2,
              description: content.has_country_information.content_item_text_2,
            },
            {
              title: content.has_country_information.content_item_title_3,
              average: content.has_country_information.content_item_third_numbers_1,
              averageInfo: content.has_country_information.content_item_third_numbers_sub_1,
              data: content.has_country_information.content_item_third_numbers_3,
              dataInfo: content.has_country_information.content_item_third_numbers_sub_3,
              dataTwo: content.has_country_information.content_item_third_numbers_2,
              dataTwoInfo: content.has_country_information.content_item_third_numbers_sub_2,
              description: content.has_country_information.content_item_text_3,
            },
            {
              title: content.has_country_information.content_item_title_4,
              average: content.has_country_information.content_item_four_numbers_1,
              averageInfo: content.has_country_information.content_item_four_numbers_sub_1,
              data: content.has_country_information.content_item_four_numbers_2,
              dataInfo: content.has_country_information.content_item_four_numbers_sub_2,
              dataTwo: content.has_country_information.content_item_four_numbers_3,
              dataTwoInfo: content.has_country_information.content_item_four_numbers_sub_3,
              description: content.has_country_information.content_item_text_4,
            },
          ]
        : [],
    [content],
  );

  if (content.has_country_information === undefined) {
    return <span>Oops! {"There's"} no country information on Global CMS.</span>;
  }

  return (
    <div className="countries-page">
      <InfoBlock
        title={content.has_country_information.title}
        buttonText={content.button_text}
        buttonHref={content.button_href}
      />

      <Cards
        data={[
          {
            title: commonPageVars.profile_title_1,
            description: commonPageVars.profile_desc_1,
          },
          {
            title: commonPageVars.profile_title_2,
            description: commonPageVars.profile_desc_2,
          },
          {
            title: commonPageVars.profile_title_3,
            description: commonPageVars.profile_desc_3,
          },
        ]}
      />

      <ProfileDescription
        invert
        touch="business-free-trial"
        titlePosition="text"
        type="blue-white-grey"
        className="directory-profile"
        data={directory}
      />

      <div className="content alternative">
        <ProfileDescription
          touch="industry-request-sample"
          className="industry-profile"
          titlePosition="text"
          data={industry}
          type="grey-red-transparent"
        />

        <DynamicImport fallback={() => <RequestDemoLoader />}>
          {() => {
            const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
              ssr: false,
              loading: () => <RequestDemoLoader />,
            });

            return <Component title={content.book_demo_title} button={content.book_demo_button} />;
          }}
        </DynamicImport>
      </div>

      <ProfileDescription
        touch="email-request-demo"
        data={emailDatabase}
        titlePosition="text"
        type="green-grey-white"
        className="email-profile"
      />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title_1_2} button={content.book_demo_button_1_2} />;
        }}
      </DynamicImport>

      <div className="content">
        <ProfileDescription
          invert
          touch="phone-book-demo"
          titlePosition="text"
          data={phoneDirectory}
          className="phone-profile"
          type="white-transparent-orange"
        />

        <Laptop
          className="marketing-profile"
          touch="marketing-free-trial"
          title={content.has_country_information.laptop_title}
          description={content.has_country_information.laptop_text}
          image={content.has_country_information.laptop_image}
        />

        {additionals.faq && additionals.faq.data.length > 0 && (
          <Faq title={content.has_country_information.question_title} data={additionals.faq.data} />
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CountryPage;
