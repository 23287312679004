import { useEffect, useCallback, useContext, useRef } from 'react';

import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'libs/localStorage';

import { ContentContext } from 'context/ContentContext';

import { useStateHandlers } from 'hooks/useStateHandlers';

import { CompaniesDataTemplate } from 'features/enrichment/templates/CompaniesDataTemplate/CompaniesDataTemplate';
import { CompaniesUploadTemplate } from 'features/enrichment/templates/CompaniesUploadTemplate/CompaniesUploadTemplate';

import styles from './EnrichmentPage.module.scss';

const EnrichmentPage = () => {
  const { data: content } = useContext(ContentContext);
  const topScrollRef = useRef<null | HTMLDivElement>(null);

  const [state, setState] = useStateHandlers({
    type: 'upload',
    session_key: '',
  });

  useEffect(() => {
    const session = getLocalStorage('enrichment_session');

    if (session !== null) {
      setState({
        type: 'data',
        session_key: session as string,
      });
    }
  }, []);

  const onChangeType = useCallback((status: string, sessionKey: string) => {
    topScrollRef.current?.scrollIntoView({ behavior: 'smooth' });

    if (status === 'pending') {
      setLocalStorage('enrichment_session', sessionKey);
      setState({ type: 'data', session_key: sessionKey });
    }
  }, []);

  const onRefresh = () => {
    removeLocalStorage('enrichment_session');
    setState({
      type: 'upload',
      session_key: '',
    });
  };

  return (
    <div ref={topScrollRef} className={styles['enrichment-wrapper']}>
      <div className={styles['container']}>
        <div className={styles['enrichment-title']}>
          <h1>{content.title}</h1>

          {state.type === 'upload' && content.description && <h2>{content.description}</h2>}
        </div>

        {state.type === 'upload' && <CompaniesUploadTemplate onChangeType={onChangeType} />}

        {state.type === 'data' && <CompaniesDataTemplate onRefresh={onRefresh} sessionKey={state.session_key} />}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default EnrichmentPage;
