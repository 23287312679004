import { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

export const Layout = ({ className = '', children }: Props) => {
  return <div className={`layout ${className}`}>{children}</div>;
};

export const LayoutContainer = ({ children }: Props) => {
  return <div className="layout-main-container">{children}</div>;
};

export const LayoutContent = ({ children }: Props) => {
  return <div className="layout-main-container-children">{children}</div>;
};
