import { createContext, useState, useEffect, ReactNode } from 'react';

import { setLocalStorage, getLocalStorage } from 'libs/localStorage';
import { guid } from 'libs/string';

export const initialValues = {
  consent: '' as string | undefined,
  consentId: '' as string | undefined,
  sync: () => {},
  change: (_type: string) => {},
  remove: () => {},
};

export const ConsentContext = createContext<typeof initialValues>(initialValues);

export const ConsentProvider = ({ children }: { children: ReactNode }) => {
  const [consent, setConsent] = useState<string>();
  const [consentId, setConsentId] = useState<string>();

  useEffect(() => {
    sync();
  }, []);

  const sync = () => {
    setConsent(getLocalStorage('consent'));
    setConsentId(getLocalStorage('consent-id'));
  };

  const change = (type: string) => {
    setLocalStorage('consent', type);
    setLocalStorage('consent-id', guid());

    sync();
  };

  const remove = () => {
    setLocalStorage('consent', '');
    setLocalStorage('consent-id', '');

    sync();
  };

  return (
    <ConsentContext.Provider
      value={{
        consent,
        consentId,
        sync,
        change,
        remove,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};
