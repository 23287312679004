import { useContext, useEffect, useMemo, useState } from 'react';

import * as types from 'types';

import { useRouter } from 'libs/router/useRouter';
import { Languages, languageNames, defaultLocale } from 'libs/locale';

import { ContentContext } from 'context/ContentContext';

import { Dropdown } from 'ui/atoms/Dropdown/Dropdown';

export const Topbar = () => {
  const { query } = useRouter();
  const {
    links,
    pageVars,
    secondaryNavigation,
    navigationGroups: { about = [], integrations = [], blog = [] },
  } = useContext(ContentContext);

  const [lang, setLang] = useState<Languages>(defaultLocale);

  const hasCountryPromotion = useMemo(() => 'country_promotion' in pageVars, [pageVars]);

  useEffect(() => {
    setLang(`${query.lang}` as Languages);
    document.cookie = `NEXT_LOCALE=${query.lang}`;
  }, [query]);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const languageOptions = useMemo(() => {
    return links.map((item) => ({
      value: item.key,
      title: (
        <a
          {...(item.link ? { href: `${process.env.HOST || ''}${item.link}` } : { href: `${process.env.HOST || ''}/` })}
        >
          {languageNames[item.key as Languages]}
        </a>
      ),
    }));
  }, [languageNames, links]);

  const integrationsOptions = useMemo(
    () =>
      integrations
        .filter(
          ({ hasIntegrationInformation, title }) => commonPageVars[`navigation_${hasIntegrationInformation}`] && title,
        )
        .reduce<string[]>(
          (acc, current) =>
            !acc.includes(current.hasIntegrationInformation) ? [...acc, current.hasIntegrationInformation] : acc,
          [],
        )
        .map((type) => ({
          value: type,
          title: commonPageVars[`navigation_${type}`],
          children: integrations
            .filter(({ hasIntegrationInformation }) => type === hasIntegrationInformation)
            .map((item) => ({ value: item.slug, title: item.title, route: item.slug })),
        })),
    [integrations, commonPageVars],
  );

  const blogOptions = useMemo(
    () =>
      secondaryNavigation.blog?.slug
        ? types.blog
            .filter((type) => commonPageVars[`blog_${type}`])
            .map((type) => ({
              value: type,
              title: commonPageVars[`blog_${type}`],
              route: secondaryNavigation.blog.slug,
              query: { type, paage: 1 },
            }))
        : [],
    [blog, secondaryNavigation, commonPageVars],
  );

  const aboutOptions = useMemo(
    () => about.filter(({ title }) => title).map((item) => ({ value: item.slug, title: item.title, route: item.slug })),
    [about],
  );

  const onChangeLanguage = (value: string): void => setLang(value as Languages);

  if (hasCountryPromotion) {
    return null;
  }

  return (
    <div className="topbar">
      <div className="topbar__container">
        <div className="topbar__select">
          <Dropdown
            className="topbar__language topbar__select-option-link"
            label={
              languageOptions.length > 0 ? (
                <span className="topbar__select-label">
                  <b>Language: </b>
                  <strong>{languageNames[lang]}</strong>
                </span>
              ) : null
            }
            value={lang}
            onChange={onChangeLanguage}
            options={languageOptions}
          />
        </div>

        <div className="topbar__info">
          {blogOptions.length > 0 && (
            <Dropdown
              label={commonPageVars.header_topbar_blog_title}
              options={blogOptions}
              position="left"
              width={214}
            />
          )}

          {integrationsOptions.length > 0 && (
            <Dropdown
              className="middle"
              label={commonPageVars.header_topbar_integrations_title}
              options={integrationsOptions}
              position="right"
              width={214}
            />
          )}

          {aboutOptions.length > 0 && (
            <Dropdown
              label={commonPageVars.header_topbar_about_title}
              options={aboutOptions}
              position="right"
              width={214}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Topbar;
