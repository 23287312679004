import { useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

import { Image } from 'ui/atoms/Image';
import { SocialMedia } from 'ui/atoms/SocialMedia/SocialMedia';

import { Benefits } from 'features/articles/organisms/Benefits/Benefits';

const AuthorPage = () => {
  const { secondaryNavigation, additionals } = useContext(ContentContext);

  return (
    <div className="author-page">
      {additionals.author?.data && (
        <div className="author-page__author">
          {additionals.author.data.photo && (
            <Image
              priority
              objectFit="contain"
              width={124}
              height={124}
              src={additionals.author.data.photo.url}
              alt={additionals.author.data.full_name}
            />
          )}

          <h1>{additionals.author.data.full_name}</h1>

          <div
            className="author-text"
            dangerouslySetInnerHTML={{ __html: additionals.author.data.motivation_text || '' }}
          />

          <SocialMedia
            twitter={additionals.author.data.twitter_link}
            facebook={additionals.author.data.facebook_link}
            linkedin={additionals.author.data.linkedin_link}
            medium={additionals.author.data.medium_link}
          />
        </div>
      )}

      <div className="author-page__content">
        <Benefits limit={7} withBlog route={secondaryNavigation.author.slug} />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AuthorPage;
