interface OurValuesItem {
  styleDiv?: string;
  title?: string;
  description?: string;
}

export const OurValuesItem = ({ styleDiv, title, description }: OurValuesItem) => {
  return (
    <div className="our-values__blocks">
      <div className={`our-values__color ${styleDiv}`}>
        <div></div>
      </div>
      <h4>{title}</h4>
      <span>{description}</span>
    </div>
  );
};
