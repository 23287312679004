import { useState } from 'react';

import { useResizeListener } from './useResizeListener';

export const useMediaQuery = (width: number): boolean | undefined => {
  const [matches, setMatches] = useState<ReturnType<typeof useMediaQuery>>(undefined);

  useResizeListener(() => {
    setMatches(window.innerWidth >= width);
  }, []);

  return matches;
};
