import { ReactNode, useContext, useMemo } from 'react';

import { Link } from 'libs/router/Link';
import { $Object } from 'libs/object/object.types';

import { ContentContext } from 'context/ContentContext';

import { useStateHandlers } from 'hooks/useStateHandlers';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Mask } from 'ui/atoms/Mask/Mask';

const Div = (props: any) => <div {...props} />;

export interface Option {
  value?: string;
  icon?: string;
  title: ReactNode;
  description?: ReactNode;
  children?: Option[] | null;
  megaDropdown?: boolean;
  route?: string;
  query?: $Object<any>;
  parent?: string;
}

interface Props {
  loading?: boolean;
  value?: string;
  label: ReactNode;
  options: Option[];
  position?: 'right' | 'left' | 'top';
  className?: string;
  width?: number;
  onChange?: (value: string) => void;
}

export const Dropdown = ({
  loading,
  label,
  value,
  options,
  position = 'left',
  className = '',
  width,
  onChange,
}: Props) => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const [{ show, parent }, setState] = useStateHandlers({ show: false, parent: '' });

  const hasOptions = useMemo(
    () => (options.length === 1 && options[0].value !== value) || options.length > 1,
    [options, value],
  );

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const renderItems = ({ value: val, icon, title, description, route, query, children = [] }: Option) => {
    const Component = route ? Link : Div;

    const onClick = children && children.length > 0 ? () => onClickItem(val) : () => onChangeHandler(val);

    return (
      <Component
        {...(route ? { route, query } : {})}
        key={val || route}
        className={`rc-dropdown-options-item rc-dropdown-${children && children.length > 0 ? 'has' : 'has-no'}-options${
          parent === val || val === value ? ' rc-dropdown-options-item-open' : ''
        }`}
        onClick={onClick}
      >
        {icon && (
          <div className="rc-dropdown-options-item-icon">
            <Icon type={icon} />
          </div>
        )}

        {title}

        {description && <div className="rc-dropdown-options-item-description">{description}</div>}

        {children && children.length > 0 && <Icon type="arrow-down" />}
      </Component>
    );
  };

  const onChangeHandler = (value?: string) => {
    if (value && onChange !== undefined) {
      onChange(value);
    }

    onClickOutside();
  };

  const onToggleShow = () => (hasOptions ? setState((prevState) => ({ show: !prevState.show })) : undefined);
  const onClickOutside = () => setState({ show: false, parent: '' });
  const onClickItem = (newParent = '') =>
    setState((prevState) => ({ parent: prevState.parent !== newParent ? newParent : '' }));

  const optionChildren = useMemo(
    () =>
      options.reduce(
        (acc, { value, children = [] }) =>
          children ? [...acc, ...children.map((i) => ({ ...i, parent: value }))] : acc,
        [] as Option[],
      ),
    [options],
  );

  return (
    <>
      {show && <Mask type="transparent" onClick={onToggleShow} zIndex={98} />}

      <div
        className={`rc-dropdown${show ? ' rc-dropdown-open' : ''}${loading ? ' rc-dropdown-loading' : ''} rc-dropdown-${
          hasOptions ? 'has' : 'has-no'
        }-options`}
      >
        <div className="rc-dropdown-label" onClick={onToggleShow}>
          {label} {hasOptions && <Icon type="arrow-down" />}
        </div>

        <div
          className={`rc-dropdown-options rc-dropdown-options-${position} ${!show ? 'hide' : ''} ${className}`}
          style={{ ...(width ? { minWidth: width } : {}) }}
        >
          {options.length > 0 && (
            <div className="rc-dropdown-options-row">
              {label === 'Blog' && commonPageVars.blog_all && (
                <Link
                  className="rc-dropdown-options-item"
                  route={secondaryNavigation.blog.slug}
                  onClick={onToggleShow}
                  query={{ paage: 1 }}
                >
                  {commonPageVars.blog_all}
                </Link>
              )}

              {options.map(renderItems)}
            </div>
          )}

          <div className={`rc-dropdown-options-row ${!parent || optionChildren.length === 0 ? 'hide' : ''}`}>
            {optionChildren.map((child) => (
              <Link
                key={child.value}
                onClick={onToggleShow}
                route={child.route}
                query={child.query}
                className={`rc-dropdown-options-item ${child.parent !== parent ? 'hide' : ''}`}
              >
                {child.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
