import { useCallback, useContext, useEffect, useMemo } from 'react';

import cn from 'classnames';

import { $Object } from 'libs/object/object.types';
import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { Price, PriceItem, Product, Report } from 'libs/http/api/dashboard/dashboard.types';
import { currencySymbol } from 'libs/string';
import { stringifyQuery } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';

import { Modal } from 'ui/organisms/Modal/Modal';
import { Icon } from 'ui/atoms/Icon/Icon';
import { Button } from 'ui/atoms/Button/Button';
import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';
import { PresentationBlock } from 'ui/organisms/PresentationBlock/PresentationBlock';

import styles from './BuyReportModal.module.scss';

const plans = {
  one_report: [
    'Credit Rating and Limit Recomendations',
    '5 years of financial statements',
    'Directors and shareholders',
    'Court Judgements',
    'Global Group Structure',
    'Trade Payment',
  ],
  unlimited_report: [
    'Everything include in one report, plus',
    'Unlimited credit reports download',
    'Unlimited users',
    'API access',
    'CRM Integration',
    'Dedicated account manager',
  ],
};

interface Props {
  onClose: () => void;
  country: string;
  countryId?: number;
  report: Report;
}

const priceOptions = {
  loading: true,
  data: { credit_reports: [] },
};

const productOptions = {
  loading: true,
  data: {} as Product,
};

const countriesOfferUnlimitedReports = {
  gb: 142,
} as $Object<number>;

export const BuyReportModal = ({ onClose, country: $country, report }: Props) => {
  const { query } = useRouter();
  const { request: requestPrice, data: dataPrice, loading: loadingPrice } = useRequest<Price>(priceOptions);
  const { request: requestProduct, data: dataProduct, loading: loadingProduct } = useRequest<Product>(productOptions);
  const { pageVars } = useContext(ContentContext);

  const country = useMemo(() => $country.toLowerCase(), [$country]);

  useEffect(() => {
    const lang = `${query.lang}`;

    requestPrice(dashboard.price({ country_code: country, lang }));

    if (country in countriesOfferUnlimitedReports) {
      requestProduct(dashboard.product.get({ lang, id: countriesOfferUnlimitedReports[country] }));
    }
  }, []);

  const creditReportPageVars = useMemo(() => pageVars.credit_report || {}, [pageVars.credit_report]);

  const price = useMemo(
    () =>
      dataPrice.credit_reports.find(({ codename }) => `credit_reports_${country}` === codename) || ({} as PriceItem),
    [country, dataPrice],
  );

  const onRedirectBuyCreditReport = useCallback(
    (payOne: boolean) => () => {
      const order = {
        data: [] as $Object<any>,
        origin: location.href,
      };

      if (payOne === false && country in countriesOfferUnlimitedReports) {
        order.data.push({
          type: 'product',
          data: {
            frequency: 'month',
            product_id: countriesOfferUnlimitedReports[country],
            credit_report: {
              source_id: encodeURIComponent(report.id),
              id: price.id,
            },
          },
        });
      } else {
        order.data.push({
          type: 'credit_report',
          data: {
            id: price.id,
            title: report.name,
            pay_one: payOne,
            source_id: encodeURIComponent(report.id),
            country,
          },
        });
      }

      window.open(`${process.env.PLATFORM}/public/payment/summary?${stringifyQuery(order)}`, '_self');
    },
    [price, country, report.id, report.name, location.href],
  );

  return (
    <Modal onClose={onClose} maxWidth={country in countriesOfferUnlimitedReports ? 700 : 500} padding={22}>
      <div className={styles['buy-report']}>
        <div className={styles['buy-report-plans']}>
          <div
            className={cn(
              styles['buy-report-plan'],
              country in countriesOfferUnlimitedReports === false && styles['highlighted'],
            )}
          >
            <div>
              <p className={styles['buy-report-title']}>{creditReportPageVars.button_report_placeholder}</p>

              <ul>
                {plans.one_report.map((item) => (
                  <li key={item}>
                    <Icon type="check-square" />

                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>

            <Button
              size={32}
              type={country in countriesOfferUnlimitedReports ? 'transparent' : 'primary'}
              onClick={onRedirectBuyCreditReport(true)}
              id="buy-report"
            >
              {creditReportPageVars.button_report_placeholder}
              <span className={cn(loadingPrice && styles['loading'])}>
                {currencySymbol(price?.currency?.name) || '0'}
                {price.price_one || '00'}
              </span>
            </Button>

            {country in countriesOfferUnlimitedReports === false && (
              <Button size={32} type="transparent" onClick={onRedirectBuyCreditReport(false)}>
                {creditReportPageVars.buy_report_promotion_title}
              </Button>
            )}
          </div>

          {country in countriesOfferUnlimitedReports && (
            <div className={cn(styles['buy-report-plan'], styles['highlighted'])}>
              <div>
                <p className={styles['buy-report-title']}>{creditReportPageVars.buy_report_promotion_desc}</p>

                <ul>
                  {plans.unlimited_report.map((item) => (
                    <li key={item}>
                      <Icon type="check-square" />

                      <p>{item}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <Button size={32} type="transparent" onClick={onRedirectBuyCreditReport(false)}>
                {creditReportPageVars.buy_report_button}
                <span className={cn(loadingProduct && styles['loading'])}>
                  {currencySymbol(dataProduct.currency?.name) || '0'}
                  {dataProduct.price_info?.month || '00'}
                </span>
                <span className={styles['hidden-tablet']}>/month/unlimited</span>
              </Button>
            </div>
          )}
        </div>

        <div className={styles['buy-report-sample']}>
          <a
            href="https://media.globaldatabase.com/media/0e90b2be-3252-4001-bc14-397475957caa.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <Icon type="sample-pdf" />

              <span>{creditReportPageVars.view_sample}</span>
            </div>
          </a>
        </div>

        <div className={styles['buy-report-customers']}>
          <OurCustomers />
        </div>

        <div className={styles['buy-report-rating']}>
          {[1, 2, 3, 4, 5].map((item) => (
            <Icon type={`report-rating-${item}`} key={item} />
          ))}
        </div>

        <div className={styles['buy-report-video']}>
          <PresentationBlock id="credit-report" hasTextWrapper={false} />
        </div>
      </div>
    </Modal>
  );
};
