import { CSSProperties, ReactNode } from 'react';

import { Link } from 'libs/router/Link';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './HeaderItem.module.scss';

interface HeaderProps {
  className?: string;
  children?: ReactNode;
}

export const HeaderRows = ({ children, className = '' }: HeaderProps) => (
  <div className={`${styles['gdb-header-rows']} ${className}`}>{children}</div>
);

export const HeaderRow = ({ children, className = '' }: HeaderProps) => (
  <div className={`${styles['gdb-header-row']} ${className}`}>{children}</div>
);

interface Props {
  disabled?: boolean;
  route?: string;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;

  title: string;
  description?: string;
  icon?: string;
}

export const HeaderItem = ({
  route,
  onClick,
  disabled,
  className = '',
  style,

  title,
  description,
  icon,
}: Props) => {
  const commonProps = {
    onClick,
    className: `${styles['gdb-header-item']} ${className} ${icon ? styles['has-icon'] : ''} ${
      disabled ? styles['disabled'] : ''
    }`,
    disabled,
    children: (
      <>
        {icon && <Icon className={styles['gdb-header-item-icon']} type={icon} />}

        {title}

        {description && <span className={styles['gdb-header-item-desc']}>{description}</span>}
      </>
    ),
    style,
  };

  if (route && !disabled) {
    return <Link route={route} {...commonProps} />;
  }

  return <div {...commonProps} />;
};
