import { useMemo, useRef, useState } from 'react';

import { useIntersection } from 'hooks/useIntersection';
import { useImage } from 'hooks/useImage';

import type { ButtonSize, ButtonType } from 'ui/atoms/Button/Button.types';
import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

const generateEmbedLink = (url: string): string | null => {
  let v = null;

  try {
    const urlParams = new URLSearchParams(new URL(url).search);
    v = urlParams.get('v');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (v === null) {
    return v;
  }

  return `https://www.youtube.com/embed/${v}/?autoplay=1`;
};

const DEFAULT_VIDEO = 'https://www.youtube.com/embed/zTTi4cP1h0k/?autoplay=1';
const DEFAULT_PREVIEW = 'img/presentation.jpg';
const DEFAULT_PREVIEW_WHITE_BG = 'img/presentation-white-bg.jpg';

interface Props {
  video?: string;
  preview?: string | undefined;
  className?: string;
  type?: 'text-icon' | 'icon';
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  hasWhiteBg?: boolean;
  ctaText?: string;
}

export const Presentation = ({
  video,
  preview,
  hasWhiteBg,
  className = '',
  type = 'text-icon',
  buttonType = 'secondary',
  buttonSize = 64,
  ctaText = 'Play video',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();

  const [open, setOpen] = useState(false);

  const onOpenPresentation = () => setOpen(true);

  const builtSrc = useMemo(
    () =>
      buildUrl({
        src: preview || (hasWhiteBg ? DEFAULT_PREVIEW_WHITE_BG : DEFAULT_PREVIEW),
        width: 1144,
        height: 630,
      }),
    [buildUrl, hasWhiteBg, preview],
  );

  const videoSrc = useMemo(() => {
    if (video) {
      if (video.match('/embed/') === null) {
        return generateEmbedLink(video);
      }

      return video;
    }

    return DEFAULT_VIDEO;
  }, [video]);

  return (
    <div
      ref={ref}
      className={`presentation presentation-type-${type} ${className}`}
      style={{ backgroundImage: intersected ? `url(${builtSrc})` : undefined }}
    >
      {open ? (
        <iframe width="100%" height="100%" src={videoSrc || DEFAULT_VIDEO} allow="autoplay" />
      ) : (
        <Button type={buttonType} size={buttonSize} onClick={onOpenPresentation} ariaLabel={ctaText}>
          <Icon type="play" /> {type === 'text-icon' ? ctaText : ''}
        </Button>
      )}
    </div>
  );
};
