import { useMemo, useState, useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

import { CustomerFeedbackItem } from '../CustomerFeedbackItem';

interface Customer {
  customers: {
    name?: string;
    description?: string;
    position?: string;
    img: { url?: string };
    id: number;
  }[];
}

export const CustomersFeedback = ({ customers = [] }: Customer) => {
  const { pageVars } = useContext(ContentContext);

  const [counter, setCounter] = useState<number>(0);

  const aboutUsPageVars = useMemo(() => pageVars.about || {}, [pageVars.about]);

  const onNext = () =>
    setCounter((prevCounter) => {
      const newCounter = prevCounter + 1;
      return customers.length !== newCounter ? newCounter : 0;
    });

  const onPrev = () =>
    setCounter((prevCounter) => {
      const newCounter = prevCounter - 1;
      return customers.length !== newCounter ? (counter === 0 ? customers.length - 1 : newCounter) : customers.length;
    });

  return (
    <>
      <h2 className="about-us-title">{aboutUsPageVars.customers_feedback_title}</h2>
      {customers &&
        customers
          .filter((_, key) => key === counter)
          .map((customer, key) => (
            <CustomerFeedbackItem key={key} customer={customer} onChangePrev={onPrev} onChangeNext={onNext} />
          ))}
    </>
  );
};
