import { useContext, useCallback, useMemo } from 'react';

import cn from 'classnames';

import { Product } from 'libs/http/api/content/content.types';
import { useRouter } from 'libs/router/useRouter';
import { extractQueryValue } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { useIsIframe } from 'hooks/useIsIframe';

import { Price } from 'ui/atoms/Price/Price';
import { Button } from 'ui/atoms/Button/Button';

import { SpecialOffer } from 'features/product-pricing/organisms/SpecialOffer/SpecialOffer';
import { PricingCardDescription } from 'features/product-pricing/organisms/PricingCardDescription/PricingCardDescription';

import styles from './PricingCards.module.scss';

export interface Props {
  data: Product[];
  frequency?: string;
  callToActionText: string | undefined;
  callToActionLink: string | undefined;
}

const isCorrectFrequency = (value?: string): value is 'month' | 'year' =>
  value ? ['month', 'year'].includes(value) : false;

export const PricingCards = ({ data, frequency: $frequency, callToActionLink, callToActionText }: Props) => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);
  const { isIframe } = useIsIframe();
  const { query } = useRouter();

  const frequency = useMemo(() => (isCorrectFrequency($frequency) ? $frequency : 'month'), [$frequency]);

  const pricingPageVars = useMemo(() => pageVars.pricing_products || {}, [pageVars.pricing_products]);

  const origin = useMemo(() => {
    if (query.origin === undefined || typeof query.origin === 'string') {
      return query.origin;
    }

    return query.origin[0];
  }, [query]);

  const priceTag = useMemo(() => `price_${frequency || 'month'}` as 'price_month' | 'price_year', [frequency]);

  const onClickAction = useCallback(() => {
    if (callToActionLink) {
      window.open(callToActionLink, '_blank');
    }
  }, [callToActionLink]);

  return (
    <div className={styles['cards-wrapper']}>
      <div className={styles['cards']}>
        {data.map((card, i) => {
          const hasPrice = Boolean(card[priceTag]);
          const buttonType = (card.best_value && 'primary') || 'secondary';

          const link = card[`link${frequency === 'year' ? `_${frequency}` : ''}` as 'link' | 'link_year'];
          const linkButton = card[`link_${frequency || 'month'}_button`];
          const productId = extractQueryValue(link, 'product_id');

          return (
            <div
              {...(hasPrice && { ['data-test']: `pricing-card-${i}` })}
              className={cn(
                styles.card,
                card.best_value ? styles['card-best'] : styles[hasPrice ? 'card-secondary' : 'card-ghost'],
              )}
              key={i}
            >
              <div>
                <div className={styles['card-title']}>
                  <h2>{card.name}</h2>

                  {card.best_value && <SpecialOffer value={pricingPageVars.highlighted_bagde} icon="best-offer" />}
                </div>

                <span className={styles['card-description']}>{card.description}</span>

                {card[priceTag] ? (
                  <Price currency={card.currency} frequency="month" size="lg" value={card[priceTag]} />
                ) : (
                  <p className={styles['price-offer']}>{pricingPageVars.price_offer}</p>
                )}

                {frequency === undefined && (
                  <div className={styles['card-discount']}>
                    <Price currency={card.currency} frequency="month" size="sm" value={card.price_year} />

                    <a href={card.link_year} target="_blank" rel="noreferrer">
                      <SpecialOffer value={pricingPageVars.special_year_offer} />
                    </a>
                  </div>
                )}

                {link ? (
                  <Button
                    size={48}
                    type={buttonType}
                    className={styles['card-next-step']}
                    href={`${link}&origin=${encodeURIComponent(origin || location.href)}`}
                    target="_blank"
                    rel="noreferrer"
                    id={!card.standalone ? `card-next-step-${i}` : `standalone-${i}`}
                  >
                    {linkButton || pricingPageVars.link_button_alternate}
                  </Button>
                ) : (
                  <Button
                    size={48}
                    type={buttonType}
                    className={styles['card-next-step']}
                    route={secondaryNavigation.book_demo.slug}
                    id={!card.standalone ? `card-individual-${i}` : `standalone-${i}`}
                  >
                    {pricingPageVars.cta_card_button}
                  </Button>
                )}

                {/*
                 * card.link_trial verification must be remained
                 * because eventually we use it to redirect customer to the summary page
                 */}
                {card.link_trial && secondaryNavigation.pricing_trial?.slug && (
                  <Button
                    size={32}
                    type="transparent"
                    className={styles['card-link-trial']}
                    route={secondaryNavigation.pricing_trial.slug}
                    query={{
                      product_id: productId,
                      origin: origin || location.href,
                      public_country: isIframe ? query.public_country : undefined,
                    }}
                    id={`product-${i}`}
                  >
                    {card.link_trial_button || pricingPageVars.link_trial_alternate}
                  </Button>
                )}

                <PricingCardDescription
                  currency={card.currency}
                  standalone={card.standalone}
                  frequency={frequency}
                  features={card.features}
                  choices={card.choices}
                  data={card.pricing_feature_groups}
                />
              </div>
            </div>
          );
        })}
      </div>

      {callToActionLink && (
        <Button size={48} type="invert" onClick={onClickAction} className={styles['cards-action']}>
          {callToActionText || pricingPageVars.cta_under_cards_button_alternate}
        </Button>
      )}
    </div>
  );
};
