/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useRef, useCallback } from 'react';

import dynamic from 'next/dynamic';
import cn from 'classnames';

import { Author } from 'libs/http/api/content/content.types';
import { Link } from 'libs/router/Link';
import { clearTimeoutIfExists, scrollToBeVisible } from 'libs/node';

import { useEventListener } from 'hooks/useEventListener';

import { Image } from 'ui/atoms/Image';
import { DynamicImport } from 'ui/DynamicImport';

import { ShareLoader } from 'features/articles/atoms/Share/ShareLoader';

import styles from './BlogSidebar.module.scss';

interface Props {
  route?: string;
  author?: Author;

  articleParts?: {
    title: string;
    id: number;
  }[];
}

const INDENT_NAVIGATION = 150;

export const BlogSidebar = ({ author, route, articleParts = [] }: Props) => {
  const partsContainerRef = useRef<HTMLDivElement>(null);
  const clickedDebounce = useRef(false);
  const scrollThrottle = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let throttle: NodeJS.Timeout;

    const nodeList = document.getElementsByClassName(styles['sidebar-part']);
    if (nodeList && nodeList.length === 0) {
      return;
    }

    Array.from(nodeList).forEach((link) => {
      link.addEventListener('click', (ev: Event) => {
        ev.preventDefault();

        document.querySelectorAll(`.${styles['sidebar-part']}`).forEach((link) => {
          if (link.classList.contains(styles['active'])) {
            link.classList.remove(styles['active']);
          }
        });

        link.classList.add(styles['active']);

        const id = link.getAttribute('data-id');
        link.setAttribute('href', `#${id}`);

        const scrollTarget = document.getElementById(`${id}`);

        if (scrollTarget) {
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition - INDENT_NAVIGATION;

          window.requestAnimationFrame(() => {
            window.scrollBy({
              top: offsetPosition,
              behavior: 'smooth',
            });
          });

          clickedDebounce.current = true;
          throttle = setTimeout(() => (clickedDebounce.current = false), 1000);
        }
      });
    });

    return () => {
      clearTimeoutIfExists(throttle);
      clearTimeoutIfExists(scrollThrottle.current);
    };
  }, []);

  useEventListener(
    'scroll',
    useCallback(() => {
      if (scrollThrottle.current) {
        return;
      }

      scrollThrottle.current = setTimeout(() => {
        window.requestAnimationFrame(() => {
          scrollThrottle.current = undefined;

          const scrollDistance = window.scrollY + INDENT_NAVIGATION;
          const links = document.querySelectorAll(`.${styles['sidebar-part']}`);

          let activeIdx: number | undefined = undefined;

          const elements = document.querySelectorAll('.article-scroll') as NodeListOf<HTMLElement>;

          Array.from(elements).forEach((item, idx) => {
            if (item instanceof HTMLElement) {
              const positionFromTop = item.getBoundingClientRect().top + document.documentElement.scrollTop;

              if (links?.[idx]) {
                if (positionFromTop - 10 <= scrollDistance) {
                  activeIdx = idx;

                  links[idx].classList.add(styles['active']);

                  if (clickedDebounce.current == false && links[idx] && partsContainerRef.current) {
                    scrollToBeVisible(links[idx] as HTMLElement, partsContainerRef.current);
                  }
                }
              }
            }
          });

          /** remove active class from all unactive sections */
          if (typeof links?.forEach === 'function') {
            links.forEach((link, $idx) => {
              if (activeIdx !== $idx) {
                link.classList.remove(styles['active']);
              }
            });
          }
        });
      }, 629);
    }, []),
    undefined,
    {
      passive: true,
    },
  );

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar-sticky']}>
        <Link tabIndex={0} className={styles['sidebar-author']} route={route} query={{ id: author?.id }}>
          {author?.photo && (
            <Image
              className={styles['sidebar-author-image']}
              objectFit="contain"
              width={75}
              height={75}
              src={author.photo.url}
              alt={author.full_name}
            />
          )}

          <div className={styles['sidebar-author-details']}>
            {author?.full_name && <p>{author.full_name}</p>}
            {author?.role && <p>{author.role}</p>}
          </div>
        </Link>

        {articleParts.length > 0 ? (
          <>
            <p className={styles['sidebar-title']}>Table of Contents</p>

            <div className={styles['sidebar-parts']} ref={partsContainerRef}>
              {articleParts.map((part) => (
                <a
                  key={part.id}
                  tabIndex={0}
                  data-id={part.id}
                  href={`#${part.id}`}
                  className={cn(styles['sidebar-part'])}
                >
                  <span>{`${part.id < 10 ? 0 : ''}${part.id}`}</span>
                  <p>{part.title}</p>
                </a>
              ))}
            </div>
          </>
        ) : null}

        <DynamicImport fallback={() => <ShareLoader />}>
          {() => {
            const Component = dynamic(() => import('features/articles/atoms/Share/Share'), {
              ssr: false,
              loading: () => <ShareLoader />,
            });

            return <Component />;
          }}
        </DynamicImport>
      </div>
    </div>
  );
};
