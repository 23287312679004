import { ReactNode, MouseEvent, useEffect, useCallback, forwardRef, ForwardedRef, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import cn from 'classnames';

import { cabinClassNames } from 'font';

import { useEventListener } from 'hooks/useEventListener';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Mask } from 'ui/atoms/Mask/Mask';
import { TagLabel } from 'ui/atoms/TagLabel/TagLabel';

import styles from './Modal.module.scss';

interface Props {
  title?: string;
  description?: string;
  onClose: () => void;
  children: ReactNode;
  closable?: boolean;
  maxWidth?: number;
  padding?: number;
}

export const Modal = forwardRef(
  (
    { title, description, onClose, closable = true, maxWidth = 460, padding = 50, children }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    useEffect(() => {
      /** Disable scrolling on mount */
      document.body.style.overflow = 'hidden';

      /** Re-enable scrolling when the component unmounts */
      return () => {
        document.body.style.overflow = 'visible';
      };
    }, []);

    useEventListener('keydown', (ev: KeyboardEvent) => {
      if (ev.keyCode === 27) {
        onCloseHandler();
      }
    });

    const onCloseHandler = useCallback(() => {
      document.body.style.overflow = 'visible';

      onClose();
    }, [onClose]);

    const stopPropagation = useCallback((e: MouseEvent) => e.stopPropagation(), []);

    const modalOverlay = (
      <>
        <Mask onClick={onCloseHandler} />

        <div
          ref={ref}
          className={cn(styles['modal'], cabinClassNames)}
          style={{ maxWidth: maxWidth, padding }}
          onClick={stopPropagation}
        >
          <div className={styles['modal-header']}>
            {title && <h2 className={styles['modal-title']}>{title}</h2>}

            {closable && (
              <button className={styles['close-button']} onClick={onCloseHandler}>
                <Icon type="close-icon" />
              </button>
            )}
          </div>

          {description && (
            <div className={styles['modal-description']}>
              <TagLabel status="active" text={description} className={styles['modal-tag']} />
            </div>
          )}

          <div className={styles['modal-content']}>{children}</div>
        </div>
      </>
    );

    return createPortal(modalOverlay, document.body) as ReactPortal;
  },
);
