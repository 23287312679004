import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './SpecialOffer.module.scss';

interface OfferProps {
  icon?: string;
  value: string;
}

export const SpecialOffer = (props: OfferProps) => {
  return (
    <div className={styles.wrapper}>
      {props.icon && <Icon type={props.icon} />}

      {props.value}
    </div>
  );
};
