import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  twitter?: string;
  facebook?: string;
  linkedin?: string;
  medium?: string;
}

export const SocialMedia = ({ twitter, facebook, linkedin, medium }: Props) => {
  if (!twitter && !facebook && !linkedin) {
    return null;
  }

  return (
    <div className="contact-us__media">
      {twitter && (
        <a href={twitter} target="_blank" rel="noreferrer" aria-label="Author's Twitter profile">
          <Icon type="twitter" className="icon-twitter" />
        </a>
      )}

      {facebook && (
        <a href={facebook} target="_blank" rel="noreferrer" aria-label="Author's Facebook profile">
          <Icon type="facebook" className="icon-facebook" />
        </a>
      )}

      {linkedin && (
        <a href={linkedin} target="_blank" rel="noreferrer" aria-label="Author's Linkedin profile">
          <Icon type="linkedin" className="icon-linkedin" />
        </a>
      )}

      {medium && (
        <a href={medium} target="_blank" rel="noreferrer" aria-label="Author's Medium profile">
          <Icon type="medium" className="icon-medium" />
        </a>
      )}
    </div>
  );
};
