import { ReactNode, useMemo, useState } from 'react';

import { Info } from 'ui/atoms/Info/Info';

import s from './Tabs.module.scss';

interface TabProps {
  show?: boolean;
  children?: ReactNode;
}

const Tab = ({ show, children }: TabProps) => (
  <div className={`${s['rc-tabs-children']} ${show ? 'show' : 'hide'}`}>{children}</div>
);

interface Props {
  data?: { label: string; className?: string; children: JSX.Element }[];
  className?: string;
}

export const Tabs = ({ data = [], className = '' }: Props) => {
  const [active, setActive] = useState(0);

  const renderTabs = useMemo(
    () =>
      data.map((item, i) => (
        <Tab key={i} show={i === active}>
          {item.children}
        </Tab>
      )),
    [active, data],
  );

  const renderTabsList = useMemo(() => {
    const setActiveHandler = (i: number) => () => setActive(i);

    return data.map((item, i) => (
      <Info
        key={i}
        className={`${item.className || ''} ${i === active ? s['rc-tab-active'] : ''}`}
        title={item.label}
        onClick={setActiveHandler(i)}
      />
    ));
  }, [active]);

  return (
    <div className={`${s['rc-tabs']} ${className}`}>
      <div className={s['rc-tabs-header']}>{renderTabsList}</div>

      {renderTabs}
    </div>
  );
};
