import { useContext, useMemo } from 'react';

import cn from 'classnames';

import { Link } from 'libs/router/Link';

import { ContentContext } from 'context/ContentContext';

import { Image } from 'ui/atoms/Image';

import { CourseDuration } from 'features/academy/atoms/CourseDuration/CourseDuration';

import styles from './CardsPreview.module.scss';

interface Props {
  size?: 'md' | 'lg';
}

export const CardsPreview = ({ size = 'lg' }: Props) => {
  const { additionals } = useContext(ContentContext);

  const academyFaculties = useMemo(() => additionals.academyFaculties?.data || [], [additionals]);

  return (
    <div className={styles['cards-preview-wrapper']}>
      {academyFaculties.map((item, idx) => (
        <Link route={item.slug} className={cn(styles['card-preview'], styles[`card-preview-${size}`])} key={item.slug}>
          <Image
            /** Prioritize first image because it appears in the very first viewport */
            priority={idx === 0}
            width={320}
            height={230}
            src={item.has_academy_faculty.image_promotion.url}
            alt={item.has_academy_faculty.title}
          />

          <h2 className={cn(styles['card-preview-title'], styles[`card-preview-title-${size}`])}>
            {item.has_academy_faculty.title}
          </h2>

          <CourseDuration lessons={item.has_academy_faculty.courses.length} />
        </Link>
      ))}
    </div>
  );
};
