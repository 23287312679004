import { useMemo, useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

export const OurMission = () => {
  const { pageVars } = useContext(ContentContext);

  const aboutUsPageVars = useMemo(() => pageVars.about || {}, [pageVars.about]);

  return (
    <div className="our-mission">
      <div className="our-mission__title">
        <h2>{aboutUsPageVars.our_mission_title}</h2>
        <p>{aboutUsPageVars.our_mission_subtitle}</p>
      </div>

      <div className="our-mission__description-block">
        <p dangerouslySetInnerHTML={{ __html: aboutUsPageVars.our_mission_desc }} />
      </div>
    </div>
  );
};
