import { useCallback, useMemo, useRef } from 'react';

import cn from 'classnames';

import { getTextSize } from 'libs/string';
import { number } from 'libs/number';

import { useIntersection } from 'hooks/useIntersection';
import { useImage } from 'hooks/useImage';

import { TouchTab } from 'ui/atoms/TouchTab/TouchTab';
import { Image } from 'ui/atoms/Image';

import { TouchCreditSearch } from 'features/profile/atoms/TouchCreditSearch/TouchCreditSearch';

import styles from './ProfileDescription.module.scss';

import { Props } from './ProfileDescription.types';

export const ProfileDescription = ({
  className = '',
  titlePosition = 'stats',
  touch,
  hasSearchCreditTouch,
  data,
  type,
  invert,
  alternative,
  image,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();

  const renderStatistics = useCallback(() => {
    const statistics = [
      {
        info: data.averageInfo,
        count: data.average,
      },
      {
        info: data.dataInfo,
        count: data.data,
      },
      {
        info: data.dataTwoInfo,
        count: data.dataTwo,
      },
    ];

    return statistics.map((item) =>
      alternative ? (
        <div className={styles['profile-description-stat']} key={item.info}>
          <div className={cn(styles['profile-description-stat-info'], styles[getTextSize(item.info)])}>{item.info}</div>
          <div className={cn(styles['profile-description-stat-count'], styles[getTextSize(item.count)])}>
            {number(item.count)}
          </div>
        </div>
      ) : (
        <div className={styles['profile-description-stat']} key={item.info}>
          <div className={cn(styles['profile-description-stat-count'], styles[getTextSize(item.count)])}>
            {number(item.count)}
          </div>

          <div className={cn(styles['profile-description-stat-info'], styles[getTextSize(item.info)])}>{item.info}</div>
        </div>
      ),
    );
  }, [data]);

  const buildSrc = useMemo(
    () => buildUrl({ src: `img/rectangles/${type}.jpg`, width: 280, height: 280 }),
    [type, buildUrl],
  );

  const imageProps = useMemo(() => {
    if (image) {
      return {
        src: image.url,
        alt: image.name,
        className: `${touch}-action`,
      };
    }

    if (data.image) {
      return {
        src: data.image?.url,
        alt: data.image?.name,
      };
    }

    return undefined;
  }, []);

  return (
    <div className={cn(styles['profile-description'], styles[className], alternative && styles['alternative'])}>
      {titlePosition === 'out' && data.title && (
        <h2 className={styles['profile-description-wrapper-title']}>{data.title}</h2>
      )}

      <div
        className={cn(
          styles['profile-description-wrapper'],
          invert && styles['invert'],
          styles[`title-position-${titlePosition}`],
        )}
      >
        <div className={styles['profile-description-text']}>
          {titlePosition === 'text' && <h2 className={styles['profile-description-text-title']}>{data.title}</h2>}

          <div dangerouslySetInnerHTML={{ __html: data.description || '' }} />

          {touch && touch !== 'search-alternative' && (
            <TouchTab type={touch} buttonClassName={styles['touchData__button']} />
          )}

          {hasSearchCreditTouch && <TouchCreditSearch />}
        </div>

        {touch === 'search-alternative' && imageProps !== undefined ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Image width={570} height={390} {...imageProps} />
        ) : (
          <div className={cn(styles['profile-description-stats-wrapper'], alternative && styles['alternative'])}>
            {titlePosition === 'stats' && <h2 className={styles['profile-description-stats-title']}>{data.title}</h2>}

            <div
              ref={ref}
              className={cn(styles['profile-description-stats'], type && styles[type])}
              style={{ backgroundImage: intersected ? `url(${buildSrc})` : undefined }}
            >
              {renderStatistics()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
