import { useEffect, useState } from 'react';

import { WebTechnology } from 'libs/http/api/content/content.types';

import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  widget: {
    title: string;
    content: string[];
    open?: boolean;
    id: string;
  };
  technologies?: WebTechnology[];
}

export const WebTechWidgetItem = ({ widget, technologies }: Props) => {
  const [active, setActive] = useState<boolean>(false);
  const [child, setChild] = useState<string[]>([]);

  useEffect(() => {
    if (widget.id && Array.isArray(technologies)) {
      setChild(technologies.filter((item) => item.parent === widget.id).map((item) => item.title));
    }
  }, [technologies, widget]);

  const onCheck = () => setActive((s) => !s);

  return (
    <div className={`web-tech-widget__wrapper ${active ? 'web-tech-widget__wrapper-active' : ''}`}>
      <div className={`web-tech-widget__content has-${child.length === 0 ? 'no-' : ''}children `} onClick={onCheck}>
        <div className="web-tech-widget__title">
          <h4>{widget.title}</h4>

          <span>{child.length}</span>
        </div>

        <div className="web-tech-widget__icon">
          <Icon type={active ? 'arrow-burn' : 'arrow-right'} />
        </div>
      </div>

      <div className="web-tech-widget__collapse-wrapper">
        {active
          ? child.map((item, key) => (
              <div className="web-tech-widget__collapse" key={key}>
                <span>{key + 1}</span>
                <span>{item}</span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
