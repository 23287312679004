/* eslint react-hooks/exhaustive-deps: "warn" */

import { useCallback, useMemo } from 'react';

import { isSvg, isVideo } from 'libs/string';

export interface BuildUrl {
  src?: string | { url: string };
  width: number;
  height: number;
}

export const useImage = () => {
  const buildUrl = useCallback(({ src, width, height }: BuildUrl) => {
    let url = (typeof src === 'string' ? src : src?.url) || '';

    if (url === '') {
      return;
    }

    url = url.match('https://') ? url : `${process.env.HOST || ''}/storage/${url}`;

    if ((process.env.DEBUG_IMAGES !== 'true' && process.env.NODE_ENV !== 'production') || isSvg(url) || isVideo(url)) {
      /** Bypass any optimizations in DEV mode or for SVGs and Videos */
      return url;
    }

    return `/api/image-serving/${width}/${height}?url=${url}`;
  }, []);

  return useMemo(() => ({ buildUrl }), [buildUrl]);
};
