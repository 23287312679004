import { Icon } from 'ui/atoms/Icon/Icon';

interface InfoPromotionProps {
  title: string;
  descriptionItem: string[];
}

export const InfoBlockPromotion = ({ title, descriptionItem = [] }: InfoPromotionProps) => {
  return (
    <div className="info-block-promotion">
      {title && <h2>{title}</h2>}

      {descriptionItem
        .filter((i) => i)
        .map((description) => (
          <div key={description} className="description">
            <Icon type="check-mark" className="icon-check" />
            <span>{description}</span>
          </div>
        ))}
    </div>
  );
};
