import { useContext, useMemo } from 'react';

import { ContentContext } from 'context/ContentContext';

import { PlanCollapseContent } from 'ui/atoms/PlanCollapseContent/PlanCollapseContent';

export const PlanCollapse = () => {
  const { pageVars } = useContext(ContentContext);

  const pricingPageVars = useMemo(() => pageVars.pricing || {}, [pageVars.pricing]);

  const [dataFirstBlock, dataSecondBlock] = useMemo(
    () => [
      [
        pricingPageVars.content_text_1,
        pricingPageVars.content_text_2,
        pricingPageVars.content_text_3,
        pricingPageVars.content_text_4,
      ],
      [
        pricingPageVars.second_content_text_1,
        pricingPageVars.second_content_text_2,
        pricingPageVars.second_content_text_3,
        pricingPageVars.second_content_text_4,
      ],
    ],
    [pricingPageVars],
  );

  return (
    <>
      <h2>{pricingPageVars.online_platform_title}</h2>

      <p>{pricingPageVars.online_platform_description}</p>

      <PlanCollapseContent item={dataFirstBlock} title={pricingPageVars.info_title_1} />

      <PlanCollapseContent item={dataSecondBlock} title={pricingPageVars.info_title_2} />
    </>
  );
};
