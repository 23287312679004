import { useContext, useMemo } from 'react';

import { ContentContext } from 'context/ContentContext';

import Cards from 'features/home/atoms/Cards/Cards';

import styles from './CardsCustomer.module.scss';

export interface PropsCardData {
  title: string;
  description: string;
  buttonText?: string;
  slug?: string;
  iconType?: string;
}

interface Props {
  title?: string;
  data?: PropsCardData[];
}

export const CardsCustomer = ({ title, data = [] }: Props) => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const homePageVars = useMemo(() => pageVars.home || {}, [pageVars.home]);
  const [hasData, cardData] = useMemo(() => {
    const $hasData = [1, 2, 3].map((k) => homePageVars[`title_${k}`] || homePageVars[`description_${k}`]);

    let $cardData;

    /** temporary solution until pageVar is ready */
    data?.length > 0
      ? ($cardData = data)
      : ($cardData = [
          {
            iconType: 'rectangles-one',
            title: homePageVars.title_1,
            description: homePageVars.description_1,
            slug: secondaryNavigation.marketing.slug,
            buttonText: homePageVars.button_text_1,
          },
          {
            iconType: 'rectangles-two',
            title: homePageVars.title_2,
            description: homePageVars.description_2,
            slug: secondaryNavigation.credits.slug,
            buttonText: homePageVars.button_text_2,
          },
          {
            iconType: 'rectangles-three',
            title: homePageVars.title_3,
            description: homePageVars.description_3,
            slug: secondaryNavigation.api?.slug,
            buttonText: homePageVars.button_text_3,
          },
        ]);

    return [$hasData, $cardData];
  }, [homePageVars]);

  return (
    <div className={styles['cards-customer']}>
      {title && <h2 className={styles['cards-customer-title']}>{title}</h2>}

      <Cards cardData={cardData} hasData={hasData} />
    </div>
  );
};
