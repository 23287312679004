import { useEffect, useState } from 'react';

import { content } from 'libs/http/api/content/content';
import { Product } from 'libs/http/api/content/content.types';
import { external } from 'libs/http/api/external/external';
import { useRouter } from 'libs/router/useRouter';

interface Response {
  data: Product[] | null;
}

export const useProducts = (redirect: () => Promise<boolean>) => {
  const {
    query: {
      lang,
      public_country: publicCountry,
      public_title: publicTitle,
      public_has_frequency_switcher: publicHasFrequencySwitcher = true,
      public_video: publicVideo,
      public_cta_text: publicCtaText,
      public_cta_link: publicCtaLink,
      public_presentation_id: publicPresentationId,
    },
  } = useRouter();

  const [data, setData] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (typeof publicCountry === 'string') {
        const { data } = await fetchProducts(publicCountry, lang, redirect);

        if (data) {
          setData(data);
          setLoading(false);
        }
        return;
      }

      let response;
      try {
        ({ data: response } = await external.getIp());
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        return redirect();
      }

      if (response?.country_code2 !== undefined) {
        const { data } = await fetchProducts(response.country_code2.toLowerCase(), lang, redirect);

        if (data) {
          setData(data);
          setLoading(false);
        }
      }
    })();
  }, []);

  const fetchProducts = async (
    country: string,
    lang: string | string[] | undefined,
    redirect: () => Promise<boolean>,
  ): Promise<Response> => {
    const options = { lang: `${lang}`, country };

    let mapToResponse = [];
    try {
      ({ data: mapToResponse } = await content.productMapTo(options));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      redirect();
    }

    if (mapToResponse.length === 0) {
      /** redirect if there is no country to map */
      redirect();
    }

    try {
      /** Get mapped to country or keep current if it was mapped properly */
      const finalCountry = mapToResponse[0]?.map_properly ? country : mapToResponse[0]?.map_to;

      const { data: dataResponse } = await content.products({ ...options, country: finalCountry });

      if (dataResponse.length === 0) {
        /** redirect if there are no products */
        redirect();
      }

      return { data: dataResponse };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      redirect();
    }

    return { data: null };
  };

  return {
    data,
    setData,
    loading,
    setLoading,

    iframeData: {
      title: publicTitle,
      country: publicCountry,
      hasFrequencySwitcher: publicHasFrequencySwitcher !== 'false',
      video: typeof publicVideo === 'string' ? publicVideo : undefined,
      ctaText: typeof publicCtaText === 'string' ? publicCtaText : undefined,
      ctaLink: typeof publicCtaLink === 'string' ? publicCtaLink : undefined,
      presentationId: typeof publicPresentationId === 'string' ? publicPresentationId : undefined,
    },
  };
};
