import { useEffect, useRef } from 'react';

import { useIntersection } from 'hooks/useIntersection';

type Props = {
  children: () => JSX.Element;
  fallback: () => JSX.Element;
};

export const DynamicImport = ({ children, fallback: Fallback }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const height = useRef<number>();

  useEffect(() => {
    height.current = ref.current?.getBoundingClientRect().height;
  }, []);

  if (intersected === false) {
    return (
      <div ref={ref}>
        <Fallback />
      </div>
    );
  }

  return <div style={{ minHeight: height.current, width: '100%' }}>{children()}</div>;
};
