import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  className?: string;
  title: string;
  description: string;
  type: string;
}

export const LocationBlock = ({ className = '', title, description, type }: Props) => (
  <div className="plan-location">
    <div className={className}>
      <div className="icon">
        <Icon type={type} />
      </div>

      <div className="rectangle-icon" />
      <div className="plan-location__block">
        <span>{title}</span>

        <p className="plan-location__description">{description}</p>
      </div>
    </div>
  </div>
);
