import { useContext } from 'react';

import dynamic from 'next/dynamic';

import { config } from 'config';

import { ContentContext } from 'context/ContentContext';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

import { Topics } from 'features/articles/atoms/Topics/Topics';
import { Benefits } from 'features/articles/organisms/Benefits/Benefits';
import { PeopleFeedbackLoader } from 'features/customers/organisms/PeopleFeedback/PeopleFeedbackLoader';

const CustomersPage = () => {
  const { secondaryNavigation, data: content } = useContext(ContentContext);

  return (
    <div className="customers-page">
      <InfoBlock
        description={content.description}
        title={content.title}
        buttonText={content.button_text}
        buttonHref={content.button_href}
        type="customers-page"
      />

      <Topics type="case_studies" />

      {secondaryNavigation.customers?.slug && (
        <Benefits
          withBlog
          hasPriortyFirstImage
          limit={config.articles.regularPerPage}
          route={secondaryNavigation.customers.slug}
        />
      )}

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      <DynamicImport fallback={() => <PeopleFeedbackLoader />}>
        {() => {
          const Component = dynamic(() => import('features/customers/organisms/PeopleFeedback/PeopleFeedback'), {
            ssr: false,
            loading: () => <PeopleFeedbackLoader />,
          });

          return <Component />;
        }}
      </DynamicImport>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CustomersPage;
