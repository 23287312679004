import { useMemo, useState, useCallback, useRef, useEffect } from 'react';

import dynamic from 'next/dynamic';
import cn from 'classnames';

import { clearTimeoutIfExists, toggleBodyScroll } from 'libs/node';

import { useIntersection } from 'hooks/useIntersection';

import styles from './Burger.module.scss';

import { MenuInterface } from '../Header/Header.types';
import { BurgerMenuLoading } from '../BurgerMenu/BurgerMenuLoading';

const burgerId = 'burger';

const minimumObservableMenuStyles = {
  minWidth: 1,
  minHeight: 1,
};

interface Props {
  className?: string;
  options: MenuInterface[];
}

export const Burger = ({ className, options }: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const menuIntersected = useIntersection(menuWrapperRef);

  const loadingDelay = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(loadingDelay.current);
    };
  }, []);

  const onOpen = useCallback(() => {
    toggleBodyScroll(true);

    setLoading(true);

    loadingDelay.current = setTimeout(() => setLoading(false), 3000);
  }, []);

  const onClose = useCallback(() => {
    clearTimeoutIfExists(loadingDelay.current);
    setLoading(false);

    toggleBodyScroll(false);

    if (checkboxRef.current !== null) {
      checkboxRef.current.checked = false;
    }
  }, []);

  const renderCheckbox = useMemo(() => <input ref={checkboxRef} id={burgerId} type="checkbox" />, []);

  const renderMenu = useMemo(() => {
    if (!menuIntersected) {
      return null;
    }

    const Component = dynamic(() => import('../BurgerMenu/BurgerMenu').then((module) => module.BurgerMenu), {
      ssr: false,
      loading: () => <BurgerMenuLoading />,
    });

    return <Component onClose={onClose} options={options} />;
  }, [menuIntersected]);

  return (
    <div className={cn(styles['burger-wrapper'], className)}>
      {renderCheckbox}

      <label
        onClick={onOpen}
        htmlFor={burgerId}
        className={cn(styles['burger-btn-wrapper'], loading && styles['loading'])}
        aria-label="Menu"
      >
        <span className={styles['burger-btn-line']}></span>
      </label>

      <div ref={menuWrapperRef} style={minimumObservableMenuStyles} className={styles['burger-menu-wrapper']}>
        {renderMenu}
      </div>
    </div>
  );
};
