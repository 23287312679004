import { ReactNode } from 'react';

import cn from 'classnames';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

interface Props {
  className?: string;
  height?: number | string;
  loading?: boolean;
  children?: ReactNode;
}

export const Loader = ({ className = '', height, loading, children }: Props) => {
  return (
    <>
      {loading && (
        <div data-id="loader" className={cn('gdb-loader', className)} style={{ height }}>
          <LoaderSpin />
        </div>
      )}

      <div className={cn('fade-in', loading && 'hide')}>{children}</div>
    </>
  );
};
