import { useContext, useMemo } from 'react';

import { Product } from 'libs/http/api/content/content.types';
import { extractQueryValue } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Button } from 'ui/atoms/Button/Button';
import { Presentation } from 'ui/organisms/Presentation/Presentation';

interface Advantage {
  /** More advantage properties might come */
  label: string;
}

interface Props {
  size?: 'md' | 'lg';
  hasFreeTrial?: boolean;
  hasTitle?: boolean;
  hasDescription?: boolean;
  title?: string;
  product?: Product[];
  description?: string;
  hasWhiteBg?: boolean;
  advantages?: Advantage[];
}

export const VideoBlock = ({
  size = 'md',
  hasFreeTrial = false,
  hasTitle = true,
  hasDescription = true,
  hasWhiteBg,
  title,
  product = [],
  description,
  advantages = [],
}: Props) => {
  const { secondaryNavigation, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const productId = product?.length > 0 ? extractQueryValue(product[0].link, 'product_id') : undefined;

  return (
    <div
      className={`video-container-wrapper video-container-${size}`}
      style={advantages?.length !== 0 ? { margin: '77px auto' } : undefined}
    >
      <div className="video-container">
        <div className="video-container__info">
          {hasTitle && <h3>{title || commonPageVars.presentation_title}</h3>}

          {hasDescription && <span>{description || commonPageVars.presentation_description}</span>}

          {advantages.length > 0 && (
            <div className="video-container__advantages">
              {advantages?.map((advantage) => (
                <div className="video-container__inner" key={advantage.label}>
                  <Icon type="check-true" />
                  <span>{advantage.label}</span>
                </div>
              ))}
            </div>
          )}

          {hasFreeTrial && secondaryNavigation.pricing_trial?.slug && (
            <Button
              size={64}
              id="video-block-btn"
              className="video-container__button"
              route={secondaryNavigation.pricing_trial.slug}
              query={{ product_id: productId }}
            >
              Start a 7-day test drive
            </Button>
          )}
        </div>

        <div className="video-container__video">
          <Presentation hasWhiteBg={hasWhiteBg} type="text-icon" />
        </div>
      </div>
    </div>
  );
};
