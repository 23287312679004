import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { OurCustomers } from 'ui/molecules/OurCustomers/OurCustomers';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { VideoBlock } from 'ui/molecules/VideoBlock/VideoBlock';
import { DynamicImport } from 'ui/DynamicImport';

import { FormBook } from 'features/customers/organisms/FormBook/FormBook';
import { InfoBlockPromotion } from 'features/profile/atoms/InfoBlockPromotion/InfoBlockPromotion';
import { SolutionDescription } from 'features/profile/organisms/SolutionDescription/SolutionDescription';
import { ProfileDescription } from 'features/profile/organisms/ProfileDescription/ProfileDescription';
import { Data as ProfileDescriptionData } from 'features/profile/organisms/ProfileDescription/ProfileDescription.types';
import { PeopleFeedbackLoader } from 'features/customers/organisms/PeopleFeedback/PeopleFeedbackLoader';

const CountryPromotion = () => {
  const { data: content, pageVars } = useContext(ContentContext);

  const countryPromotionPagevars = useMemo(() => pageVars.country_promotion || {}, [pageVars.country_promotion]);

  const [dataInfoBlock, solutionStatistics] = useMemo(
    () => [
      [
        countryPromotionPagevars.head_text_1,
        countryPromotionPagevars.head_text_2,
        countryPromotionPagevars.head_text_3,
      ],
      {
        titleOne: countryPromotionPagevars.solution_title_1,
        titleTwo: countryPromotionPagevars.solution_title_2,
        titleThree: countryPromotionPagevars.solution_title_3,
        dataOne: countryPromotionPagevars.solution_data_1,
        dataTwo: countryPromotionPagevars.solution_data_2,
        dataThree: countryPromotionPagevars.solution_data_3,
      },
    ],
    [countryPromotionPagevars],
  );

  const [industry, emailDatabase] = useMemo(
    (): ProfileDescriptionData[] =>
      content.has_country_promotion !== undefined
        ? [
            {
              title: content.has_country_promotion.first_subtitle,
              description: content.has_country_promotion.first_text,
              average: content.has_country_promotion.content_item_first_numbers_1,
              averageInfo: content.has_country_promotion.content_item_first_numbers_sub_1,
              data: content.has_country_promotion.content_item_first_numbers_3,
              dataInfo: content.has_country_promotion.content_item_first_numbers_sub_3,
              dataTwo: content.has_country_promotion.content_item_first_numbers_2,
              dataTwoInfo: content.has_country_promotion.content_item_first_numbers_sub_2,
            },
            {
              title: content.has_country_promotion.second_subtitle,
              description: content.has_country_promotion.second_text,
              average: content.has_country_promotion.content_item_second_numbers_1,
              averageInfo: content.has_country_promotion.content_item_second_numbers_sub_1,
              data: content.has_country_promotion.content_item_second_numbers_3,
              dataInfo: content.has_country_promotion.content_item_second_numbers_sub_3,
              dataTwo: content.has_country_promotion.content_item_second_numbers_2,
              dataTwoInfo: content.has_country_promotion.content_item_second_numbers_sub_2,
            },
          ]
        : [],
    [content],
  );

  return (
    <div className="country-promotion">
      <div className="info-wrapper">
        <InfoBlockPromotion title={countryPromotionPagevars.headline} descriptionItem={dataInfoBlock} />

        <FormBook isCountryPromotion title={countryPromotionPagevars.request_a_quote} type="quote" />
      </div>

      <div className="clients">
        <InfoBlock title={countryPromotionPagevars.client_title} description={countryPromotionPagevars.client_text} />

        <OurCustomers />
      </div>

      <div className="gdb-solutions">
        <InfoBlock title={countryPromotionPagevars.solution_title} />

        <SolutionDescription data={solutionStatistics} />
      </div>

      <ProfileDescription
        invert
        alternative
        data={industry}
        titlePosition="text"
        className="industry-promotion"
        type="white-blue-white-alternative"
      />

      <div className="content-grey-bg">
        <DynamicImport fallback={() => <RequestDemoLoader />}>
          {() => {
            const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
              ssr: false,
              loading: () => <RequestDemoLoader />,
            });

            return (
              <Component hasCountryPromotion title={content.book_demo_title} button={content.book_demo_button_1_2} />
            );
          }}
        </DynamicImport>

        <ProfileDescription
          alternative
          data={emailDatabase}
          titlePosition="text"
          className="email-promotion"
          type="grey-white-blue-alternative"
        />

        <DynamicImport fallback={() => <PeopleFeedbackLoader />}>
          {() => {
            const Component = dynamic(() => import('features/customers/organisms/PeopleFeedback/PeopleFeedback'), {
              ssr: false,
              loading: () => <PeopleFeedbackLoader />,
            });

            return <Component title={countryPromotionPagevars.feedback_title} />;
          }}
        </DynamicImport>
      </div>

      <VideoBlock
        hasWhiteBg
        title={countryPromotionPagevars.presentation_title}
        description={countryPromotionPagevars.presentation_description}
      />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title} button={content.book_demo_button_1_2} />;
        }}
      </DynamicImport>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CountryPromotion;
