import { HeroText } from 'ui/atoms/HeroText/HeroText';
import { CardsPreview } from 'ui/atoms/CardsPreview/CardsPreview';

import styles from './LearnBlock.module.scss';

export const LearnBlock = () => {
  return (
    <div className={styles['learn-block-wrapper']}>
      <HeroText size="lg">Learn.</HeroText>

      <p className={styles['learn-block-description']}>
        Tutorials, videos, examples, and articles to get started with Global Database.
      </p>

      <CardsPreview />
    </div>
  );
};
