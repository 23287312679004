import { useMemo } from 'react';

import type { AcademyCourse } from 'libs/http/api/content/content.types';
import { keys } from 'libs/object/object';
import { percentage } from 'libs/number';

import { CourseStage } from 'features/academy/atoms/CourseStage/CourseStage';
import { ProgressBar } from 'features/academy/atoms/ProgressBar/ProgressBar';

import styles from './CourseCompletion.module.scss';

interface Props {
  hasVideo?: boolean;
  onChange: (value: number) => void;
  progression: Record<number, number>;
  active: AcademyCourse;
  data: AcademyCourse[];
}

export const CourseCompletion = ({ hasVideo = true, onChange, progression, data, active }: Props) => {
  const concatedPercentages = useMemo(
    () => keys(progression).reduce((acc, current) => (acc += progression[current]), 0),
    [progression],
  );

  const progress = useMemo(() => {
    if (!hasVideo) {
      return 0;
    }

    return percentage(
      concatedPercentages,
      data.reduce((acc) => (acc += 100), 0),
    );
  }, [hasVideo, concatedPercentages, data]);

  return (
    <div className={styles['course-completion-wrapper']}>
      <h3>Course completion</h3>

      {hasVideo && (
        <ProgressBar rate={progress.toFixed(0)} viewed={Math.ceil(concatedPercentages / 100)} total={data.length} />
      )}

      <CourseStage hasVideo={hasVideo} onChange={onChange} active={active} data={data} progression={progression} />
    </div>
  );
};
