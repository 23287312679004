import { useState, useEffect, useRef } from 'react';

import type { ScoreData, DataList, ListDataUpload } from 'libs/http/api/dashboard/dashboard.types';
import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { clearTimeoutIfExists } from 'libs/node';

import { useStateHandlers } from 'hooks/useStateHandlers';
import { useRequest } from 'hooks/useRequest';

import { Scores } from 'ui/molecules/Scores/Scores';
import { SmoothLoading } from 'ui/atoms/SmoothLoading/SmoothLoading';
import { Button } from 'ui/atoms/Button/Button';

import styles from './CompaniesDataTemplate.module.scss';

import { CompaniesTable } from '../../organisms/CompaniesTable/CompaniesTable';

interface Props {
  sessionKey: string;
  onRefresh: () => void;
}

const initialScoreData = { loading: true, data: { total: 0, found: 0, not_found: 0, processed: 0 } as ScoreData };

export const CompaniesDataTemplate = ({ sessionKey, onRefresh }: Props) => {
  const REQUEST_COUNT = useRef(0);

  const { query } = useRouter();
  const { request: requestScore, data: dataScore, loading: loadingScore } = useRequest<ScoreData>(initialScoreData);
  const loadingTimer = useRef<NodeJS.Timeout>();
  const fetchScoreTimeout = useRef<NodeJS.Timeout>();
  const fetchDataTimeout = useRef<NodeJS.Timeout>();

  const [error, setError] = useState(false);
  const [state, setState] = useStateHandlers({
    data: [] as DataList[],
    upload: {} as ListDataUpload,
    loading: true,
  });

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(fetchScoreTimeout.current);
      clearTimeoutIfExists(fetchDataTimeout.current);
      clearTimeoutIfExists(loadingTimer.current);
    };
  }, []);

  useEffect(() => {
    if (sessionKey) {
      /** TODO: Need a cancel request here, but temporary the function is not provided */
      clearTimeoutIfExists(fetchScoreTimeout.current);

      fetchScoreTimeout.current = setTimeout(() => {
        requestScore(dashboard.score({ session_key: sessionKey, lang: `${query.lang}` }));
      }, 1000);
    }
  }, [sessionKey, state.data]);

  useEffect(() => {
    const onFetch = async () => {
      REQUEST_COUNT.current += 1;

      if (REQUEST_COUNT.current > 200) {
        /** Throw to error view as it user already passed 200 requests */
        setError(true);
        return;
      }

      if (Boolean(sessionKey) === false) {
        return;
      }

      try {
        /** TODO: Need a cancel request here, but temporary the function is not provided */
        const { data } = await dashboard.firstList({ session_key: sessionKey, lang: `${query.lang}` });

        setState({ upload: data.upload, data: data.data });
        const status = data.upload?.status;

        if (status === 'finish') {
          setState({ loading: false });
        }

        if (status !== 'finish') {
          /** Re-request data till status is finish, but maximum 250 times */

          clearTimeoutIfExists(fetchDataTimeout.current);

          fetchDataTimeout.current = setTimeout(() => {
            onFetch();
          }, 1000);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        setError(true);
      }
    };

    onFetch();
  }, [sessionKey]);

  if (error) {
    return (
      <div className={styles['companies-data-error']}>
        <h2>We apologize</h2>
        <p>An error occurred during enrichment your data.</p>

        <Button size={64} onClick={onRefresh}>
          Reupload
        </Button>
      </div>
    );
  }

  return (
    <div className={styles['companies-data']}>
      <Scores data={dataScore} loading={loadingScore} />

      <SmoothLoading hasSpinner spinnerType="loader-border" loading={state.loading && state.data.length === 0}>
        <CompaniesTable data={state.data} upload={state.upload} loading={state.loading} />
      </SmoothLoading>
    </div>
  );
};
