import styles from './ProgressBar.module.scss';

interface Props {
  rate: number | string;
  viewed?: number;
  total?: number;
}

export const ProgressBar = ({ rate, viewed = 0, total = 0 }: Props) => {
  return (
    <div className={styles['progressbar-wrapper']}>
      <div className={styles['progressbar-completed']}>
        <span>{rate}% completed</span>
        <span>{`${viewed}/${total}`}</span>
      </div>

      <div className={styles.progressbar}>
        <div className={styles['progressbar-inner']} style={{ transform: `translate3D(${rate}%, 0, 0)` }} />
      </div>
    </div>
  );
};
