import { useMemo } from 'react';

interface Data {
  titleOne?: string;
  titleTwo?: string;
  titleThree?: string;
  dataOne?: string;
  dataTwo?: string;
  dataThree?: string;
}

interface Props {
  data: Data;
}

export const SolutionDescription = ({ data }: Props) => {
  const solutions = useMemo(
    () =>
      [
        {
          title: data.titleOne,
          count: data.dataOne,
        },
        {
          title: data.titleTwo,
          count: data.dataTwo,
        },
        {
          title: data.titleThree,
          count: data.dataThree,
        },
      ].filter((i) => i.title || i.count),
    [data],
  );

  const renderSolution = useMemo(() => {
    return solutions.map(({ title, count }) => (
      <div key={title} className="solution-description-stat">
        <div className="solution-description-title">{title}</div>
        <div className="solution-description-count">{count}</div>
      </div>
    ));
  }, [solutions]);

  if (solutions.length === 0) {
    return null;
  }

  return <div className="solution-description">{renderSolution}</div>;
};
