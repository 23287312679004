/* eslint react-hooks/exhaustive-deps: "warn" */

import { useContext, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';

import { LeadContext } from 'context/LeadContext';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

import { FormHubspotStringCss } from 'features/customers/organisms/FormHubspot/FormHubspotStringCss';

import styles from './FormHubspot.module.scss';

type SubmittedData = {
  redirectUrl: string;
  submissionValues: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
  };
};

type HubspotType = {
  forms: {
    create: (options: {
      region: string;
      portalId: string;
      formId: string;
      target: string;
      css?: string;
      onFormReady: () => void;
      onFormSubmitted?: (form: unknown, data: SubmittedData) => void;
      redirectUrl?: string;
      inlineMessage?: string;
    }) => void;
  };
};

export type FormHubspotProps = {
  data: Record<string, string> & { default: string };
  redirectUrl?: string;
  onFormSubmitted?: () => void;
};

export const FormHubspot = ({ data, redirectUrl, onFormSubmitted }: FormHubspotProps) => {
  const { query } = useRouter();
  const { setLeadData } = useContext(LeadContext);
  const hbsptRef = useRef<HubspotType | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeForm = () => {
      if (!hbsptRef.current) {
        return;
      }

      const onFormSubmittedHandler = onFormSubmitted
        ? (_: unknown, data: SubmittedData) => {
            if (!data.submissionValues) {
              return;
            }

            setLeadData({
              full_name: [data.submissionValues.firstname, data.submissionValues.lastname].filter((i) => i).join(' '),
              email: data.submissionValues.email,
              phone: data.submissionValues.phone,
              message: '',
            });

            onFormSubmitted();
          }
        : undefined;

      const lang = query.lang as string;
      const formId = lang in data ? data[lang] : data.default;

      hbsptRef.current.forms.create({
        region: 'eu1',
        portalId: '144159233',
        formId,
        target: '#hubspotForm',
        css: FormHubspotStringCss,
        onFormReady: () => {
          setLoading(false);
        },
        onFormSubmitted: onFormSubmittedHandler,
        redirectUrl: onFormSubmittedHandler ? undefined : redirectUrl,
        inlineMessage: onFormSubmittedHandler ? 'Thank you for submission. Loading next step ...' : undefined,
      });
    };

    if (!hbsptRef.current) {
      const script = document.createElement('script');

      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
      script.async = true;
      script.onload = () => {
        hbsptRef.current = (window as unknown as { hbspt?: HubspotType }).hbspt ?? null;
        initializeForm();
      };

      document.body.appendChild(script);
    } else {
      initializeForm();
    }

    return () => {
      const formWrapper = document.getElementById('hubspotForm');

      if (formWrapper) {
        formWrapper.innerHTML = '';
      }
    };
  }, [data, onFormSubmitted, query.lang, redirectUrl, setLeadData]);

  return (
    <>
      <div className={cn(styles['hubspot-form-loading'], !loading && styles['hidden'])}>
        <LoaderSpin />
      </div>

      <div className={cn(styles['hubspot-form-wrapper'], loading && styles['hidden'])}>
        <div id="hubspotForm" className={styles['hubspot-form']} />
      </div>
    </>
  );
};
