import { Icon } from 'ui/atoms/Icon/Icon';

import s from './DataIntro.module.scss';

type Props = {
  title: string;
  data: string[];
};

export const DataIntro = ({ title, data }: Props) => {
  return (
    <div className={s['intro-block']}>
      <div className={s['intro-block__title']}>
        <h2>{title}</h2>
      </div>

      <div className={s['intro-block__text']}>
        <ul className={s['text-content']}>
          {data.map((item, key) => (
            <li key={key} className={s['text-content__item']}>
              <Icon className={s['icon-square']} type="blue-square" />
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
