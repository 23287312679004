import { Image } from 'ui/atoms/Image';
import { Icon } from 'ui/atoms/Icon/Icon';

export const CustomerFeedbackItem = ({ customer, onChangePrev, onChangeNext }: any) => {
  return (
    <div className="customers-feedback">
      <div className="customers-feedback__left">
        <Image width={136} height={136} src={customer.image.url} alt={customer.name} />
        <div className="customers-feedback__rectangles-left">
          <Icon type="about-us-left" />
        </div>
      </div>

      <div className="customers-feedback__right">
        <span className="customers-description">{customer.description}</span>

        <div className="customers-description-hr" />

        <h4>{customer.profession}</h4>

        <span>{customer.profession_description}</span>

        <div className="customers-feedback__buttons">
          <Icon type="prev" className="icon-prev" onClick={onChangePrev} />

          <Icon type="next" className="icon-next" onClick={onChangeNext} />
        </div>

        <div className="customers-feedback__rectangles-right">
          <Icon type="about-us-right" />
        </div>
      </div>
    </div>
  );
};
