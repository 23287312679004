import { useContext, useMemo } from 'react';

import cn from 'classnames';

import { Link } from 'libs/router/Link';

import { ContentContext } from 'context/ContentContext';

import { Image } from 'ui/atoms/Image';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

interface BlogItemProps {
  className?: string;
  img?: string;
  title?: string;
  type?: string;
  caseStudyType?: string;
  description?: string;
  readTime?: string;
  slug?: string;
}

export const BlogItem = ({
  className,
  title,
  img,
  type,
  caseStudyType,
  description,
  readTime,
  slug,
}: BlogItemProps) => {
  const { pageVars, secondaryNavigation } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  return (
    <div className={cn('advantages__blocks', className)}>
      {img && (
        <Link className="advantages__blocks-image-container" route={slug || ''} ariaLabel={`Article page: ${title}`}>
          {(loading) => (
            <>
              <Image width={384} height={288} className="advantages__blocks-image" src={img} alt={title} />

              <LoaderSpin className="advantages__blocks-loading" color="white" size="small" loading={loading} />
            </>
          )}
        </Link>
      )}

      <div className="advantages__blocks-content">
        {type || caseStudyType ? (
          <span className="advantages__blocks-category">
            {caseStudyType ? (
              <Link tabIndex={0} route={secondaryNavigation.customers.slug} query={{ type: caseStudyType, paage: 1 }}>
                {commonPageVars[`case_studies_${caseStudyType}`]}
              </Link>
            ) : (
              <Link
                tabIndex={0}
                route={secondaryNavigation.blog.slug}
                query={{ type: type !== 'all_topics' ? type : undefined, paage: 1 }}
              >
                {commonPageVars[`blog_${type}`]}
              </Link>
            )}

            {readTime && (
              <span>
                {readTime} {commonPageVars.min_read}
              </span>
            )}
          </span>
        ) : null}

        <h2 className="advantages__blocks-title">
          <Link tabIndex={0} route={slug || ''}>
            {title}
          </Link>
        </h2>

        {description && (
          <div className="advantages__blocks-description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
};
