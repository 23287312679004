import { ReactNode, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './Tooltip.module.scss';

interface Props {
  label: string | number | null;
  children: ReactNode;
}

export const Tooltip = ({ label, children }: Props) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  useEffect(() => {
    if (isVisible && targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const centerX = targetRect.left + targetRect.width / 2;

      const left = centerX - tooltipRect.width / 2;
      const top = targetRect.top - tooltipRect.height - 10;

      setPosition({
        top: top < 0 ? 0 : top,
        left: left < 0 ? 0 : left,
      });
    }
  }, [isVisible]);

  const showTooltip = () => setIsVisible(true);

  const hideTooltip = () => setIsVisible(false);

  return (
    <div className={styles['tooltip-wrapper']}>
      <>
        <div ref={targetRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          {children}
        </div>

        {isVisible &&
          label !== null &&
          createPortal(
            <div
              ref={tooltipRef}
              className={styles['tooltip']}
              style={{ top: `${position?.top}px`, left: `${position?.left}px` }}
            >
              {label}
              <div className={styles['tooltip-arrow']} />
            </div>,
            document.body,
          )}
      </>
    </div>
  );
};
