import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (newChecked: boolean) => void;
  className?: string;
}

export const Checkbox = ({ label, checked, disabled, onChange, className = '' }: Props) => {
  const onChangeHandler = () => {
    if (!disabled && onChange !== undefined) {
      onChange(!checked);
    }
  };

  return (
    <div
      className={`gdb-checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${className}`}
      onClick={onChangeHandler}
    >
      <div className="gdb-checkbox-input-wrapper">
        <input type="checkbox" checked={checked} className="gdb-checkbox-input" />

        <Icon type="check" className="gdb-checkbox-icon" />
      </div>

      {label && <span className="gdb-checkbox-label">{label}</span>}
    </div>
  );
};
