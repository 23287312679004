import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { Cards } from 'ui/atoms/Cards/Cards';
import { Laptop } from 'ui/atoms/Laptop/Laptop';
import { Faq } from 'ui/atoms/Faq/Faq';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

import { ProfileDescription } from 'features/profile/organisms/ProfileDescription/ProfileDescription';
import { Data as ProfileDescriptionData } from 'features/profile/organisms/ProfileDescription/ProfileDescription.types';

const IndustryPage = () => {
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const [automotive, targetAutomotive, industryEmail, industryPhone] = useMemo(
    (): ProfileDescriptionData[] =>
      content.has_industry_information !== undefined
        ? [
            {
              title: content.has_industry_information.content_item_title_1,
              average: content.has_industry_information.content_item_numbers_1,
              averageInfo: content.has_industry_information.content_item_numbers_sub_1,
              data: content.has_industry_information.content_item_numbers_3,
              dataInfo: content.has_industry_information.content_item_numbers_sub_3,
              dataTwo: content.has_industry_information.content_item_numbers_2,
              dataTwoInfo: content.has_industry_information.content_item_numbers_sub_2,
              description: content.has_industry_information.content_item_text_1,
            },
            {
              title: content.has_industry_information.content_item_title_2,
              average: content.has_industry_information.content_second_item_numbers_1,
              averageInfo: content.has_industry_information.content_second_item_numbers_sub_1,
              data: content.has_industry_information.content_second_item_numbers_3,
              dataInfo: content.has_industry_information.content_second_item_numbers_sub_3,
              dataTwo: content.has_industry_information.content_second_item_numbers_2,
              dataTwoInfo: content.has_industry_information.content_second_item_numbers_sub_2,
              description: content.has_industry_information.content_item_text_2,
            },
            {
              title: content.has_industry_information.content_item_title_3,
              average: content.has_industry_information.content_third_item_numbers_1,
              averageInfo: content.has_industry_information.content_third_item_numbers_sub_1,
              data: content.has_industry_information.content_third_item_numbers_3,
              dataInfo: content.has_industry_information.content_third_item_numbers_sub_3,
              dataTwo: content.has_industry_information.content_third_item_numbers_2,
              dataTwoInfo: content.has_industry_information.content_third_item_numbers_sub_2,
              description: content.has_industry_information.content_item_text_3,
            },
            {
              title: content.has_industry_information.content_item_title_4,
              average: content.has_industry_information.content_four_item_numbers_1,
              averageInfo: content.has_industry_information.content_four_item_numbers_sub_1,
              data: content.has_industry_information.content_four_item_numbers_2,
              dataInfo: content.has_industry_information.content_four_item_numbers_sub_2,
              dataTwo: content.has_industry_information.content_four_item_numbers_3,
              dataTwoInfo: content.has_industry_information.content_four_item_numbers_sub_3,
              description: content.has_industry_information.content_item_text_4,
            },
          ]
        : [],
    [content],
  );

  if (content.has_industry_information === undefined) {
    return <span>Oops! {"There's"} no industry information on Global CMS.</span>;
  }

  return (
    <div className="industries-page">
      <InfoBlock
        title={content.has_industry_information.industry_title}
        buttonText={content.has_industry_information.button_text}
        buttonHref={content.button_href}
      />

      <Cards
        data={[
          {
            title: commonPageVars.profile_title_1,
            description: commonPageVars.profile_desc_1,
          },
          {
            title: commonPageVars.profile_title_2,
            description: commonPageVars.profile_desc_2,
          },
          {
            title: commonPageVars.profile_title_3,
            description: commonPageVars.profile_desc_3,
          },
        ]}
      />

      <ProfileDescription invert type="white-blue-grey" data={automotive} />

      <ProfileDescription titlePosition="out" type="red-grey-transparent" data={targetAutomotive} />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component type="gray" title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      <ProfileDescription invert type="white-transparent-green" data={industryEmail} />

      <ProfileDescription titlePosition="text" type="white-grey-orange" data={industryPhone} />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title_1_2} button={content.book_demo_button_1_2} />;
        }}
      </DynamicImport>

      <div className="content">
        <Laptop
          title={content.has_industry_information.laptop_title}
          description={content.has_industry_information.laptop_text}
        />
      </div>

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq title={commonPageVars.faq_2} data={additionals.faq.data} />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndustryPage;
