import { useState, useCallback } from 'react';

import cn from 'classnames';

import type { AcademyCourse } from 'libs/http/api/content/content.types';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './CourseStage.module.scss';

interface Props {
  hasVideo?: boolean;
  onChange: (value: number) => void;
  progression: Record<number, number>;
  data: AcademyCourse[];
  active: AcademyCourse;
}

export const CourseStage = ({ hasVideo, onChange, data, active, progression }: Props) => {
  const [hover, setHover] = useState<string>('');

  const onMouseEnter = useCallback((key: string) => () => setHover(key), []);
  const onMouseLeave = () => setHover('');
  const onChangeHandler = useCallback((value: number) => () => onChange(value), []);

  return (
    <ul>
      {data.map((item, idx) => (
        <li
          key={item.id}
          className={cn(styles['course-stage-item'], active.id === item.id && styles['active'])}
          onMouseEnter={onMouseEnter(item.id)}
          onMouseLeave={onMouseLeave}
          onClick={onChangeHandler(idx)}
        >
          <h5>
            <span>{idx + 1}</span>

            <p className={styles[`course-stage-viewed`]}>{item.name}</p>
          </h5>

          {hover === item.id ? (
            <Icon type="play-blue" className={styles['course-stage-play']} />
          ) : (
            <>
              {idx in progression && progression[idx] === 100 ? (
                <Icon type="check-green" />
              ) : hasVideo ? (
                <span>{item.duration}</span>
              ) : null}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};
