import cn from 'classnames';

import styles from './ProgressLine.module.scss';

interface Props {
  percentage: number | null;
  className?: string;
}

export const ProgressLine = ({ percentage = 0, className }: Props) => {
  if (Boolean(percentage) === false) {
    return null;
  }

  return (
    <div
      className={cn(styles['progress'], `${percentage === 0 || !percentage ? 'hide' : ''}`, className)}
      style={{ width: `${percentage}%` }}
    />
  );
};
