/* eslint react-hooks/exhaustive-deps: "warn" */

import { useState, useEffect } from 'react';

import { clearTimeoutIfExists } from 'libs/node';

type TargetElement = HTMLElement | null;

export const useResizeListener = (
  cb: () => void,
  deps: unknown[],
  $targetElement: TargetElement = null,
  throttleDelay = 500,
) => {
  const [targetElement, setTargetElement] = useState<TargetElement>(null);

  useEffect(() => {
    setTargetElement($targetElement || document.documentElement);
  }, [$targetElement]);

  useEffect(() => {
    if (!targetElement || typeof cb !== 'function') {
      return;
    }

    cb();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, targetElement]);

  useEffect(() => {
    let throttle: NodeJS.Timeout | undefined = undefined;

    const resizeObserver = new ResizeObserver(() => {
      if (throttle !== undefined) {
        return;
      }

      throttle = setTimeout(() => {
        throttle = undefined;

        if (!targetElement || typeof cb !== 'function') {
          return;
        }

        cb();
      }, throttleDelay);
    });

    if (targetElement) {
      resizeObserver.observe(targetElement);
    }

    return () => {
      clearTimeoutIfExists(throttle);
      resizeObserver.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, targetElement, throttleDelay]);
};
