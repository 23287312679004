import { useContext } from 'react';

import { keys } from 'libs/object/object';

import { ContentContext } from 'context/ContentContext';
import { ConsentContext } from 'context/ConsentContext';

import { Layout } from 'features/public/organisms/Layout/Layout';
import cookies from 'features/layout/organisms/Consent/cookies.json';

const PolicyPage = () => {
  const { consent, consentId, remove } = useContext(ConsentContext);
  const { data: content } = useContext(ContentContext);

  const onShowConstent = () => remove();

  return (
    <Layout className="policy-page" title={content.title}>
      {content.has_policy && content.has_policy.content && (
        <div
          className="policy-description-block"
          dangerouslySetInnerHTML={{ __html: content.has_policy.content || '' }}
        />
      )}

      {content.navigation_id === 'cookies_policy' && (
        <div className="policy-description-block" id="declaration">
          <p className="policy-change-consent">
            <span>
              Your current state:{' '}
              {consent ? (consent === 'all' ? 'Allow all' : `Allow selection (${consent})`) : 'Deny'}
            </span>

            {consent && <span>Your consent ID: {consentId}</span>}

            <span className={consent ? 'link' : 'link-disabled'} onClick={consent ? onShowConstent : undefined}>
              Change your consent
            </span>
          </p>

          <p>
            Learn more about who we are, how you can contact us and how we process personal data in our Privacy Notices.
            Please state your consent ID and date when you contact us regarding your consent.
          </p>

          <h2>5. Cookie Declaration</h2>

          {keys(cookies).map((cookie) => (
            <div key={cookie} className="policy-page-cookie">
              <h2>{cookie}</h2>

              <p>{cookies[cookie][0]}</p>

              {cookies[cookie][1].length > 1 ? (
                <table>
                  <tbody>
                    {(cookies[cookie][1] as string[][]).map((row, key) => (
                      <tr key={key}>
                        {row.map((column, columnKey) => (
                          <td key={column}>
                            <p>
                              {key > 0 && columnKey === 1 ? (
                                <strong dangerouslySetInnerHTML={{ __html: column }} />
                              ) : (
                                <strong>{column}</strong>
                              )}
                            </p>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="policy-page-cookie-empty">We do not use cookies of this type.</div>
              )}
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

// eslint-disable-next-line import/no-default-export
export default PolicyPage;
