import { getDuration } from 'libs/number';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './CourseDuration.module.scss';

interface Props {
  minimal?: boolean;
  align?: 'left' | 'center';
  lessons: number;
  duration?: string;
}

export const CourseDuration = ({ minimal = false, align = 'left', lessons = 0, duration }: Props) => {
  if (minimal && lessons === 1 && !duration) {
    return null;
  }

  return (
    <div className={styles['course-duration-wrapper']}>
      <ul className={styles[`course-duration-${align}`]}>
        {(minimal && lessons !== 1) || (!minimal && lessons > 0) ? (
          <li>
            <Icon type="lessons" />
            <span>{`${lessons} lesson${lessons === 1 ? '' : 's'}`}</span>
          </li>
        ) : null}

        {duration && (
          <li>
            <Icon type="lessons" />
            <span>{getDuration(duration)}</span>
          </li>
        )}
      </ul>
    </div>
  );
};
