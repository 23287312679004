import styles from './VacancyDescription.module.scss';

type Props = {
  title: string;
  content: string;
};

export const VacancyDescription = ({ title, content }: Props) => {
  if (!title && !content) {
    return null;
  }

  return (
    <div className={styles['vacancy-description-wrapper']}>
      {title && <h5>{title}</h5>}

      {content && <div className={styles['rich-text']} dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};
