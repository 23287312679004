import { useCallback, useContext, useEffect, useMemo } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { Icon } from 'ui/atoms/Icon/Icon';
import { Button } from 'ui/atoms/Button/Button';

import { ThankYouMedia } from 'features/customers/organisms/ThankYouMedia/ThankYouMedia';
import { Benefits } from 'features/articles/organisms/Benefits/Benefits';

const callToActionWords = ['verify', 'you!'] as const;

const ThankYouPage = () => {
  const { query } = useRouter();
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (typeof query.sample_link === 'string') {
      timeoutId = setTimeout(() => {
        window.open(query.sample_link as string);
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [query.sample_link]);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const splitArrayHalf = useCallback((array: string[]) => {
    const middle = Math.floor(array.length / 2);

    return {
      firstHalf: array.slice(0, middle).join(' '),
      secondHalf: array.slice(middle).join(' '),
    };
  }, []);

  const title = useMemo(() => {
    const regex = new RegExp(callToActionWords.join('|'), 'gi');
    const titleHasAction = content.title.replace(regex, (match) => `<span>${match}</span>`);

    return content.navigation_id === 'thank_you_trial'
      ? splitArrayHalf(titleHasAction.split(' '))
      : { singleTitle: titleHasAction };
  }, [content.title]);

  return (
    <div className="thank-you-page">
      <div className="thank-you-block">
        <div className={cn('thank-you__wrapper', content.navigation_id === 'thank_you_trial' && 'thank-you-trial')}>
          <div className={cn(content.navigation_id === 'thank_you' && 'thank-you-wrapper__left')}>
            {content.navigation_id === 'thank_you_trial' && (
              <div className="thank-you-title">
                <h1 dangerouslySetInnerHTML={{ __html: 'firstHalf' in title ? title.firstHalf : '' }} />
                <h1 dangerouslySetInnerHTML={{ __html: 'secondHalf' in title ? title.secondHalf : '' }} />
              </div>
            )}

            {content.navigation_id === 'thank_you' && (
              <h1 dangerouslySetInnerHTML={{ __html: 'singleTitle' in title ? title.singleTitle : '' }} />
            )}

            <h5>
              {content.description}

              <div
                className="thank-you-download-sample"
                style={{ display: query.request_sample !== 'true' ? 'none' : undefined }}
              >
                <span>{commonPageVars.request_sample_title}</span>

                <Button type="invert" size={48} href="/storage/uk_sample.xlsx" target="_blank" rel="noreferrer">
                  {commonPageVars.request_sample_button}
                </Button>
              </div>
            </h5>

            <div className="thank-you-wrapper__bg">
              <div className="thank-you__icons">
                <Icon type="thank-you" />
              </div>
            </div>
          </div>

          {content.navigation_id === 'thank_you' && (
            <div className="thank-you-wrapper__right">
              <ThankYouMedia />
            </div>
          )}
        </div>
      </div>

      {additionals.articlesList && (additionals.articlesList.data || []).length > 0 && (
        <div className="thank-you__customers-stories">
          <div className="thank-you__stories-header">
            <h3>{content.description_2}</h3>
          </div>

          <div className="thank-you__customers-benefits">
            <Benefits withViewMore limit={3} />
          </div>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ThankYouPage;
