import { fetcher, Params } from '../../fetcher';
import type {
  BookDemoParams,
  ResultsParams,
  AdvancedResultsParams,
  Price,
  BookDemoType,
  PriceParams,
  Country,
  State,
  CompanyList,
  TrialParams,
  Nomenclature,
  TrialToken,
  SetupParam,
  FirstListParam,
  FirstList,
  ScoreData,
  ScoreParam,
  SetupData,
  Config,
  Product,
  UploadResponse,
} from './dashboard.types';

const baseUrl = 'https://dashboard-api.globaldatabase.com/api';

export const dashboard = {
  // TODO: Move all crm related methods inside "crm" property here
  book: ({ lang, ...params }: BookDemoParams) => {
    const $window = window as any;

    return fetcher.post(
      `${baseUrl}/crm/shop/book`,
      {
        ...params,
        session_key: typeof $window.m_auto_id != 'undefined' ? $window.m_auto_id() : '',
        source: `${window.location.href}#${params.type}`,

        // Uppercase<BookDemoType> doesn't work, it works but can't build
        type: params.type?.toUpperCase() as BookDemoType,
      },
      { lang },
    );
  },

  apply: (params: FormData) => fetcher.post(`${baseUrl}/crm/shop/apply`, params, { lang: '', formData: true }),

  nomenclatures: (params: Params) => fetcher.get<Nomenclature[]>(`${baseUrl}/nomenclature/countries`, params),

  product: {
    get: ({ id, ...params }: Params) => fetcher.get<Product>(`${baseUrl}/applications/products/public/${id}`, params),
  },

  // TODO: Move all credit reports related methods inside "credit reports" property here
  country: (params: Params) => fetcher.get<Country[]>(`${baseUrl}/credit-reports/countries`, params),

  state: (params: Params) => fetcher.get<State[]>(`${baseUrl}/credit-reports/search/states`, params),

  results: (params: ResultsParams) => fetcher.get<CompanyList>(`${baseUrl}/credit-reports/search`, params),

  advanced: (params: AdvancedResultsParams) =>
    fetcher.get<CompanyList>(`${baseUrl}/credit-reports/search/advanced`, params),

  price: (params: PriceParams) => fetcher.get<Price>(`${baseUrl}/billing/credit-reports/price`, params),

  // TODO: Move all billing related methods inside "billing" property here
  investigationPrice: (params: PriceParams) => fetcher.get<Price>(`${baseUrl}/billing/investigation/price`, params),

  trial: ({ product_id: productId, ...params }: TrialParams) =>
    fetcher.post<TrialToken>(`${baseUrl}/billing/trial/${productId}`, params, {
      lang: '',
    }),

  // TODO: Move all enrichment related methods inside "enrichment" property here
  upload: (params: FormData) =>
    fetcher.post<UploadResponse>(`${baseUrl}/enrichment/companies/session/upload`, params, {
      lang: '',
      formData: true,
    }),

  setup: ({ session_key: sessionKey, ...params }: SetupParam) =>
    fetcher.post<SetupData>(`${baseUrl}/enrichment/companies/session/${sessionKey}/setup`, params, { lang: '' }),

  firstList: ({ session_key: sessionKey, ...params }: FirstListParam) =>
    fetcher.get<FirstList>(`${baseUrl}/enrichment/companies/session/${sessionKey}/list/first`, params),

  score: ({ session_key: sessionKey, ...params }: ScoreParam) =>
    fetcher.get<ScoreData>(`${baseUrl}/enrichment/companies/session/${sessionKey}/score`, params),

  config: (params: Params) => fetcher.get<Config>(`${baseUrl}/enrichment/companies/config`, params),
};
