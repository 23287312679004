import { useEffect, useCallback, useContext, useMemo, useRef } from 'react';

import dynamic from 'next/dynamic';

import { config } from 'config';

import { useRouter } from 'libs/router/useRouter';
import { clearTimeoutIfExists, getTopOffset } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { SliderLoader } from 'ui/atoms/Slider/SliderLoader';
import { Pagination } from 'ui/molecules/Pagination/Pagination';
import { Button } from 'ui/atoms/Button/Button';
import { DynamicImport } from 'ui/DynamicImport';

import { BlogItem } from '../BlogItem/BlogItem';
import { Blog } from '../Blog/Blog';

interface BenefitsProps {
  hasPriortyFirstImage?: boolean;
  hasEmptyPlaceholder?: boolean;
  hasSliderFeature?: boolean;
  className?: string;
  withViewMore?: boolean;
  route?: string;
  limit?: number;
  withBlog?: boolean;
  title?: string;
}

const INDENT_NAVIGATION = 180;

export const Benefits = ({
  hasPriortyFirstImage = false,
  hasEmptyPlaceholder = true,
  hasSliderFeature = false,
  className = '',
  withViewMore = false,
  route,
  limit,
  withBlog = false,
  title,
}: BenefitsProps) => {
  const { query } = useRouter();
  const { data: content, secondaryNavigation, additionals, pageVars } = useContext(ContentContext);
  const benefitsRef = useRef<HTMLDivElement>(null);
  const scrollDebounce = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(scrollDebounce.current);
    };
  }, []);

  const onChange = useCallback(() => {
    if (['blog', 'customers', 'author'].includes(content.navigation_id) === false) {
      return;
    }

    clearTimeoutIfExists(scrollDebounce.current);
    scrollDebounce.current = setTimeout(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo({
          top: (getTopOffset(benefitsRef?.current) || 0) - INDENT_NAVIGATION,
          behavior: 'smooth',
        });
      });
    }, 370);
  }, []);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const [blog, blogItems] = useMemo(() => {
    const articlesList = additionals.articlesList?.data
      ? additionals.articlesList.data.filter(
          (item, key) =>
            /** do not allow to show current one */
            content.slug !== item.slug &&
            /** do not allow to cross the limit */
            (limit !== undefined ? limit > key : true),
        )
      : [];

    return [articlesList.filter((_, key) => key <= 3), articlesList.filter((_, key) => key >= (withBlog ? 4 : 0))];
  }, [additionals.articlesList, withBlog, limit, content]);

  const renderedItems = useMemo(() => {
    const children = blogItems
      .filter((item) => item.has_article)
      .map((item) => (
        <BlogItem
          key={item.id}
          title={item.title}
          img={item.background?.url}
          type={item.has_article?.type}
          caseStudyType={item.has_article?.case_studies_type}
          description={item.description}
          readTime={item.has_article?.read_time}
          slug={item.slug}
          className="my-20"
        />
      ));

    if (hasSliderFeature === false) {
      return <>{children}</>;
    }

    return (
      <DynamicImport fallback={() => <SliderLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/atoms/Slider/Slider').then((module) => module.Slider), {
            ssr: false,
            loading: () => <SliderLoader />,
          });

          return <Component arrowType="primary">{children}</Component>;
        }}
      </DynamicImport>
    );
  }, [hasSliderFeature, blogItems]);

  if ((blog.length === 0 && blogItems.length === 0) || !additionals.articlesList?.data) {
    if (hasEmptyPlaceholder) {
      return <div className="advantages-empty">Sorry, we {"don't"} have any articles here yet.</div>;
    }

    return null;
  }

  return (
    <div className={`benefits-wrapper ${className}`} ref={benefitsRef}>
      <div className="benefits">
        {title && <h2>{title}</h2>}

        {withBlog && <Blog hasPriortyFirstImage={hasPriortyFirstImage} posts={blog} />}

        {blogItems.length > 0 && <div className="advantages">{renderedItems}</div>}

        {withViewMore !== true && (route || secondaryNavigation.blog?.slug) ? (
          <Pagination
            scrollRestoration={false}
            page={`${query.paage}`}
            total={additionals.articlesList.count}
            perPage={config.articles.regularPerPage}
            route={route || `${secondaryNavigation.blog.slug}`}
            onChange={onChange}
            {...(query.type ? { query: { type: `${query.type}` } } : {})}
            {...(query.id ? { query: { id: `${query.id}` } } : {})}
          />
        ) : null}

        {withViewMore && secondaryNavigation.blog?.slug && additionals.articlesList.next && (
          <Button
            type="invert"
            className="benefits-view-more"
            route={secondaryNavigation.blog.slug}
            query={{ paage: 1 }}
          >
            {commonPageVars.view_more}
          </Button>
        )}
      </div>
    </div>
  );
};
