import { useContext, useMemo } from 'react';

import dynamic from 'next/dynamic';

import { isVideo } from 'libs/string';

import { ContentContext } from 'context/ContentContext';

import { Faq } from 'ui/atoms/Faq/Faq';
import { Cards } from 'ui/atoms/Cards/Cards';
import { DataBlock } from 'ui/molecules/DataBlock/DataBlock';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { VideoBlock } from 'ui/molecules/VideoBlock/VideoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

const ApiPage = () => {
  const { data: content, additionals, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const pagePageVars = useMemo(() => pageVars[content.page_var_suffix || content.page_type] || {}, [content, pageVars]);

  const cardsData = useMemo(
    () =>
      [1, 2, 3].map((i) => ({
        title: pagePageVars[`circle_api_text_${i}`],
        description: pagePageVars[`circle_api_desc_${i}`],
      })),
    [pagePageVars],
  );

  return (
    <div className="api-page">
      <InfoBlock
        title={content.title}
        description={content.description}
        buttonText={content.button_text}
        buttonHref={content.button_href}
      />

      <Cards data={cardsData} />

      {pagePageVars.sub_description && <h5 className="api-page-sub-description">{pagePageVars.sub_description}</h5>}

      <DataBlock
        title={pagePageVars.title_1}
        subTitle={pagePageVars.sub_title_1}
        description={pagePageVars.description_1}
        {...{ [isVideo(pagePageVars.image_1) ? 'video' : 'img']: pagePageVars.image_1 }}
      />

      <DataBlock
        type="get-data-reverse"
        title={pagePageVars.title_2}
        subTitle={pagePageVars.sub_title_2}
        description={pagePageVars.description_2}
        {...{ [isVideo(pagePageVars.image_2) ? 'video' : 'img']: pagePageVars.image_2 }}
      />

      <DataBlock
        title={pagePageVars.title_3}
        subTitle={pagePageVars.sub_title_3}
        description={pagePageVars.description_3}
        {...{ [isVideo(pagePageVars.image_3) ? 'video' : 'img']: pagePageVars.image_3 }}
      />

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return <Component title={content.book_demo_title} button={content.book_demo_button} />;
        }}
      </DynamicImport>

      <DataBlock
        type="get-data-reverse"
        title={pagePageVars.title_4}
        subTitle={pagePageVars.sub_title_4}
        description={pagePageVars.description_4}
        {...{ [isVideo(pagePageVars.image_4) ? 'video' : 'img']: pagePageVars.image_4 }}
      />

      {pagePageVars.title_5 && pagePageVars.description_5 && pagePageVars.image_5 && (
        <DataBlock
          title={pagePageVars.title_5}
          subTitle={pagePageVars.sub_title_5}
          description={pagePageVars.description_5}
          {...{ [isVideo(pagePageVars.image_5) ? 'video' : 'img']: pagePageVars.image_5 }}
        />
      )}

      {pagePageVars.title_6 && pagePageVars.description_6 && pagePageVars.image_6 && (
        <DataBlock
          type="get-data-reverse"
          title={pagePageVars.title_6}
          subTitle={pagePageVars.sub_title_6}
          description={pagePageVars.description_6}
          {...{ [isVideo(pagePageVars.image_6) ? 'video' : 'img']: pagePageVars.image_6 }}
        />
      )}

      {pagePageVars.title_7 && pagePageVars.description_7 && pagePageVars.image_7 && (
        <DataBlock
          title={pagePageVars.title_7}
          subTitle={pagePageVars.sub_title_7}
          description={pagePageVars.description_7}
          {...{ [isVideo(pagePageVars.image_7) ? 'video' : 'img']: pagePageVars.image_7 }}
        />
      )}

      <VideoBlock
        title={pagePageVars.presentation_title}
        description={pagePageVars.presentation_description}
        hasWhiteBg
      />

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq title={commonPageVars.faq_2} data={additionals.faq.data} />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ApiPage;
