export function multipleSplit<T>(array: T[], splitTimes: number) {
  const elementsPerSplit = Math.ceil(array.length / splitTimes);

  const rows: T[][] = [...Array(splitTimes).keys()].map(() => []);

  return array.reduce<typeof rows>((acc, current) => {
    const availableIndex = acc.reduce((acc1, _, currentIndex1) => {
      if (acc1 === -1 && acc[currentIndex1].length !== elementsPerSplit) {
        acc1 = currentIndex1;
      }

      return acc1;
    }, -1);

    if (availableIndex >= 0) {
      acc[availableIndex] = [...acc[availableIndex], current];
    }

    return acc;
  }, rows);
}
