import { useState, useEffect, ReactNode } from 'react';

import cn from 'classnames';

import { clearTimeoutIfExists } from 'libs/node';

import { InfinityLoader } from 'ui/atoms/InfinityLoader/InfinityLoader';

import styles from './SmoothLoading.module.scss';

type Props = {
  loading: boolean;
  children: ReactNode;
  hasSpinner?: boolean;
  spinnerType?: string;
};

export const SmoothLoading = ({ loading, children, hasSpinner = false, spinnerType = '' }: Props) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading === false) {
      timer = setTimeout(() => {
        setShowContent(true);
      }, 300);
    }

    return () => {
      clearTimeoutIfExists(timer);
    };
  }, [loading]);

  return (
    <div className={cn(styles['loading'], showContent && styles['loading-show'])}>
      {hasSpinner && showContent === false && (
        <InfinityLoader className={styles['loading-spinner']} type={spinnerType} />
      )}

      <div className={styles['loading-children']}>{children}</div>
    </div>
  );
};
