import { useEffect, useState } from 'react';

import Script from 'next/script';

export const VideoPreviewScript = ({ onLoad }: { onLoad: () => void }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      window.onYouTubeIframeAPIReady = () => {
        onLoad();
      };
    }
  }, [loaded]);

  const onScriptLoaded = () => setLoaded(true);

  return <Script onLoad={onScriptLoaded} type="text/javascript" src="https://www.youtube.com/iframe_api" />;
};
