import { RefObject, useEffect } from 'react';

export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, cb: (newValue: boolean) => void) => {
  useEffect(() => {
    function handleClickOutside(this: Document, event: MouseEvent) {
      cb(Boolean(ref.current && ref.current.contains(event.target as Node)));
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
