import { useCallback, useState } from 'react';

import { isObject } from 'libs/object/object';

interface Options<T> {
  loading?: boolean;
  data?: T;
}

interface RequestOptions {
  concat?: boolean;
}
/* eslint react-hooks/exhaustive-deps: "warn" */

export function useRequest<T>(options = {} as Options<T>) {
  const [data, setData] = useState<T>(options?.data || ([] as unknown as T));
  const [loading, setLoading] = useState<boolean>(options.loading || false);

  const [errors, setErrors] = useState<{ [key: string]: string | string[] }>({});

  const request = useCallback(
    async (fn: { data: T } | Promise<{ data: T }>, requestOptions?: RequestOptions): Promise<T> => {
      setErrors({});

      setLoading(true);

      try {
        const { data: response } = await fn;

        // ** A weird error getting bellow */
        // @ts-ignore
        setData((prevData) => {
          if (requestOptions?.concat) {
            if (Array.isArray(prevData) && Array.isArray(response)) {
              return [...prevData, ...response];
            }

            if (isObject(prevData) && isObject(response)) {
              return { ...prevData, ...response };
            }
          }

          return response;
        });

        return Promise.resolve(response);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);

        if (err?.response?.data) {
          setErrors(err.response.data);

          return Promise.reject(err.response.data);
        }

        return Promise.reject({});
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    request,
    data,
    loading,

    errors,
  };
}
