import { useRef, useState, useMemo, useEffect } from 'react';

import Head from 'next/head';
import cn from 'classnames';

import { Link } from 'libs/router/Link';
import { useRouter } from 'libs/router/useRouter';
import { $Object } from 'libs/object/object.types';
import { clearTimeoutIfExists } from 'libs/node';

import { Icon } from 'ui/atoms/Icon/Icon';

export type Page = number | string;

interface Props {
  scrollRestoration?: boolean;
  onChange?: (page?: Page) => void;
  page?: Page;
  total?: number;
  perPage?: number;
  route: string;
  params?: $Object<any>;
  query?: $Object<any>;
}

interface DataSource {
  isPage: boolean;
  label: string | number;
  value?: Page;
  disabled?: boolean;
}

export const Pagination = ({
  scrollRestoration = true,
  onChange,
  page: $page = 1,
  total = 0,
  perPage = 0,
  route,
  query = {},
  params,
}: Props) => {
  const router = useRouter();

  const loadingTimer = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState<Page | undefined | null>(null); // * undefined is used for 1st page

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(loadingTimer.current);
    };
  }, []);

  const onClickHandler = (newPage?: Page) => {
    if (onChange === undefined) {
      setLoading(null);
      return;
    }

    onChange(newPage);
    setLoading(newPage);

    clearTimeoutIfExists(loadingTimer.current);
    loadingTimer.current = setTimeout(() => {
      setLoading(null);
    }, 350);
  };

  const page = useMemo(() => Number($page) || 1, [$page]);
  const pages = useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  const dataSource = useMemo(() => {
    const keys = Array.from(Array(pages).keys())
      .map((i) => i + 1)
      .reduce((acc, current) => [...acc, { isPage: true, label: current, value: current }], [] as DataSource[]);

    const pageIndex = page - 1;
    const rangeAfter = keys.slice(pageIndex, pageIndex + 3);
    const rangeBefore = keys.slice(pageIndex > 2 ? pageIndex - 2 : 0, pageIndex);
    const lastThree = keys.slice(keys.length - 2, keys.length);

    const newDataSource = [...rangeBefore, ...rangeAfter];

    // get last 3 pages if there're no in range after
    if (
      lastThree.length > 0 &&
      rangeAfter.length > 0 &&
      !lastThree.map((i) => i.value).includes(rangeAfter[rangeAfter.length - 1].value)
    ) {
      if (lastThree[0].value !== rangeAfter[rangeAfter.length - 1].value) {
        newDataSource.push({ isPage: false, label: '...' });
      }

      newDataSource.push(...lastThree);
    }

    // add first page if it's not in range before
    if (newDataSource.length > 0 && newDataSource[0].value !== 1) {
      newDataSource.unshift(
        { isPage: true, label: 1, value: 1 },
        {
          isPage: false,
          label: '...',
        },
      );
    }

    return newDataSource;
  }, [page, pages]);

  const hasPageParam = useMemo(() => router.asPath.match('/page/'), [router.asPath]);

  if (pages === 1) {
    return null;
  }

  return (
    <>
      <Head>
        {page > 1 && (
          <link
            rel="prev"
            href={`${process.env.HOST || ''}${
              hasPageParam && page === 2
                ? router.asPath.replace(`/page/${page}`, '')
                : router.asPath.replace(`/page/${page}`, `/page/${page - 1}`)
            }`}
          />
        )}

        {page < pages && (
          <link
            rel="next"
            href={`${process.env.HOST || ''}${
              hasPageParam ? router.asPath.replace(`/page/${page}`, `/page/${page + 1}`) : `${router.asPath}/page/2`
            }`}
          />
        )}
      </Head>

      <div className="pagination flex flex-between">
        {pages > 0 && (
          <div className="pages-wrapper">
            <Link
              tabIndex={0}
              scroll={scrollRestoration}
              disabled={page === 1}
              route={route}
              query={{ ...query, paage: page - 1 }}
              params={params}
              onClick={page !== 1 ? () => onClickHandler(page - 1) : undefined}
            >
              <Icon type="left-row" className={cn(page === 1 && 'disabled')} />
              Prev
            </Link>

            {dataSource.map((item, key) =>
              item.isPage && item.value !== undefined ? (
                <Link
                  key={key}
                  tabIndex={page !== item.value ? 0 : -1}
                  scroll={scrollRestoration}
                  disabled={item.disabled}
                  route={route}
                  query={{ ...query, paage: item.value }}
                  params={params}
                  className={cn('button', page === item.value && 'selected', loading === item.value && 'loading')}
                  onClick={onChange && (() => onClickHandler(item.value))}
                >
                  {item.label}
                </Link>
              ) : (
                <span key={key}>{item.label}</span>
              ),
            )}

            <Link
              tabIndex={0}
              scroll={scrollRestoration}
              disabled={page === pages}
              route={route}
              query={{ ...query, paage: page + 1 }}
              params={params}
              onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}
            >
              Next
              <Icon
                type="right-row"
                className={cn(page === pages && 'disabled')}
                onClick={page !== pages ? () => onClickHandler(page + 1) : undefined}
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
