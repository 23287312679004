import { useCallback, useEffect, useRef, RefObject } from 'react';

import { clearTimeoutIfExists } from 'libs/node';

const setNodePointerEvents = (value: 'none' | '') => (node: ChildNode) => {
  if (node instanceof HTMLElement) {
    node.style.pointerEvents = value;
  }
};

type Ref = RefObject<HTMLElement>;
type Options = {
  direction?: 'vertical' | 'horizontal' | 'both';
};

export const useDraggableScroll = (ref: Ref, { direction }: Options = { direction: 'both' }) => {
  if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
    // eslint-disable-next-line no-console
    console.error('useDraggableScroll expects a single ref argument.');
  }

  const throttle = useRef<NodeJS.Timeout>();
  const initialPosition = useRef({ scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 });
  const isMouseMove = useRef(false);

  useEffect(() => {
    const event = [
      'click',
      () => {
        if (!isMouseMove.current && ref.current) {
          isMouseMove.current = false;
        }
      },
    ] as const;

    ref.current?.addEventListener(...event);

    return () => {
      ref.current?.removeEventListener(...event);

      clearTimeoutIfExists(throttle.current);
    };
  }, []);

  const mouseMove = useCallback((event: { clientX: number; clientY: number }) => {
    if (!isMouseMove.current) {
      /** Unfocus (blur) any links that user could potencially focus by intending to scroll */
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      ref.current?.childNodes.forEach(setNodePointerEvents('none'));
    }

    isMouseMove.current = true;

    if (throttle.current !== undefined) {
      /** Throttle utill previous timeout is done */
      return;
    }

    throttle.current = setTimeout(() => {
      throttle.current = undefined;

      if (!ref.current) {
        return;
      }

      const dx = event.clientX - initialPosition.current.mouseX;
      const dy = event.clientY - initialPosition.current.mouseY;

      if (direction !== 'horizontal') {
        ref.current.scrollTop = initialPosition.current.scrollTop - dy;
      }

      if (direction !== 'vertical') {
        ref.current.scrollLeft = initialPosition.current.scrollLeft - dx;
      }
    }, 50);
  }, []);

  const mouseUp = useCallback(() => {
    if (ref.current) {
      isMouseMove.current = false;

      ref.current.style.cursor = 'grab';
      ref.current.style.scrollBehavior = 'smooth';
      ref.current.childNodes.forEach(setNodePointerEvents(''));
    }

    document.removeEventListener('mousemove', mouseMove);
    document.removeEventListener('mouseup', mouseUp);
  }, []);

  const onMouseDown = useCallback((event: { clientX: number; clientY: number }) => {
    if (!ref.current) {
      return;
    }

    initialPosition.current = {
      scrollLeft: ref.current.scrollLeft,
      scrollTop: ref.current.scrollTop,
      mouseX: event.clientX,
      mouseY: event.clientY,
    };

    ref.current.style.cursor = 'grabbing';
    ref.current.style.scrollBehavior = 'initial';
    ref.current.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMove);
    document.addEventListener('mouseup', mouseUp);
  }, []);

  return { onMouseDown };
};
