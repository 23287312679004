import { useRef } from 'react';

import { useIntersection } from 'hooks/useIntersection';

export interface Props {
  src?: string;
  autoPlay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
}

export const Video = ({ src = '', controls = false, autoPlay = true, loop = true, muted = true }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  if (!src) {
    return null;
  }

  if (intersected === false) {
    <div ref={ref} />;
  }

  return (
    <video
      title="Video Overview"
      playsInline
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      muted={muted}
      src={src.match('https://') ? src : `/storage/${src}`}
    >
      <track kind="captions" src="/public/storage/placeholder.vtt" />
    </video>
  );
};
