import { useContext, useMemo, useRef } from 'react';

import dynamic from 'next/dynamic';

import { ContentContext } from 'context/ContentContext';

import { useImage } from 'hooks/useImage';
import { useIntersection } from 'hooks/useIntersection';

import { Icon } from 'ui/atoms/Icon/Icon';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';
import { RequestDemoLoader } from 'ui/molecules/RequestDemo/RequestDemoLoader';
import { DynamicImport } from 'ui/DynamicImport';

import s from './OurDataPage.module.scss';

import { DataIntro } from '../../organisms/DataIntro/DataIntro';
import { ColorTable } from '../../organisms/ColorTable/ColorTable';

const OurDataPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();
  const { data: content, pageVars } = useContext(ContentContext);

  const ourDataPageVars = useMemo(() => pageVars.our_data || {}, [pageVars.our_data]);

  const [block1, block2, block3] = useMemo(
    () => [
      buildUrl({ src: 'img/4green.jpg', width: 280, height: 280 }),
      buildUrl({ src: 'img/4red.jpg', width: 280, height: 280 }),
      buildUrl({ src: 'img/4orange.jpg', width: 280, height: 280 }),
    ],
    [buildUrl],
  );

  return (
    <div className={s['our-data']}>
      <InfoBlock className={s['info-block']} title={content.title} description={content.description} />

      <DataIntro
        title={ourDataPageVars.collect_title}
        data={[
          ourDataPageVars.collect_text_item_1,
          ourDataPageVars.collect_text_item_2,
          ourDataPageVars.collect_text_item_3,
        ]}
      />

      <div className={s['arrows']}>
        <Icon type="arrow-bottom" />
      </div>

      <div className={s['arrows-title']}>
        <ColorTable title={ourDataPageVars.color_button_text_1} background="blue" />
      </div>

      <div className={s['arrows']}>
        <Icon type="arrow-bottom" />
      </div>

      <div className={s['first-arrow-block']}>
        <h5>{ourDataPageVars.sub_button_text_1}</h5>

        <div className={s['arrows']}>
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways']}>
          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_title_1}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_title_2}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_title_3}</span>
          </div>
        </div>

        <div className={s['ways-arrows-information']}>
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways-information']}>
          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_item_1 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_item_2 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_item_3 }}
          />
        </div>
      </div>

      <div className={s['second-arrow-block']}>
        <div className={s['second-arrow-block-title']}>
          <div className={s['arrows-title']}>
            <ColorTable title={ourDataPageVars.color_button_text_2} background="orange" />
          </div>

          <div className={s['arrows-title']}>
            <ColorTable title={ourDataPageVars.color_button_text_3} background="green" />
          </div>
        </div>

        <div className={s['arrows-to-title']}>
          <div className={s['arrows-to-title-text']}>
            <div className={s['arrows']}>
              <div className={s['icon-orange']}>
                <Icon type="arrow-bottom" />
              </div>
            </div>

            <h5>{ourDataPageVars.sub_button_text_2}</h5>

            <div className={s['arrows']}>
              <div className={s['icon-orange']}>
                <Icon type="arrow-bottom" />
              </div>
            </div>
          </div>

          <div className={s['arrows-to-title-text']}>
            <div className={s['arrows']}>
              <div className={s['icon-green']}>
                <Icon type="arrow-bottom" />
              </div>
            </div>

            <h5>{ourDataPageVars.sub_button_text_3}</h5>

            <div className={s['arrows']}>
              <div className={s['icon-green']}>
                <Icon type="arrow-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s['second-arrow-block-ways']}>
        <div className={s['left-side']}>
          <div className={s['left-side-ways']}>
            <div className={s['left-side-ways-item']}>
              <span>{ourDataPageVars.color_second_title_1}</span>
            </div>

            <div className={s['left-side-ways-item']}>
              <span>{ourDataPageVars.color_second_title_2}</span>
            </div>
          </div>

          <div className={s['left-side-ways-arrows-information']}>
            <Icon type="arrow-bottom" />
            <Icon type="arrow-bottom" />
          </div>

          <div className={s['left-information']}>
            <div
              className={s['left-information-item']}
              dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_second_item_1 }}
            />

            <div
              className={s['left-information-item']}
              dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_second_item_2 }}
            />
          </div>
        </div>

        <div className={s['right-side']}>
          <div className={s['color-title-mq-green']}>
            <div className={s['arrows-title']}>
              <ColorTable title={ourDataPageVars.color_button_text_3} background="green" />
            </div>

            <Icon type="arrow-bottom" />
          </div>

          <div className={s['right-side-ways']}>
            <div className={s['right-side-ways-item']}>
              <span>{ourDataPageVars.color_third_title_1}</span>
            </div>

            <div className={s['right-side-ways-item']}>
              <span>{ourDataPageVars.color_third_title_2}</span>
            </div>
          </div>

          <div className={s['right-side-ways-arrows-information']}>
            <Icon type="arrow-bottom" />
            <Icon type="arrow-bottom" />
          </div>

          <div className={s['right-information']}>
            <div
              className={s['right-information-item']}
              dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_third_item_1 }}
            />

            <div
              className={s['right-information-item']}
              dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_third_item_2 }}
            />
          </div>
        </div>
      </div>

      <div className={s['partnership-title']}>
        <ColorTable title={ourDataPageVars.color_button_text_4} background="blue" />
      </div>

      <div className={s['arrows']}>
        <Icon type="arrow-bottom" />
      </div>

      <div className={s['first-arrow-block']}>
        <h5>{ourDataPageVars.sub_button_text_4}</h5>

        <div className={s['arrows']}>
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways']}>
          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_four_title_1}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_four_title_2}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_four_title_3}</span>
          </div>
        </div>

        <div className={s['ways-arrows-information']}>
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways-information']}>
          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_four_item_1 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_four_item_2 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_four_item_3 }}
          />
        </div>
      </div>

      <DynamicImport fallback={() => <RequestDemoLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/molecules/RequestDemo/RequestDemo'), {
            ssr: false,
            loading: () => <RequestDemoLoader />,
          });

          return (
            <Component
              className="mt-200"
              type="gray"
              title={content.book_demo_title}
              button={content.book_demo_button}
            />
          );
        }}
      </DynamicImport>

      <div className={s['public-title']}>
        <ColorTable title={ourDataPageVars.color_button_text_5} background="orange" />

        <div className={s['arrows']}>
          <div className={s['icons']}>
            <Icon type="arrow-bottom" />
          </div>
        </div>
      </div>

      <div className={s['first-arrow-block-orange']}>
        <h5>{ourDataPageVars.sub_button_text_5}</h5>

        <div className={s['arrows']}>
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways']}>
          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_fifth_title_1}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_fifth_title_2}</span>
          </div>

          <div className={s['ways-item']}>
            <span>{ourDataPageVars.color_fifth_title_3}</span>
          </div>
        </div>

        <div className={s['ways-arrows-information']}>
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
          <Icon type="arrow-bottom" />
        </div>

        <div className={s['ways-information']}>
          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_fifth_item_1 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_fifth_item_2 }}
          />

          <div
            className={s['ways-information-item']}
            dangerouslySetInnerHTML={{ __html: ourDataPageVars.color_fifth_item_3 }}
          />
        </div>
      </div>

      <div className={s['data-accuracy-title']}>
        <h2>{ourDataPageVars.accuracy_title}</h2>
        <p>{ourDataPageVars.accuracy_description}</p>
      </div>

      <div className={s['example-blocks']}>
        <h4>{ourDataPageVars.example_title}</h4>

        <div className={s['example-blocks-item']} ref={ref}>
          <div className={s['block-1']} style={{ backgroundImage: intersected ? `url(${block1})` : undefined }}>
            <h2>{ourDataPageVars.example_item_number_1}</h2>
            <p>{ourDataPageVars.example_item_description_1}</p>
          </div>

          <div className={s['block-2']} style={{ backgroundImage: intersected ? `url(${block2})` : undefined }}>
            <h2>{ourDataPageVars.example_item_number_2}</h2>
            <p>{ourDataPageVars.example_item_description_2}</p>
          </div>

          <div className={s['block-3']} style={{ backgroundImage: intersected ? `url(${block3})` : undefined }}>
            <h2>{ourDataPageVars.example_item_number_3}</h2>
            <p>{ourDataPageVars.example_item_description_3}</p>
          </div>
        </div>
      </div>

      <DataIntro
        title={ourDataPageVars.collect_second_title}
        data={[
          ourDataPageVars.collect_second_text_item_1,
          ourDataPageVars.collect_second_text_item_2,
          ourDataPageVars.collect_second_text_item_3,
        ]}
      />

      <div className={s['data-update-title']}>
        <ColorTable title={ourDataPageVars.color_button_text_6} background="blue" />

        <div className={s['arrows']}>
          <Icon type="arrow-bottom" />
        </div>
      </div>

      <div className={s['data-update-content']}>
        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item']}>
            <span>{ourDataPageVars.color_sixth_title_1}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-orange']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>

          <div className={s['data-update-content-mq']}>
            <div className={s['data-update-content-item-1']}>
              <span>{ourDataPageVars.color_sixth_item_1}</span>
            </div>
          </div>
        </div>

        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item']}>
            <span>{ourDataPageVars.color_sixth_title_2}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-green']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>

          <div className={s['data-update-content-mq']}>
            <div className={s['data-update-content-item-2']}>
              <span>{ourDataPageVars.color_sixth_item_2}</span>
            </div>
          </div>
        </div>

        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item']}>
            <span>{ourDataPageVars.color_sixth_title_3}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-blue']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>

          <div className={s['data-update-content-mq']}>
            <div className={s['data-update-content-item-3']}>
              <span>{ourDataPageVars.color_sixth_item_3}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={s['data-update-dashed']}>
        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item-1']}>
            <span>{ourDataPageVars.color_sixth_item_1}</span>
          </div>
        </div>

        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item-2']}>
            <span>{ourDataPageVars.color_sixth_item_2}</span>
          </div>
        </div>

        <div className={s['data-update-content-title']}>
          <div className={s['data-update-content-item-3']}>
            <span>{ourDataPageVars.color_sixth_item_3}</span>
          </div>
        </div>
      </div>

      <div className={s['data-update-title-last']}>
        <div className={s['data-update-content-title-last']}>
          <div className={s['data-update-content-item-last-1']}>
            <span>{ourDataPageVars.color_sixth_title_4}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-blue']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>
        </div>

        <div className={s['data-update-content-title-last']}>
          <div className={s['data-update-content-item-last-2']}>
            <span>{ourDataPageVars.color_sixth_title_5}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-orange']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>
        </div>
      </div>

      <div className={s['data-update-title-last-dashed']}>
        <div className={s['data-update-content-title-last-dashed']}>
          <div className={s['data-update-content-item-last-dashed-1']}>
            <span>{ourDataPageVars.color_sixth_item_4}</span>
          </div>
        </div>

        <div className={s['data-update-content-title-last-dashed']}>
          <div className={s['data-update-content-title-last-dashed-2-mq']}>
            <div className={s['data-update-content-item-last-2-mq']}>
              <span>{ourDataPageVars.color_sixth_title_5}</span>
            </div>

            <div className={s['arrows']}>
              <div className={s['icon-orange']}>
                <Icon type="arrow-bottom" />
              </div>
            </div>
          </div>

          <div className={s['data-update-content-item-last-dashed-2']}>
            <span>{ourDataPageVars.color_sixth_item_5}</span>
          </div>

          <div className={s['arrows']}>
            <div className={s['icon-orange']}>
              <Icon type="arrow-bottom" />
            </div>
          </div>

          <div className={s['data-update-content-item-last-dashed-medium']}>
            <span>{ourDataPageVars.color_sixth_item_5_second}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default OurDataPage;
