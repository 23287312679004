import { useEffect, useRef, useState, ChangeEvent, KeyboardEvent } from 'react';

import { useRouter } from 'next/router';

import { common } from 'libs/http/api/common/common';
import { SearchedBlog } from 'libs/http/api/common/common.types';
import { Link } from 'libs/router/Link';
import { defaultLocale } from 'libs/locale';

import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

import { Icon } from 'ui/atoms/Icon/Icon';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

import styles from './Search.module.scss';

const modifyBlogs = (data: SearchedBlog[]) =>
  data.map((item) => ({
    title: item.title,
    slug: item.slug,
  }));

const renderSearchResult = (blog: SearchedBlog, key: number) => (
  <Link key={key} route={blog.slug!}>
    <div className={styles['result']}>{blog.title}</div>
  </Link>
);

export const Search = () => {
  const { query } = useRouter();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const debounce = useRef<NodeJS.Timeout>();

  const [data, setData] = useState<SearchedBlog[]>([]);
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [entry, setEntry] = useState('');
  const [visibleResults, setVisibleResults] = useState(false);

  const router = useRouter();

  useOutsideAlerter(wrapperRef, setVisibleResults);

  const onChangeEntry = ({ target }: ChangeEvent<HTMLInputElement>) => setEntry(target.value);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (data.length > 0 && loading === false && data[0].slug) {
        router.push(data[0].slug);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (debounce.current !== undefined) {
        clearTimeout(debounce.current);
      }
    };
  }, []);

  useEffect(() => {
    if (entry.length > 1) {
      setLoading(true);
      setEmpty(false);

      if (debounce.current !== undefined) {
        clearTimeout(debounce.current);
      }

      debounce.current = setTimeout(() => {
        (async () => {
          try {
            const { data: newData = [] } = await common.search({
              'filter[title__icontains]': entry,
              lang: `${query.lang || defaultLocale}`,
            });

            if (newData.length === 0) {
              setEmpty(true);
            }

            setData(modifyBlogs(newData));
          } finally {
            setLoading(false);
          }
        })();
      }, 250);
    }
  }, [entry]);

  return (
    <div className={styles['search-wrapper']} ref={wrapperRef}>
      <form className={styles['search-form']}>
        <div className={styles['search-icon-wrapper']}>
          <Icon type="search" className={styles['search-icon']} />
        </div>

        <input
          aria-label="Search article"
          className={styles['text-box']}
          placeholder="Search article"
          onChange={onChangeEntry}
          onKeyDown={handleKeyDown}
        />
      </form>

      <div>
        {visibleResults && (entry.length > 0 || data.length > 0) && (
          <div className={styles[`results-wrapper${data.length === 1 ? '-extra-padding' : ''}`]}>
            {loading ? (
              <LoaderSpin size="small" loading />
            ) : (
              <>
                {(empty || data.length > 5) && (
                  <>
                    {data.length > 5 && <div className={styles['results-number']}>Results: {data.length}</div>}
                    {empty && <div className={styles['results-number']}>Sorry, we {"can't"} find anything</div>}

                    <hr />
                  </>
                )}

                {data.map(renderSearchResult)}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
