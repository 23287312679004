import { FormEvent, useContext, useState, useCallback, useMemo } from 'react';

import { keys } from 'libs/object/object';
import { useRouter } from 'libs/router/useRouter';
import { stringifyQuery } from 'libs/node';

import { ContentContext } from 'context/ContentContext';

import { Button } from 'ui/atoms/Button/Button';
import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

import {
  FreshInvestigation,
  initialState as initialProduct,
} from 'features/products/organisms/FreshInvestigation/FreshInvestigation';

const FreshInvestigationPage = () => {
  const { data: content, pageVars } = useContext(ContentContext);
  const { query } = useRouter();
  const [products, setProducts] = useState<(typeof initialProduct)[]>([initialProduct]);
  const [errors, setErrors] = useState({});

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const requiredValues = ['name', 'country'];

    const errors = products.reduce((acc: any, current) => {
      keys(current).forEach((key) => {
        if (requiredValues.includes(key) && !current[key]) {
          acc[current.key] = [...(acc[current.key] || []), key];
        }
      });

      return acc;
    }, {});

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const queryString = products.map((product) => ({
        type: 'fresh_investigation',
        data: product,
      }));

      const readyQueryString = stringifyQuery({
        data: queryString,
        origin: `${process.env.HOST || ''}/${query.category}`,
      });

      window.location.assign(process.env.PLATFORM + '/public/payment/summary?' + readyQueryString);
    }
  };

  const onAdd = () => setProducts((prevProducts) => [...prevProducts, { ...initialProduct, key: Date.now() }]);

  const onChange = (newValue: typeof initialProduct) =>
    setProducts((prevProducts) => prevProducts.map((product) => (newValue.key === product.key ? newValue : product)));

  const onDelete = useCallback(
    (key: number) => () => setProducts((prevProducts) => prevProducts.filter((product) => key !== product.key)),
    [],
  );

  const freshInvestigationPageVars = useMemo(() => pageVars.fresh_investigation || {}, [pageVars.fresh_investigation]);

  return (
    <div className="fresh-investigation">
      <InfoBlock title={content.title} description={content.description} />

      <form onSubmit={onSubmit}>
        {products.map((product, key) => (
          <FreshInvestigation
            key={product.key}
            id={product.key}
            initialValues={product}
            errors={errors}
            onDelete={key > 0 ? onDelete(product.key) : undefined}
            onChange={onChange}
          />
        ))}

        <div className="fresh-investigation-action-buttons">
          <Button type="transparent" size={48} className="add-company" onClick={onAdd}>
            {freshInvestigationPageVars.add_company_button}
          </Button>

          <Button htmlType="submit" type="primary" size={48}>
            {freshInvestigationPageVars.pay_now_button}
          </Button>
        </div>
      </form>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default FreshInvestigationPage;
