import { useMemo, useRef } from 'react';

import { useImage } from 'hooks/useImage';
import { useIntersection } from 'hooks/useIntersection';

interface Props {
  background: string;
  title: string;
}

export const ColorTable = ({ background, title }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();

  const buildSrc = useMemo(() => buildUrl({ src: `img/bg_${background}.jpg`, width: 384, height: 64 }), [buildUrl]);

  return (
    <div
      ref={ref}
      className={background ? `color-table-${background}` : 'color-table'}
      style={{ backgroundImage: intersected ? `url(${buildSrc})` : undefined }}
    >
      <span>{title ? title : 'Set Title'}</span>
    </div>
  );
};
