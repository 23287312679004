import { useMemo, useRef } from 'react';

import cn from 'classnames';

import { useIntersection } from 'hooks/useIntersection';

export interface Card {
  title: string;
  description: string;
  className?: string;
}

interface Props {
  className?: string;
  data?: Card[];
}

export const Cards = ({ className = '', data: $data = [] }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const data = useMemo(() => $data.filter((i) => i.title || i.description), [$data]);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div ref={ref} className={cn('gdb-cards', className, intersected && 'intersected')}>
      {data.map((item) => (
        <div key={item.title} className={`gdb-card ${className}`}>
          {item.title && <h2 className="gdb-card-title">{item.title}</h2>}

          {item.description && <div className="gdb-card-description">{item.description}</div>}
        </div>
      ))}
    </div>
  );
};
